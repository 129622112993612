import React from "react";
import logo from "../../assets/images/logo.png";
import { Route, Switch } from "react-router-dom";
import "../../assets/css/layout.css";
import { Tooltip, Typography } from "@material-ui/core";

const TabContainer = (props) => {
  const optionalTabs = props.optionalTabs.map((tab) => (
    <Tooltip title={tab.text} key={"tab-" + tab.key}>
      <div
        onClick={() => {
          props.onTopNavChanged(tab.key);
        }}
        className={`top-nav-btns inline-block${props.currentNavFocused === tab.key ? " top-nav-btns-focused" : ""}`}
      >
        <div className="icon-alignment inline-block">{tab.icon}</div>
        <div className="inline-block sm-hide-txt">
          <Typography>{tab.text}</Typography>
        </div>
        {props.currentNavFocused === tab.key ? <div className="tab-selected-connnector" /> : null}
      </div>
    </Tooltip>
  ));
  const routes = (
    <div className="inner-con">
      {props.isLoaded ? (
        // <BrowserRouter>
        <Switch>
          {props.optionalTabs.map((tab) => (
            // <Route key={"route-" + tab.key} path={tab.path} component={tab.component} />
            <Route key={"route-" + tab.key} path={tab.path} render={() => tab.component({ ...props[tab.stateFamily], ...tab.functions })} />
          ))}
        </Switch>
      ) : //</BrowserRouter>
      null}
    </div>
  );
  return (
    <>
      <div className="relative height100">
        <div className="description inline-block">
          <Typography variant="h5">{props.title}</Typography>
          {props.subTitle.length > 40 ? (
            <Tooltip title={props.subTitle}>
              <Typography variant="h6" style={{ wordBreak: "break-word" }}>
                {props.subTitle.substring(0, 40) + "..."}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="h6" style={{ wordBreak: "break-word" }}>
              {props.subTitle || "תיאור עסקה"}
            </Typography>
          )}
        </div>

        <div className="optional-tabs-con">{optionalTabs}</div>

        <div className="small-logo-con inline-block">
          <img alt="btax-logo" src={logo} />
        </div>
      </div>

      {routes}
    </>
  );
};

export default TabContainer;
