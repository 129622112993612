import React, { Component } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { connect } from "react-redux";
import { Grid, Typography, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import salesService from "../../services/salesService";
import dealsService from "../../services/dealsService";
import adminService from "../../services/adminService";
import * as customColors from "../../theme/customColors";
import CustomAcordion from "../layout/CustomAcordion";
import LabeledTextField from "../layout/LabeledTextField";
import CustomComplexTable from "../layout/CustomComplexTable";
import logo from "../../assets/images/logo.png";
import { validateEmail } from "../../services/utils/utils";
import DealsSearchComponent from "../layout/DealsSearchComponent";

class UsersManagement extends Component {
  state = {
    searchByCardNum: "",
    clientEmailForConf: "",
    emailConfTokenLink: "",
    filterDealByNumber: "",
    filterDealByName: "",
    filterJustPrisaByNumber: "",
    filterJustPrisaByName: "",
    dealHeaders: [
      "מספר עסקה",
      "שם עסקה",
      "עורך דין",
      "תאריך יצירה",
      "סטטוס מס",
      "מועד אחרון לדיווחים",
      "הוגשו דיווחים",
      "הערות אזהרה",
      "אישורי מיסים",
      "התראות"
    ],
    dealRows: [],
    justPrisaHeaders: ["מספר עסקה", "שם עסקה", "עורך דין", "תאריך יצירה"],
    justPrisaRows: [],
    test1Pass: "",
    test2Pass: "",
    test3Pass: "",
    searchDealsFilters: {
      dealPrettyId: "",
      dealName: ""
    },
    btaxDealsAllCount: "",
    yamDealsAllCount: "",

    resetFiltersAllDeals: Math.random()
  };

  componentDidMount() {
    window["scrollTo"]({ top: 0, behavior: "smooth" });
    if (this.props.match.params.searchByCardNum) {
      this.setState({ searchByCardNum: this.props.match.params.searchByCardNum }, () => {
        this.getDealsByCardNum(true);
      });
    }
  }

  render() {
    let filteredDealRows = this.state.dealRows;
    let filteredJustPrisaRows = this.state.justPrisaRows;

    return (
      <Grid container direction="column" spacing={2}>
        <Grid container item direction="row">
          <Grid container item direction="row" xs={6} justify="flex-start" alignItems="flex-end" style={{ minHeight: "85px" }}>
            <Typography variant="h4">ניהול משתמשים</Typography>
          </Grid>
          <Grid container item direction="row" xs={6} justify="flex-end">
            <div className="small-logo-deals-page inline-block">
              <img alt="btax-logo" src={logo} />
            </div>
          </Grid>
        </Grid>
        <Grid item>
          <CustomAcordion summaryText="חיפוש לפי מספר כרטיס" defaultExpanded>
            <Grid container direction="column" spacing={2}>
              <Grid container item direction="row" spacing={1} justify="flex-start" alignItems="center">
                <Grid item>
                  <LabeledTextField
                    type="text"
                    name="searchByCardNum"
                    value={this.state.searchByCardNum}
                    placeholder="הזן מספר כרטיס"
                    onChange={(e) => {
                      window.history.pushState("", "", "/users-management/" + e.target.value);
                      this.handleOnChange(e);
                    }}
                    onKeyPress={this.handleOnKeyPress}
                  />
                </Grid>

                <Grid item>
                  <DealsSearchComponent
                    onSearchFiltersChange={(key, value) =>
                      this.setState({ searchDealsFilters: { ...this.searchDealsFilters, [key]: value } })
                    }
                    searchDealsWithFilters={() => this.getDealsByCardNum(true)}
                  />
                </Grid>
              </Grid>

              <Grid container item direction="row" spacing={1} justify="flex-start" alignItems="center">
                <Grid item>
                  <hr />

                  <LabeledTextField
                    label="מייל להצגת איפוס סיסמת לקוח"
                    type="text"
                    name="clientEmailForConf"
                    value={this.state.clientEmailForConf}
                    onChange={this.handleOnChange}
                  />
                </Grid>
                <Grid item>
                  <hr />

                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    style={{ maxWidth: "36px", minWidth: "36px" }}
                    endIcon={<SearchIcon style={{ marginRight: "10px" }} />}
                    disabled={!this.state.clientEmailForConf}
                    onClick={this.getEmailConfToken}
                  />
                </Grid>
              </Grid>
              <Grid container item direction="row" spacing={1} justify="flex-start" alignItems="center">
                <span className="email-conf-client yellow-color">{this.state.emailConfTokenLink}</span>
              </Grid>
            </Grid>
          </CustomAcordion>
        </Grid>
        <Grid item>
          <CustomAcordion summaryText="עסקאות ביטקס" defaultExpanded>
            <Grid container direction="column" spacing={3} justify="center">
              {this.state.dealRows.length > 0 ? (
                <Grid item>
                  <CustomComplexTable
                    hideFirstCellsInRow={1}
                    headers={this.state.dealHeaders}
                    rows={filteredDealRows}
                    notifyBadge={true}
                    handleDoubleClick={this.props.role === "admin" ? this.calcDeal : null}
                    calcFunc={this.props.role === "admin" ? this.calcDeal : null}
                    copyFunc={this.props.role === "admin" ? this.copyDeal : null}
                    deleteFunc={this.props.role === "admin" ? this.deleteDeal : null}
                    dealsAllCount={this.state.btaxDealsAllCount}
                    onPageChange={this.fetchBtaxDeals}
                    resetFilters={this.state.resetFiltersAllDeals}
                  />
                </Grid>
              ) : (
                <Grid item>
                  <Typography variant="h6">לא קיימות עסקאות ביטקס עבור כרטיס זה</Typography>
                </Grid>
              )}
            </Grid>
          </CustomAcordion>
        </Grid>
        <Grid item>
          <CustomAcordion summaryText="עסקאות ביטקס ים" defaultExpanded>
            <Grid container direction="column" spacing={3} justify="center">
              {this.state.justPrisaRows.length > 0 ? (
                <Grid item>
                  <CustomComplexTable
                    hideFirstCellsInRow={1}
                    headers={this.state.justPrisaHeaders}
                    rows={filteredJustPrisaRows}
                    handleDoubleClick={this.props.role === "admin" ? this.calcJustPrisa : null}
                    calcFunc={this.props.role === "admin" ? this.calcJustPrisa : null}
                    copyFunc={this.props.role === "admin" ? this.copyJustPrisa : null}
                    deleteFunc={this.props.role === "admin" ? this.deleteDeal : null}
                    dealsAllCount={this.state.yamDealsAllCount}
                    onPageChange={this.fetchYamDeals}
                    resetFilters={this.state.resetFiltersAllDeals}
                  />
                </Grid>
              ) : (
                <Grid item>
                  <Typography variant="h6">לא קיימות עסקאות ביטקס ים עבור כרטיס זה</Typography>
                </Grid>
              )}
            </Grid>
          </CustomAcordion>
        </Grid>
        <Grid item>
          <CustomAcordion summaryText="איפוס משתמשי סימולציה" defaultExpanded>
            <Grid container direction="column" spacing={3} justify="center">
              <Grid item>
                <Grid container direction="row" spacing={1} justify="center" alignItems="center">
                  <Grid item>
                    <InfoOutlinedIcon color="secondary" style={{ verticalAlign: "middle" }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" color="secondary">
                      שימו לב, איפוס משתמש בדיקה ימחק את כל העסקאות שבוצעו על ידו!
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {["test1", "test2", "test3"].map((user) => (
                <Grid item key={user}>
                  <Grid container direction="row" spacing={2} justify="center" alignItems="center">
                    <Grid item>
                      <LabeledTextField
                        type="password"
                        value={this.state[`${user}Pass`]}
                        name={`${user}Pass`}
                        label={`${user}@btax.com`}
                        textFieldWidth={190}
                        onChange={(e) => this.handleOnChange(e)}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        endIcon={<AutorenewIcon style={{ marginRight: "8px" }} />}
                        variant="contained"
                        color="secondary"
                        onClick={() => this.resetTestUserPassword(user)}
                      >
                        אפס סיסמה
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CustomAcordion>
        </Grid>
      </Grid>
    );
  }

  handleOnKeyPress = (e) => {
    if (e.key === "Enter" && Number.isFinite(parseInt(this.state.searchByCardNum))) {
      this.getDealsByCardNum(true);
    }
  };

  handleOnChange = (e) => {
    const newState = { ...this.state, [e.target.name]: e.target.value };
    this.setState(newState);
  };

  specificClientDealsTransfer = () => {
    if (!validateEmail(this.state.clientEmailNewBtax) || !validateEmail(this.state.clientEmailOldBtax)) {
      Swal.fire("Wrong email format", "", "warning");
      return;
    }

    Swal.fire({
      showCancelButton: true,
      title: "להעביר עסקאות?",
      text: "Continue?",
      icon: "warning"
    }).then((result) => {
      if (result.value) {
        adminService.specificClientDealsTransfer(this.state.clientEmailNewBtax, this.state.clientEmailOldBtax).then((res) => {
          if (res.success) {
            if (res.noNewEmail) {
              Swal.fire("מייל חדש לא נמצא במערכת", "", "warning");
              return;
            }
          }
        });
      }
    });
  };

  getEmailConfToken = () => {
    if (!validateEmail(this.state.clientEmailForConf)) {
      Swal.fire("Wrong email format", "", "warning");
      return;
    }
    salesService.getEmailConfToken(this.state.clientEmailForConf).then((res) => {
      if (res.success) {
        let salesmanMsg = "";
        if (res.noTokenData) salesmanMsg = "אין ללקוח עם המייל שהזנת TOKEN";
        else salesmanMsg = res.emailConfTokenLink;
        this.setState({ emailConfTokenLink: salesmanMsg });
      }
    });
  };

  resetTestUserPassword = (user) => {
    // TODO: Add validations.
    salesService.resetTestUserPassword(`${user}@btax.com`, this.state[`${user}Pass`]).then((res) => {
      if (res["success"]) {
        return Swal.fire("", `המשתמש ${user}@btax.com אופס בהצלחה`, "success");
      } else {
        return Swal.fire("", `איפוס משתמש נכשל`, "error");
      }
    });
  };

  getDealsByCardNum = (isTotalCount, newPage = 0) => {
    this.fetchBtaxDeals(isTotalCount, newPage);
    this.fetchYamDeals(isTotalCount, newPage);
  };

  fetchBtaxDeals = (isTotalCount, newPage) => {
    const { dealPrettyId, dealName } = this.state.searchDealsFilters;

    salesService.getAllDealsManager(this.state.searchByCardNum, "btax", dealPrettyId, dealName, isTotalCount, newPage * 10).then((res) => {
      if (res["success"]) {
        const dealRows = [];

        for (const deal of res.allDeals) {
          const row = [];
          row.push(deal._id);
          row.push(deal.prettyId || "");
          row.push(deal.manage.dealDescription || "");
          row.push(deal.fullName || "");
          row.push(moment(deal.dateCreated).format("DD/MM/YYYY") || "");
          row.push(
            deal.hasTaxToPay ? (
              <Grid container direction="row" spacing={1} wrap="nowrap" alignItems="center">
                <Grid item>
                  <WarningRoundedIcon style={{ color: customColors.red, fontSize: "25px", verticalAlign: "middle" }} />
                </Grid>
                <Grid item>
                  <Typography>חייב במס</Typography>
                </Grid>
              </Grid>
            ) : (
              ""
            )
          );
          row.push(moment(deal.dateCreated).add(30, "days").format("DD/MM/YYYY") || "");
          row.push(deal.manage.hasMasShevachReportsSubmitted ? "כן" : "לא");
          row.push(deal.manage.hasEaaratAzhara ? "כן" : "לא");
          row.push(deal.manage.haitelHashbacha && deal.manage.masRechisha && deal.manage.masShavach ? "כן" : "לא");
          row.push(deal.remindersCount || 0);
          dealRows.push(row);
        }
        const newState = { dealRows, btaxDealsAllCount: res.dealsAmount || this.state.btaxDealsAllCount };
        if (isTotalCount) {
          newState.resetFiltersAllDeals = Math.random();
        }
        this.setState(newState);
      }
    });
  };

  fetchYamDeals = (isTotalCount, newPage) => {
    const { dealPrettyId, dealName } = this.state.searchDealsFilters;

    salesService
      .getAllDealsManager(this.state.searchByCardNum, "btaxYam", dealPrettyId, dealName, isTotalCount, newPage * 10)
      .then((res) => {
        if (res["success"]) {
          const justPrisaRows = [];

          for (const deal of res.allDeals) {
            const row = [];
            row.push(deal._id);
            row.push(deal.prettyId || "");
            row.push(deal.manage.dealDescription || "");
            row.push(deal.fullName || "");
            row.push(moment(deal.dateCreated).format("DD/MM/YYYY") || "");
            justPrisaRows.push(row);
          }
          const newState = { justPrisaRows, yamDealsAllCount: res.dealsAmount || this.state.yamDealsAllCount };
          if (isTotalCount) {
            newState.resetFiltersAllDeals = Math.random();
          }
          this.setState(newState);
        }
      });
  };

  calcDeal = (row) => {
    if (row && Array.isArray(row) && row.length > 1 && row[0].length === 24) {
      this.props.history.push("/deal/calc/" + row[0]);
    } else {
      return Swal.fire("", "אירעה שגיאה", "error");
    }
  };

  calcJustPrisa = (row) => {
    if (row && Array.isArray(row) && row.length > 1 && row[0].length === 24) {
      this.props.history.push("/just-prisa/calc/" + row[0]);
    } else {
      return Swal.fire("", "אירעה שגיאה", "error");
    }
  };

  copyDeal = (row) => {
    if (row && Array.isArray(row) && row.length > 1 && row[0].length === 24) {
      dealsService
        .copyDeal(row[0])
        .then((res) => this.props.history.push("/deal/calc/" + res.newId))
        .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
    }
  };

  copyJustPrisa = (row) => {
    if (row && Array.isArray(row) && row.length > 1 && row[0].length === 24) {
      dealsService
        .copyDeal(row[0])
        .then((res) => this.props.history.push("/just-prisa/calc/" + res.newId))
        .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
    }
  };

  deleteDeal = (row) => {
    Swal.fire({
      icon: "warning",
      title: `האם אתה בטוח שברצונך למחוק את עסקה מספר ${row[1]}`,
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonColor: customColors.red,
      confirmButtonText: "מחק",
      preConfirm: () => {
        if (!row[0]) return Swal.showValidationMessage("מחיקה נכשלה");
        return dealsService
          .deleteDeal(row[0])
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed && result.value.success) {
        Swal.fire({
          icon: "success",
          title: `עסקה מספר ${row[1]} נמחקה בהצלחה`,
          confirmButtonText: "המשך",
          preConfirm: () => {}
        }).then((result) => {
          if (result.isConfirmed) this.props.history.go(0);
        });
      }
    });
  };
}

const mapStateToProps = (state) => {
  return {
    role: state.loggedinReducer.role
  };
};

export default connect(mapStateToProps)(UsersManagement);
