import React, { memo } from "react";
import { TextField, Typography, Box, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import HelpDialog from "./HelpDialog";

// Original optional props:
// name
// label
// placeholder
// value
// onChange
// disabled
// freeSolo

// Extended optional props:
// options: array of {label, value} (relevant for "select" type).
// helpDialog: HTML (enable the help dialog).
// textFieldWidth: TextField width in px deafult 180.
// labelWidth: Label width in px deafult null (auto).
// bold: set the label as bold.

// These props could change dynamically and should be check for rerenders.
const isEqual = (prevProps, nextProps) => {
  if (prevProps.value !== nextProps.value) return false;
  if (prevProps.label !== nextProps.label) return false;
  if (prevProps.disabled !== nextProps.disabled) return false;
  if (prevProps.options && nextProps.options) {
    if (prevProps.options.length !== nextProps.options.length) return false;
    else
      for (let i = 0; i < prevProps.options.length; i++) {
        if (prevProps.options[i].label !== nextProps.options[i].label) return false;
        if (prevProps.options[i].value !== nextProps.options[i].value) return false;
      }
  }
  return true;
};

const LabeledTextField = function (props) {
  const handleOnChange = function (e) {
    e.target = { value: e.target.textContent || e.target.value, name: props.name };
    props.onChange(e);
  };

  const textFieldStyle = {
    width: props.textFieldWidth ? props.textFieldWidth + "px" : "190px"
  };
  return (
    <Grid container item direction="row" spacing={1} alignItems="center">
      {props.label ? (
        <Grid item>
          <Box width={props.labelWidth ? props.labelWidth : null} marginBottom={props.helperText ? "22px" : ""}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="subtitle1" align="right" style={{ fontWeight: props.bold ? "bold" : null }}>
                  {props.label}
                </Typography>
              </Grid>
              {props.helpDialog ? (
                <Grid item>
                  <HelpDialog helpDialog={props.helpDialog} label={props.label} />
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Grid>
      ) : null}
      <Grid item>
        <Autocomplete
          disabled={props.disabled}
          // name={props.name}
          size="small"
          style={textFieldStyle}
          value={props.value}
          onChange={handleOnChange}
          onBlur={props.freeSolo ? handleOnChange : null}
          freeSolo={props.freeSolo}
          disableClearable
          options={props.options.map((option) => option.label)}
          renderInput={(params) => (
            <TextField
              placeholder={props.placeholder}
              {...params}
              variant="outlined"
              InputProps={{ ...params.InputProps, type: "search" }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default memo(LabeledTextField, isEqual);
