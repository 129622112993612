import React, { Component } from "react";
import { Typography, Checkbox, Button, FormControlLabel, Grid } from "@material-ui/core";
import logo from "../../assets/images/logo.png";
import TextFieldWithIcon from "../layout/textFieldWithIcon";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Alert } from "@material-ui/lab";
import userService from "../../services/usersService";
import { connect } from "react-redux";
import { setLoggedIn } from "../../store/actions/loggedinActions";
import { validateEmail } from "../../services/utils/utils";

class Login extends Component {
  state = {
    email: "",
    password: "",
    showConfMsg: false,
    shouldRememberEmail: false
  };

  constructor() {
    super();
    var emailForLogin = localStorage.getItem("emailForLogin");
    if (emailForLogin) {
      this.state.email = emailForLogin;
      this.state.shouldRememberEmail = true;
    }
  }

  render() {
    return (
      <div className="session-con">
        <div className="center">
          <img src={logo} alt="logo" />
        </div>

        <div className="mt-20">
          <div className="login-top-blue">
            <Typography>כניסה</Typography>
          </div>
          <div className="login-wrapper">
            <div className="mt-10">
              <TextFieldWithIcon
                value={this.state.email}
                onChange={this.handleChange}
                onKeyPress={(e) => this.handleOnKeyPress(e)}
                name="email"
                iconName="person"
                placeholder='דוא"ל'
                iconColor="#eee"
              />
            </div>
            <div className="mt-10 relative">
              <TextFieldWithIcon
                value={this.state.password}
                onChange={this.handleChange}
                onKeyPress={(e) => this.handleOnKeyPress(e)}
                name="password"
                type="password"
                iconName="lock"
                placeholder="סיסמה"
                iconColor="#eee"
              />

              <div className="forgot-pass-link">
                <Link to="/sessions/forgot-password">
                  <Typography variant="subtitle2">שכחתי סיסמה</Typography>
                </Link>
              </div>
            </div>

            <div className="right black-color">
              <FormControlLabel
                style={{ marginRight: "0" }}
                labelPlacement="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.shouldRememberEmail}
                    onClick={() => this.setState({ shouldRememberEmail: !this.state.shouldRememberEmail })}
                  />
                }
                label="זכור אותי"
              />
            </div>

            <div className="right mr-10">
              <Button onClick={this.handleSubmit} variant="contained">
                כניסה
              </Button>
            </div>
            {this.state.showConfMsg ? (
              <div className="mt-20 resend-conf-con">
                <Alert severity="warning">
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="subtitle2">לקוח יקר עליך לאשר את האימייל שלך על מנת להמשיך</Typography>
                    </Grid>
                    <Grid item>
                      <Link onClick={this.resendEmailConf}>
                        <Typography variant="subtitle2" align="center">
                          במידה ולא קבלת ניתן ללחוץ כאן לשליחה נוספת
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                  {/* <br />
                  <span className="simulate-link" onClick={this.resendEmailConf}>
                    במידה ולא קבלת ניתן ללחוץ כאן לשליחה נוספת
                  </span> */}
                </Alert>
              </div>
            ) : null}
            <div className="right mt-30 mr-10">
              <Link
                className="inline-block"
                to={{
                  pathname: "/sessions/register"
                }}
              >
                <Typography variant="subtitle2">רישום משרד חדש</Typography>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  resendEmailConf = () => {
    if (!this.state.email) return;
    userService.resendEmailConf(this.state.email).then((data) => {
      if (data.success) {
        Swal.fire("", 'דוא"ל נוסף נשלח בהצלחה', "success");
        this.setState({ showConfMsg: false });
      }
    });
  };

  handleChange = (e) => {
    const newState = {
      [e.target.name]: e.target.value
    };

    if (this.state.showConfMsg) newState.showConfMsg = false;
    this.setState(newState);
  };

  handleOnKeyPress(e) {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  }

  handleSubmit = () => {
    if (!this.state.email || !this.state.password) {
      Swal.fire("", "יש להזין מייל וסיסמה", "warning");
      return;
    }

    if (!validateEmail(this.state.email)) {
      Swal.fire("", 'פורמט דוא"ל לא חוקי', "warning");
      return;
    }

    if (this.state.password.length < 6) {
      Swal.fire("", "שם משתמש או סיסמה אינם נכונים", "warning");
      return;
    }

    userService.login(this.state.email, this.state.password).then((res) => {
      if (res["success"]) {
        res.data.shouldRememberEmail = this.state.shouldRememberEmail;
        this.props.setLoggedIn(res.data).then(() => {
          this.props.history.push("/my-deals");
        });
      } else if (res.isNotFound) {
        Swal.fire("", "שם משתמש או סיסמה אינם נכונים", "warning");
      } else if (res.isEmailNotConfirmed) {
        this.setState({ showConfMsg: true });
      } else if (res.isPendingUser) {
        Swal.fire("לקוח יקר", "פנייתך הינה כבר בטיפולנו. ניתן ליצור קשר עם שירות הלקוחות שלנו במידת הצורך", "success");
      } else if (res.needsRenewSubscription) {
        Swal.fire("המנוי אינו בתוקף", "בבקשה צור איתנו קשר :)", "info");
      } else if (res.needsToSetPass) {
        Swal.fire(
          "לקוח יקר",
          'יש צורך להפעיל משתמש זה. שלחנו דוא"ל אליך עם הוראות נוספות. במידה ואתה לא קבלת מייל זה בבקשה צור איתנו קשר',
          "info"
        );
      } else if (res.badStation) {
        Swal.fire("לקוח יקר", "משתמש זה כבר פעיל בעמדת מחשב אחרת. ניתן כמובן ליצור איתנו קשר במידה וברצונך לבצע שינוי", "info");
      } else if (res.isInvalidIp) {
        Swal.fire("כתובת IP לא תקינה", "בבקשה צור קשר עם אחראי המחשוב", "info");
      }
    });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (data) => dispatch(setLoggedIn(data))
  };
};

export default connect(null, mapDispatchToProps)(Login);
