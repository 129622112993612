import { Button, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import * as customColors from "../../theme/customColors";

var msgTypes = {
  "sent-lawyer": { title: "תודה לך", subTitle: "ניתן לסגור את העמוד.", txt: "כל הנתונים נשלחו לעורך הדין" },
  "reset-pass-msg": {
    title: "בקשתך לאיפוס סיסמה התקבלה בהצלחה",
    subTitle: 'נשלח אליך דוא"ל עם הוראות נוספות',
    txt: "",
    linkForRedirection: "/sessions/login",
    linkForRedirectionTxt: "לחץ להתחברות"
  }
};

class MessagePage extends Component {
  render() {
    var msgData = msgTypes[this.props.match.params.messageType];

    return (
      <div className="session-con">
        <div className="center">
          <img src={logo} alt="logo" />
        </div>

        <div className="mt-20 center">
          <div className="login-top-blue">
            <Typography>הודעת מערכת</Typography>
          </div>
          <div className="login-wrapper">
            <Typography variant="h6" style={{ color: customColors.sessionBlue }}>
              {msgData.title}
            </Typography>
            <Typography>{msgData.subTitle}</Typography>
            <Typography>{msgData.txt}</Typography>
            {msgData.linkForRedirection ? (
              <div className="mt-50">
                <hr className="mb-30" />
                <Button onClick={this.handleSubmit} variant="contained">
                  <Link style={{ color: "white", textDecoration: "none" }} to={msgData.linkForRedirection}>
                    {msgData.linkForRedirectionTxt}
                  </Link>
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      // <div className="row">
      //   <div className="col-sm-12 center mt-50">
      //     <Link to="/login">
      //       <img width="150" src={logo} />
      //     </Link>
      //   </div>
      //   <div className="col-sm-3"></div>
      //   <div className="col-sm-6">
      //     <br />
      //     <Card>
      //       <CardContent className="login-card">
      //         <h4 className="primary-color mb-40">{msgData.title}</h4>

      //         <p>
      //           {msgData.subTitle}
      //           <br />
      //           {msgData.txt}
      //         </p>

      //         {msgData.linkForRedirection ? (
      //           <div className="mt-120">
      //             <hr />
      //             <Link to={msgData.linkForRedirection}>{msgData.linkForRedirectionTxt}</Link>
      //           </div>
      //         ) : null}
      //       </CardContent>
      //     </Card>
      //   </div>
      // </div>
    );
  }
}

export default MessagePage;
