import Chart from "react-apexcharts";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Typography, Button, FormControlLabel, RadioGroup, Radio, Grid, FormControl, Box, Divider, Checkbox } from "@material-ui/core";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GetAppIcon from "@material-ui/icons/GetApp";
import LabeledTextField from "../../layout/LabeledTextField";
import CustomAcordion from "../../layout/CustomAcordion";
import CustomBasicTable from "../../layout/CustomBasicTable";
import HelpDialog from "../../layout/HelpDialog";
import helpTexts from "../../../assets/texts/helpTexts";
import * as customColors from "../../../theme/customColors";
import IsInheritancePtorAllowedDialog from "./dialogs/isInheritancePtorAllowedDialog";
import IsSinglePropertyPtorAllowedDialog from "./dialogs/isSinglePropertyPtorAllowedDialog";
import IsMutavAllowedDialog from "./dialogs/isMutavAllowedDialog";
import HasTzinunOnPtorDialog from "./dialogs/hasTzinunOnPtorDialog";
import HasMutavTzinunDialog from "./dialogs/hasMutavTzinunDialog";
import CombinationDialog from "./dialogs/combinationDialog";
import CustomLink from "../../layout/CustomLink";

class DealPreCalc extends Component {
  state = {
    isPreCalcResultsExpanded: false,
    dialogs: {
      dialogPart: 0,
      combinationDialogOpen: false,
      isMutavAllowedDialogOpen: false,
      isInheritancePtorAllowedDialogOpen: false,
      isSinglePropertyPtorAllowedDialogOpen: false,
      hasTzinunOnPtorDialogOpen: false,
      hasMutavTzinunDialogOpen: false
    }
  };

  render() {
    const isShown = this.isElementsShown();
    const sellerTypeOpts = [
      { value: "single", label: "יחיד" },
      { value: "organization", label: "חברה" }
    ];
    let propertyTypeOpts = [];
    if (this.props.sellerType === "single") {
      propertyTypeOpts = [
        { value: "dmm", label: "דירת מגורים מזכה" },
        { value: "otherProperty", label: "זכות אחרת" },
        { value: "sharesInUnion", label: "מניות באיגוד מקרקעין" }
      ];
    } else if (this.props.sellerType === "organization") {
      propertyTypeOpts = [
        { value: "apartment", label: "דירה" },
        { value: "otherProperty", label: "זכות אחרת" },
        { value: "sharesInUnion", label: "מניות באיגוד מקרקעין" }
      ];
    }
    const stepsBoughtOpts = [];
    for (let i = 1; i <= 6; i++) {
      stepsBoughtOpts.push({ value: i, label: `${i}` });
    }

    const percentHoldingSharesElement = isShown.percentHoldingSharesElement ? (
      <Grid item>
        <LabeledTextField
          type="number"
          label="אחוז האחזקה בחברה"
          name="percentHoldingShares"
          max={100}
          min={0}
          textFieldWidth={140}
          onChange={this.props.handlePreCalcOnChange}
          value={this.props.percentHoldingShares}
          helpDialog={helpTexts.percentHoldingShares}
        />
      </Grid>
    ) : null;
    const iazamCombinationPercentElement = isShown.iazamCombinationPercentElement ? (
      <Grid container item direction="row" spacing={1}>
        <Grid item>
          <LabeledTextField
            type="number"
            label="מה האחוז הנמכר ליזם? (%)"
            name="iazamCombinationPercent"
            max={99}
            min={1}
            textFieldWidth={140}
            labelWidth={190}
            onChange={this.props.handlePreCalcOnChange}
            value={this.props.iazamCombinationPercent}
            helperText={`יחס קומבינציה: ${100 - (this.props.iazamCombinationPercent || 0)}/${this.props.iazamCombinationPercent || 0}`}
            error={this.props.iazamCombinationPercent < 1 || this.props.iazamCombinationPercent > 99}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={() => this.openDialog("combinationDialogOpen")}>
            עזרה בחישוב שווי עסקת קומבינציה
          </Button>
        </Grid>
      </Grid>
    ) : null;
    const totalPropertyCostElement = isShown.totalPropertyCostElement ? (
      <Grid container item direction="row" spacing={1}>
        <Grid item>
          <LabeledTextField
            type="number"
            label="שווי הנכס הכולל"
            name="totalPropertyCost"
            textFieldWidth={140}
            labelWidth={190}
            onChange={this.props.handlePreCalcOnChange}
            value={this.props.totalPropertyCost}
            currency="₪"
          />
        </Grid>
      </Grid>
    ) : null;
    const valueActualSoldOfPropertyElement = isShown.valueActualSoldOfPropertyElement ? (
      <Grid container item direction="row" spacing={1}>
        <Grid item>
          <LabeledTextField
            type="number"
            label={
              this.props.howPropertyIsSold === "combination"
                ? `שווי הקומבינציה (${this.props.iazamCombinationPercent || 0}%)`
                : "שווי החלק הנמכר"
            }
            name="valueActualSoldOfProperty"
            textFieldWidth={140}
            labelWidth={190}
            onChange={this.props.handlePreCalcOnChange}
            value={this.props.valueActualSoldOfProperty}
            currency="₪"
          />
        </Grid>
      </Grid>
    ) : null;
    const totalPropertyCostWithoutZabanElement = isShown.totalPropertyCostWithoutZabanElement ? (
      <Grid container item direction="row" spacing={1}>
        <Grid item>
          <LabeledTextField
            type="number"
            label='שווי כל הנכס ללא זב"ן'
            name="totalPropertyCostWithoutZaban"
            textFieldWidth={140}
            labelWidth={190}
            onChange={this.props.handlePreCalcOnChange}
            value={this.props.totalPropertyCostWithoutZaban}
            helpDialog={helpTexts.totalPropertyCostWithoutZaban}
            currency="₪"
          />
        </Grid>

        <Grid item>
          <LabeledTextField
            type="number"
            label='כמה מטרים הזב"ן?'
            name="zabanMeters"
            textFieldWidth={140}
            labelWidth={190}
            onChange={this.props.handlePreCalcOnChange}
            value={this.props.zabanMeters}
          />
        </Grid>
      </Grid>
    ) : null;
    const cosnstructionsDoneBeforeApril1997Element = isShown.cosnstructionsDoneBeforeApril1997Element ? (
      <Grid container item direction="column" spacing={1}>
        <Box p={1}>
          <FormControl component="fieldset">
            <Grid container direction="row" spacing={3} alignItems="center">
              <Grid item xs={6}>
                <Typography variant="subtitle1" align="right">
                  האם רכישה ובניה של כל הזכויות בדירה הסתיימו לפני 1.4.1997?
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  row
                  name="cosnstructionsDoneBeforeApril1997"
                  value={Boolean(this.props.cosnstructionsDoneBeforeApril1997).toString()}
                  onChange={(e) => this.props.handlePreCalcOnChange(e)}
                >
                  <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                  <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                </RadioGroup>
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      </Grid>
    ) : null;
    const zabanValueElement = isShown.zabanValueElement ? (
      <Grid container item direction="row" spacing={1}>
        <Grid item>
          <LabeledTextField
            disabled
            type="number"
            label="שווי זכויות הבנייה"
            textFieldWidth={140}
            labelWidth={190}
            value={
              Number.isFinite(this.props.totalPropertyCost) &&
              Number.isFinite(this.props.totalPropertyCostWithoutZaban) &&
              this.props.totalPropertyCost - this.props.totalPropertyCostWithoutZaban >= 0
                ? this.props.totalPropertyCost - this.props.totalPropertyCostWithoutZaban
                : ""
            }
            currency="₪"
          />
        </Grid>
      </Grid>
    ) : null;
    const hasZabanAndSinglePropertyElements = isShown.hasZabanAndSinglePropertyElements ? (
      <Grid container item direction="column" xs={4} spacing={1}>
        <Grid container item direction="row">
          <FormControl disabled={this.props.howPropertyIsSold === "combination"} component="fieldset">
            <Grid container item direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="subtitle1" align="right">
                  האם יש זכויות בניה נוספות
                </Typography>
              </Grid>
              <Grid item>
                <HelpDialog helpDialog={helpTexts.hasZaban} label="האם יש זכויות בניה נוספות" />
              </Grid>
            </Grid>
            <RadioGroup
              row
              name="hasZaban"
              value={Boolean(this.props.hasZaban).toString()}
              onChange={(e) => this.props.handlePreCalcOnChange(e)}
            >
              <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
              <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {zabanValueElement}
        <Grid container item direction="row">
          <FormControl component="fieldset">
            <Grid container item direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="subtitle1" align="right">
                  האם זוהי דירתו היחידה של המוכר
                </Typography>
              </Grid>
              <Grid item>
                <HelpDialog helpDialog={helpTexts.isOnlyProperty} label="האם זוהי דירתו היחידה של המוכר" />
              </Grid>
            </Grid>
            <RadioGroup
              row
              value={Boolean(this.props.isOnlyProperty).toString()}
              name="isOnlyProperty"
              onChange={(e) => this.props.handlePreCalcOnChange(e)}
            >
              <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
              <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    ) : null;
    const pieElement = isShown.pieElement ? (
      <Grid item xs={3}>
        <Chart
          options={{
            title: {
              text: `סה"כ: ${Number(this.props.totalPropertyCost || 0).toLocaleString(undefined, { maximumFractionDigits: 2 })}`,
              align: "center",
              style: { fontSize: "12px" }
            },
            labels: ["דירה", "זכויות"],
            colors: [customColors.orange, customColors.lightBlue],
            legend: {
              horizontalAlign: "center",
              position: "bottom"
            },
            plotOptions: {
              pie: {
                donut: {
                  size: "30%"
                }
              }
            }
          }}
          series={[this.props.totalPropertyCostWithoutZaban, this.props.totalPropertyCost - this.props.totalPropertyCostWithoutZaban]}
          type="donut"
          width={280}
        />
      </Grid>
    ) : null;
    const dialogs = (
      <Fragment>
        {this.state.dialogs.combinationDialogOpen ? (
          <CombinationDialog
            handleResults={(e1, e2) => this.props.handleCombinationOnChange(e1, e2)}
            iazamCombinationPercent={this.props.iazamCombinationPercent}
            onClose={() => this.closeDialog("combinationDialogOpen")}
            open={true}
          />
        ) : null}
        {this.state.dialogs.isMutavAllowedDialogOpen ? (
          <IsMutavAllowedDialog
            handleResults={(e) => this.props.handlePreCalcPartOnChange(e, this.state.dialogs.dialogPart)}
            onClose={() => this.closeDialog("isMutavAllowedDialogOpen")}
            open={true}
          />
        ) : null}
        {this.state.dialogs.isInheritancePtorAllowedDialogOpen ? (
          <IsInheritancePtorAllowedDialog
            handleResults={(e) => this.props.handlePreCalcPartOnChange(e, this.state.dialogs.dialogPart)}
            onClose={() => this.closeDialog("isInheritancePtorAllowedDialogOpen")}
            open={true}
          />
        ) : null}
        {this.state.dialogs.isSinglePropertyPtorAllowedDialogOpen ? (
          <IsSinglePropertyPtorAllowedDialog
            isBefore2018={this.props.soldPropertyDate < "2018-01-01"}
            handleResults={(e) => this.props.handlePreCalcPartOnChange(e, this.state.dialogs.dialogPart)}
            onClose={() => this.closeDialog("isSinglePropertyPtorAllowedDialogOpen")}
            open={true}
          />
        ) : null}
        {this.state.dialogs.hasTzinunOnPtorDialogOpen ? (
          <HasTzinunOnPtorDialog
            soldPropertyDate={this.props.soldPropertyDate}
            handleResults={(e) => this.props.handlePreCalcPartOnChange(e, this.state.dialogs.dialogPart)}
            onClose={() => this.closeDialog("hasTzinunOnPtorDialogOpen")}
            open={true}
          />
        ) : null}
        {this.state.dialogs.hasMutavTzinunDialogOpen ? (
          <HasMutavTzinunDialog
            soldPropertyDate={this.props.soldPropertyDate}
            handleResults={(e) => this.props.handlePreCalcPartOnChange(e, this.state.dialogs.dialogPart)}
            onClose={() => this.closeDialog("hasMutavTzinunDialogOpen")}
            open={true}
          />
        ) : null}
      </Fragment>
    );
    const acordionElement = isShown.acordionElement
      ? this.props.preCalcPartsData.map((part, index) => {
          return (
            <Grid key={`purchse-details-${index + 1}`} item>
              <CustomAcordion summaryText={`פרטי רכישה ${index + 1}`} defaultExpanded={true}>
                <Grid container item direction="column" spacing={2} wrap="nowrap">
                  <Grid container item direction="row" wrap="nowrap">
                    {this.props.preCalcPartsData.length > 1 ? (
                      <Grid container item direction="row" spacing={1} justify="flex-start" alignItems="center" xs={5}>
                        <Grid item>
                          <LabeledTextField
                            type="number"
                            label="האחוז הנרכש %"
                            name="percentBought"
                            textFieldWidth={70}
                            max={100}
                            min={0}
                            onChange={(e) => this.props.handlePreCalcPartOnChange(e, index)}
                            value={part.percentBought}
                          />
                        </Grid>
                        <Grid item>
                          <LabeledTextField
                            disabled
                            type="number"
                            textFieldWidth={120}
                            value={
                              Number.isFinite(this.props.valueActualSoldOfProperty) && Number.isFinite(part.percentBought)
                                ? (this.props.valueActualSoldOfProperty * part.percentBought) / 100
                                : ""
                            }
                            currency="₪"
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                    <Grid container item direction="row" justify="flex-start" alignItems="center" xs={7}>
                      <FormControl component="fieldset">
                        <Grid container item direction="row" spacing={1} justify="flex-start" alignItems="center">
                          <Grid item>
                            <Typography variant="subtitle1" align="right">
                              אופן קבלת הממכר
                            </Typography>
                          </Grid>
                          {this.props.sellerType === "single" && this.props.propertyType === "dmm" ? (
                            <Grid item>
                              <HelpDialog helpDialog={helpTexts.wayOfGettingProperty} label="אופן קבלת הממכר" />
                            </Grid>
                          ) : null}
                          <Grid item>
                            <RadioGroup
                              value={part.wayOfGettingProperty}
                              row
                              name="wayOfGettingProperty"
                              onChange={(e) => this.props.handlePreCalcPartOnChange(e, index)}
                            >
                              <Grid container item direction="row" spacing={3}>
                                <Grid item>
                                  <FormControlLabel
                                    value={"purchase"}
                                    control={<Radio color="primary" />}
                                    label="רכישה"
                                    labelPlacement="end"
                                  />
                                </Grid>
                                <Grid item>
                                  <FormControlLabel
                                    value={"inheritance"}
                                    control={<Radio color="primary" />}
                                    label="ירושה"
                                    labelPlacement="end"
                                  />
                                </Grid>
                                <Grid item>
                                  <FormControlLabel value={"gift"} control={<Radio color="primary" />} label="מתנה" labelPlacement="end" />
                                </Grid>
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {this.props.sellerType === "single" && this.props.propertyType === "dmm" ? (
                    <Fragment>
                      <Grid item>
                        <Divider light />
                      </Grid>
                      <Grid item>
                        <FormControl fullWidth component="fieldset">
                          <Grid container direction="row" wrap="nowrap">
                            <Grid container item direction="row" alignItems="center" spacing={1} xs={3}>
                              <Grid item>
                                <Typography variant="subtitle1" align="right">
                                  סוג הפטור המבוקש
                                </Typography>
                              </Grid>
                              <Grid item>
                                <HelpDialog helpDialog={helpTexts.kindOfRequestedPtor} label="סוג הפטור המבוקש" />
                              </Grid>
                            </Grid>
                            <Grid container item direction="column" justify="center" alignItems="flex-start" xs={2}>
                              <Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                  <FormControlLabel
                                    labelPlacement="end"
                                    control={
                                      <Checkbox
                                        disabled={this.props.soldPropertyDate >= "2018-01-01"}
                                        checked={part.isMutavAllowed}
                                        value={!part.isMutavAllowed}
                                        onChange={(e) => this.props.handlePreCalcPartOnChange(e, index)}
                                        name="isMutavAllowed"
                                        color="primary"
                                      />
                                    }
                                    label="מס מוטב"
                                  />
                                </Grid>
                                {this.props.soldPropertyDate >= "2018-01-01" ? (
                                  <Grid item>
                                    <HelpDialog helpDialog={helpTexts.isMutavAllowed} label="מס מוטב" />
                                  </Grid>
                                ) : null}
                              </Grid>
                              <Grid item>
                                <CustomLink
                                  disabled={!(this.props.soldPropertyDate < "2018-01-01")}
                                  label="בדוק זכאות"
                                  onClick={() => this.openDialog("isMutavAllowedDialogOpen", index)}
                                />
                              </Grid>
                            </Grid>
                            <Grid container item direction="column" justify="center" alignItems="flex-start" xs={2}>
                              <Grid item>
                                <FormControlLabel
                                  labelPlacement="end"
                                  control={
                                    <Checkbox
                                      disabled={part.wayOfGettingProperty !== "inheritance"}
                                      checked={part.isInheritancePtorAllowed}
                                      value={!part.isInheritancePtorAllowed}
                                      onChange={(e) => this.props.handlePreCalcPartOnChange(e, index)}
                                      name="isInheritancePtorAllowed"
                                      color="primary"
                                    />
                                  }
                                  label="פטור ירושה"
                                />
                              </Grid>
                              <Grid item>
                                {/* <CustomLink
                                  disabled={!(part.wayOfGettingProperty === "inheritance")}
                                  label="בדוק זכאות"
                                  onClick={() => this.openDialog("isInheritancePtorAllowedDialogOpen", index)}
                                /> */}
                              </Grid>
                            </Grid>
                            <Grid container item direction="column" justify="center" alignItems="flex-start" xs={2}>
                              <Grid item>
                                <FormControlLabel
                                  labelPlacement="end"
                                  control={
                                    <Checkbox
                                      disabled={!this.props.isOnlyProperty}
                                      checked={this.props.isOnlyProperty && part.isSinglePropertyPtorAllowed}
                                      value={!part.isSinglePropertyPtorAllowed}
                                      onChange={(e) => this.props.handlePreCalcPartOnChange(e, index)}
                                      name="isSinglePropertyPtorAllowed"
                                      color="primary"
                                    />
                                  }
                                  label="פטור דירה יחידה"
                                />
                              </Grid>
                              <Grid item>
                                <CustomLink
                                  disabled={!this.props.isOnlyProperty}
                                  label="בדוק זכאות"
                                  onClick={() => this.openDialog("isSinglePropertyPtorAllowedDialogOpen", index)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Divider light />
                      </Grid>
                      <Grid container item direction="row">
                        <FormControl
                          disabled={!part.isSinglePropertyPtorAllowed || part.wayOfGettingProperty !== "gift"}
                          component="fieldset"
                        >
                          <Grid container item direction="row" spacing={10}>
                            <Grid item>
                              <Box width="350px">
                                <Typography
                                  color={
                                    part.isSinglePropertyPtorAllowed && part.wayOfGettingProperty === "gift"
                                      ? "textPrimary"
                                      : "textSecondary"
                                  }
                                  variant="subtitle1"
                                  align="right"
                                >
                                  האם קיימת מגבלת צינון לשימוש בפטור דירה יחידה?
                                </Typography>
                                <CustomLink
                                  disabled={!part.isSinglePropertyPtorAllowed || part.wayOfGettingProperty !== "gift"}
                                  label="בדוק מגבלה"
                                  onClick={() => this.openDialog("hasTzinunOnPtorDialogOpen", index)}
                                />
                              </Box>
                            </Grid>
                            <Grid item>
                              <RadioGroup
                                value={
                                  !part.isSinglePropertyPtorAllowed || part.wayOfGettingProperty !== "gift"
                                    ? ""
                                    : Boolean(part.hasTzinunOnPtor).toString()
                                }
                                row
                                name="hasTzinunOnPtor"
                                onChange={(e) => this.props.handlePreCalcPartOnChange(e, index)}
                              >
                                <Grid container item direction="row" spacing={10}>
                                  <Grid item>
                                    <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                                  </Grid>
                                  <Grid item>
                                    <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                                  </Grid>
                                </Grid>
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                      {this.props.soldPropertyDate < "2018-01-01" ? (
                        <Fragment>
                          <Grid item>
                            <Divider light />
                          </Grid>
                          <Grid container item direction="row" justify="flex-start" alignItems="center">
                            <FormControl disabled={!part.isMutavAllowed || part.wayOfGettingProperty !== "gift"} component="fieldset">
                              <Grid container item direction="row" spacing={10}>
                                <Grid item>
                                  <Box width="350px">
                                    <Typography
                                      color={part.isMutavAllowed && part.wayOfGettingProperty === "gift" ? "textPrimary" : "textSecondary"}
                                      variant="subtitle1"
                                      align="right"
                                    >
                                      האם קיימת מגבלת צינון לשימוש במס מוטב?
                                    </Typography>
                                    <CustomLink
                                      disabled={!part.isMutavAllowed || part.wayOfGettingProperty !== "gift"}
                                      label="בדוק מגבלה"
                                      onClick={() => this.openDialog("hasMutavTzinunDialogOpen", index)}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <RadioGroup
                                    value={
                                      !part.isMutavAllowed || part.wayOfGettingProperty !== "gift"
                                        ? ""
                                        : Boolean(part.hasMutavTzinun).toString()
                                    }
                                    row
                                    name="hasMutavTzinun"
                                    onChange={(e) => this.props.handlePreCalcPartOnChange(e, index)}
                                  >
                                    <Grid container item direction="row" spacing={10}>
                                      <Grid item>
                                        <FormControlLabel
                                          value={"true"}
                                          control={<Radio color="primary" />}
                                          label="כן"
                                          labelPlacement="end"
                                        />
                                      </Grid>
                                      <Grid item>
                                        <FormControlLabel
                                          value={"false"}
                                          control={<Radio color="primary" />}
                                          label="לא"
                                          labelPlacement="end"
                                        />
                                      </Grid>
                                    </Grid>
                                  </RadioGroup>
                                </Grid>
                              </Grid>
                            </FormControl>
                          </Grid>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  ) : null}
                </Grid>
              </CustomAcordion>
            </Grid>
          );
        })
      : null;
    const tablesData = this.props.hasResults ? this.resultsTableCalculation() : null;
    const hasTaxToPay = tablesData ? this.hasTaxToPay(tablesData[0]) : false;
    const preCalcResults = this.props.hasResults ? (
      <Grid item>
        <CustomAcordion summaryText="תוצאות ביניים" defaultExpanded={true}>
          <Grid container direction="column" spacing={2}>
            <Grid container item direction="row" spacing={2}>
              <Grid item>
                {hasTaxToPay ? (
                  <WarningRoundedIcon style={{ color: customColors.red, fontSize: "40px" }} />
                ) : (
                  <CheckCircleRoundedIcon style={{ color: customColors.green, fontSize: "40px" }} />
                )}
              </Grid>
              <Grid item>
                {hasTaxToPay ? <Typography variant="h4">חייב במס</Typography> : <Typography variant="h4">לא חייב במס</Typography>}
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={3}>
              <Grid item xs={1}>
                <Typography variant="h5">תוצאה</Typography>
              </Grid>
              <Grid container item direction="column" spacing={2} xs={10}>
                <Grid item>
                  <CustomBasicTable name="main" headers={tablesData[0].headers} rows={tablesData[0].body} />
                </Grid>
                {tablesData && tablesData.length > 1 ? (
                  <Fragment>
                    <Grid container item direction="row">
                      <Button
                        style={{ width: "130px" }}
                        endIcon={
                          this.state.isPreCalcResultsExpanded ? (
                            <ArrowDownwardIcon style={{ marginRight: "8px" }} />
                          ) : (
                            <ArrowBackIcon style={{ marginRight: "8px" }} />
                          )
                        }
                        variant="contained"
                        color="secondary"
                        name="isPreCalcResultsExpanded"
                        onClick={() => this.handlePreCalcResultExpanded()}
                      >
                        פירוט נוסף
                      </Button>
                    </Grid>
                    {this.state.isPreCalcResultsExpanded
                      ? tablesData
                          .filter((table, index) => index > 0)
                          .map((table, index) => (
                            <Grid item>
                              <CustomBasicTable
                                key={`part-${index + 1}`}
                                name={`part-${index + 1}`}
                                headers={table.headers}
                                rows={table.body}
                              />
                            </Grid>
                          ))
                      : null}
                  </Fragment>
                ) : null}
              </Grid>
            </Grid>
            {this.props.recommendations ? (
              <Fragment>
                <Grid item>
                  <Divider light />
                </Grid>
                <Grid item>
                  <Box borderColor={customColors.green} border={1} p={2}>
                    <Typography align="right" variant="h6" style={{ fontWeight: "bold" }}>
                      המלצות:
                    </Typography>
                    <Typography align="right" variant="h6">
                      {this.props.soldPropertyDate >= "2018-01-01"
                        ? this.props.recommendations.recAfter2018
                        : this.props.recommendations.recBefore2018}
                    </Typography>
                  </Box>
                </Grid>
              </Fragment>
            ) : null}
            {this.props.hasZaban &&
            Number.isFinite(this.props.totalPropertyCost) &&
            Number.isFinite(this.props.totalPropertyCostWithoutZaban) &&
            this.props.totalPropertyCost - this.props.totalPropertyCostWithoutZaban <= 100000 ? (
              <Grid item>
                <Box borderColor={customColors.green} border={1} p={2}>
                  <Typography align="right" variant="h6">
                    על פי הוראת ביצוע 6/98, זכויות בניה בהיקף זניח ששויו עד 100000 ש"ח, או מהוות עד 10% משטח הדירה(לפי הנמוך מבינהם), לא
                    ייחשבו כמשפיעות על התמורה. אם תנאי זה מתקיים, מומלץ לפנות למנהל מס שבח ולבקש שלא לשלם מס שבח בגין זכויות בניה אלו.
                  </Typography>
                </Box>
              </Grid>
            ) : null}

            {this.props.miraArielMsg ? (
              <Grid item>
                <Box borderColor={customColors.green} border={1} p={2}>
                  <Typography align="right" variant="h6">
                    המערכת זיהתה כי לפי הנתונים שהוזנו, יתכן והמוכר זכאי לניצול גבוה יותר של תקרת הפטור בהתאם לפס"ד ו"ע 44028-01-16 מירה
                    אריאל. מומלץ לפנות לעו"ד לייעוץ משפטי.
                  </Typography>
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </CustomAcordion>
      </Grid>
    ) : null;

    return (
      <Grid container direction="column" spacing={2}>
        <Grid container item direction="row" spacing={1}>
          <Grid item>
            <LabeledTextField
              type="select"
              label="זהות המוכר"
              name="sellerType"
              labelWidth={110}
              onChange={this.props.handlePreCalcOnChange}
              value={this.props.sellerType}
              helpDialog={helpTexts.sellerType}
              options={sellerTypeOpts}
            />
          </Grid>
          <Grid item>
            <div className="mr-30">
              <LabeledTextField
                type="select"
                label="סוג הממכר"
                name="propertyType"
                labelWidth={105}
                onChange={this.props.handlePreCalcOnChange}
                value={this.props.propertyType}
                helpDialog={helpTexts.propertyType}
                options={propertyTypeOpts}
              />
            </div>
          </Grid>
          {percentHoldingSharesElement}
          <Grid item>
            <div className="mr-30">
              <LabeledTextField
                type="date"
                label="מועד מכירה"
                name="soldPropertyDate"
                labelWidth={80}
                onChange={this.props.handlePreCalcOnChange}
                value={this.props.soldPropertyDate}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item>
          <Divider light />
        </Grid>
        <Grid container item direction="row">
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="howPropertyIsSold"
              value={this.props.howPropertyIsSold}
              onChange={(e) => this.props.handlePreCalcOnChange(e)}
            >
              <Grid container item direction="row" spacing={3}>
                <Grid item>
                  <FormControlLabel
                    value="allPropertySold"
                    control={<Radio color="primary" />}
                    label="מכירה של כל הזכויות בנכס"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value="partOfPropertySold"
                    control={<Radio color="primary" />}
                    label="מכירה של חלק מהזכויות בנכס"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    disabled={!this.props.abilities.includes("combination")}
                    value="combination"
                    control={<Radio color="primary" />}
                    label="עסקת קומבינציה"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        {iazamCombinationPercentElement}
        <Grid container item direction="row" spacing={3}>
          <Grid container item direction="column" spacing={1} xs={4}>
            {/* <Grid container item direction="row" spacing={1}>
              <Grid item>
                <LabeledTextField
                  type="number"
                  label={
                    this.props.howPropertyIsSold === "combination"
                      ? `שווי הקומבינציה (${this.props.iazamCombinationPercent || 0}%)`
                      : "שווי הנכס הכולל"
                  }
                  name="totalPropertyCost"
                  textFieldWidth={140}
                  labelWidth={190}
                  onChange={this.props.handlePreCalcOnChange}
                  value={this.props.totalPropertyCost}
                  currency="₪"
                />
              </Grid>
            </Grid> */}
            {totalPropertyCostElement}
            {valueActualSoldOfPropertyElement}
            {totalPropertyCostWithoutZabanElement}
            {cosnstructionsDoneBeforeApril1997Element}
            <Grid item>
              <LabeledTextField
                type="select"
                label="בכמה שלבים נרכש הנכס?"
                name="stepsBought"
                textFieldWidth={140}
                labelWidth={190}
                onChange={this.props.handlePreCalcOnChange}
                value={this.props.stepsBought}
                options={stepsBoughtOpts}
              />
            </Grid>
          </Grid>
          {hasZabanAndSinglePropertyElements}
          {pieElement}
        </Grid>
        <Grid container item direction="column" wrap="nowrap">
          {acordionElement}
          {preCalcResults}
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item>
            <Button
              disabled={this.props.hasResults}
              style={{ minWidth: "130px" }}
              endIcon={<PlayArrowIcon style={{ marginRight: "8px" }} />}
              variant="contained"
              color="secondary"
              onClick={this.props.preCalcStart}
            >
              נתח עסקה
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{ minWidth: "130px" }}
              endIcon={<SaveIcon style={{ marginRight: "8px" }} />}
              variant="contained"
              color="secondary"
              onClick={() => this.props.updateDealData()}
            >
              שמירה
            </Button>
          </Grid>
          {hasTaxToPay ? (
            <Grid item>
              <Button
                style={{ minWidth: "130px" }}
                endIcon={<ArrowBackIosIcon style={{ marginRight: "8px" }} />}
                variant="contained"
                color="secondary"
                onClick={() => this.props.handleStepChange(2)}
              >
                המשך
              </Button>
            </Grid>
          ) : null}
          {this.props.role === "admin" ? (
            <Grid item>
              <Button
                style={{ minWidth: "130px" }}
                endIcon={<GetAppIcon style={{ marginRight: "8px" }} />}
                variant="contained"
                color="primary"
                onClick={() => this.props.exportDealData()}
              >
                ייצא
              </Button>
            </Grid>
          ) : null}
        </Grid>
        {dialogs}
      </Grid>
    );
  }

  isElementsShown() {
    return {
      percentHoldingSharesElement: this.props.sellerType === "single" && this.props.propertyType === "sharesInUnion",
      iazamCombinationPercentElement: this.props.howPropertyIsSold === "combination",
      totalPropertyCostElement: this.props.howPropertyIsSold !== "combination",
      valueActualSoldOfPropertyElement:
        this.props.howPropertyIsSold === "partOfPropertySold" || this.props.howPropertyIsSold === "combination",
      // zabanValueElement: this.props.howPropertyIsSold === "combination" || this.props.hasZaban,
      zabanValueElement: this.props.hasZaban,
      // totalPropertyCostWithoutZabanElement: this.props.howPropertyIsSold === "combination" || this.props.hasZaban,
      totalPropertyCostWithoutZabanElement: this.props.hasZaban,
      cosnstructionsDoneBeforeApril1997Element:
        this.props.hasZaban &&
        Number.isFinite(this.props.totalPropertyCostWithoutZaban) &&
        this.props.totalPropertyCostWithoutZaban < 303501,
      hasZabanAndSinglePropertyElements: this.props.sellerType === "single" && this.props.propertyType === "dmm",
      pieElement:
        Number.isFinite(this.props.totalPropertyCost) &&
        Number.isFinite(this.props.totalPropertyCostWithoutZaban) &&
        this.props.totalPropertyCost - this.props.totalPropertyCostWithoutZaban &&
        this.props.sellerType === "single" &&
        this.props.propertyType === "dmm" &&
        this.props.hasZaban &&
        this.props.totalPropertyCost - this.props.totalPropertyCostWithoutZaban >= 0,
      acordionElement:
        this.props.sellerType === "single" || (Array.isArray(this.props.preCalcPartsData) && this.props.preCalcPartsData.length > 1)
    };
  }

  openDialog(dialogName, part) {
    this.setState({ dialogs: { ...this.state.dialogs, [dialogName]: true, dialogPart: part || 0 } });
  }

  closeDialog(dialogName) {
    this.setState({ dialogs: { ...this.state.dialogs, [dialogName]: false, dialogPart: 0 } });
  }

  handlePreCalcResultExpanded() {
    this.setState({ isPreCalcResultsExpanded: !this.state.isPreCalcResultsExpanded });
  }

  // Check in summary table if there tax to pay.
  // May be replaced with hasTaxToPay() of dealsContainer.
  hasTaxToPay(table) {
    if (!table || !table.body || !Array.isArray(table.body) || !Array.isArray(table.body[0])) return false;
    const rowLength = table.body[0].length;
    for (let i = 0; i < table.body.length; i++) {
      if (table.body[i][rowLength - 1] > 0 || table.body[i][rowLength - 2] > 0) {
        return true;
      }
    }
    return false;
  }

  // Calculate the summary table and the part specific tables.
  // First table always will be the summary table.
  resultsTableCalculation() {
    const tables = this.props.preCalcPartsData.map((part, index) => {
      const table = {};
      table.headers = [`חלק ${index + 1}`, "סכום הפטור", "סוג הפטור", "סכום חייב לינארי ישן", "סכום חייב מס מוטב"];
      table.body = [];
      if ((part.baseAmount || part.baseAmount === 0) && part.baseMethodType) {
        const row = new Array(5);
        row[0] = "שווי התמורה עד תקרת הפטור";
        row[1] = ["SINGLE_PROPERTY_PTOR", "INHERITANCE_PTOR"].includes(part.baseMethodType) ? part.baseAmount : 0;
        if (part.baseMethodType === "SINGLE_PROPERTY_PTOR") row[2] = "פטור דירה יחידה, 49ב'(2)";
        else if (part.baseMethodType === "INHERITANCE_PTOR") row[2] = "פטור דירה בירושה, 49ב'(5)";
        else if (part.baseMethodType === "TAX_MUTAV") row[2] = "מס מוטב, 48א'(ב2)";
        else row[2] = "";
        row[3] = part.baseMethodType === "REG_TAX" ? part.baseAmount : 0;
        row[4] = part.baseMethodType === "TAX_MUTAV" ? part.baseAmount : 0;
        table.body.push(row);
      }
      if ((part.deltaAmount || part.deltaAmount === 0) && part.deltaMethodType) {
        const row = new Array(5);
        row[0] = "שווי התמורה מעל תקרת הפטור";
        row[1] = 0;
        if (part.deltaMethodType === "TAX_MUTAV") row[2] = "מס מוטב, 48א'(ב2)";
        else row[2] = "";
        row[3] = part.deltaMethodType === "REG_TAX" ? part.deltaAmount : 0;
        row[4] = part.deltaMethodType === "TAX_MUTAV" ? part.deltaAmount : 0;
        table.body.push(row);
      }
      if ((part.zabanAllowedAnyPtor || part.zabanAllowedAnyPtor === 0) && (part.zabanOldLinear || part.zabanOldLinear === 0)) {
        const row = new Array(5);
        row[0] = "שווי זכויות בניה נוספות";
        row[1] = ["SINGLE_PROPERTY_PTOR", "INHERITANCE_PTOR"].includes(part.baseMethodType) ? part.zabanAllowedAnyPtor : 0;
        row[2] = part.zabanAllowedAnyPtor > 0 ? "49ז'" : "";
        row[3] = part.zabanOldLinear || 0;
        row[4] = !["SINGLE_PROPERTY_PTOR", "INHERITANCE_PTOR"].includes(part.baseMethodType) ? part.zabanAllowedAnyPtor : 0;
        table.body.push(row);
      }
      return table;
    });
    if (tables.length === 1) {
      tables[0].headers[0] = "טבלה מרכזת";
    } else {
      const sumTable = {};
      sumTable.headers = ["טבלה מרכזת", "סכום פטור", "סכום חייב לינארי ישן", "סכום חייב מס מוטב"];
      sumTable.body = new Array(tables[0].body.length);
      for (let i = 0; i < sumTable.body.length; i++) {
        sumTable.body[i] = new Array(4);
        sumTable.body[i][0] = tables[0].body[i][0];
        sumTable.body[i][1] = 0;
        sumTable.body[i][2] = 0;
        sumTable.body[i][3] = 0;
      }
      for (let i = 0; i < tables.length; i++) {
        const table = tables[i];
        for (let row = 0; row < table.body.length; row++) {
          for (let cell = 1; cell < table.body[row].length; cell++) {
            if (cell === 2) continue;
            if (cell >= 3) sumTable.body[row][cell - 1] += table.body[row][cell];
            else sumTable.body[row][cell] += table.body[row][cell];
          }
        }
      }
      tables.unshift(sumTable);
    }
    return tables;
  }
}

const mapStateToProps = (state) => {
  return {
    abilities: state.loggedinReducer.abilities,
    role: state.loggedinReducer.role
  };
};

export default connect(mapStateToProps)(DealPreCalc);
