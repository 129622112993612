import React, { Component, Fragment } from "react";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import Swal from "sweetalert2";
import moment from "moment";

import dealsService from "../services/dealsService";
import CalculatorIcon from "../components/layout/CalculatorIcon";
import JustPrisaCalc from "../components/justPrisa/calc/justPrisaCalc";
import ManageComponent from "../components/shared/manageComponent";
import TabContainer from "../components/layout/TabContainer";

const topNavOpts = {
  CALC: "CALC",
  MANAGE: "MANAGE"
};

class PrisaContainer extends Component {
  state = {
    dealId: null,
    prettyId: null,
    containerTabs: {
      currentNavFocused: topNavOpts.CALC,
      tabs: [
        {
          key: topNavOpts.CALC,
          text: "חישוב מס שבח",
          icon: <CalculatorIcon color="secondary" />,
          path: "/just-prisa/calc/:dealId",
          component: (props) => <JustPrisaCalc {...props} />,
          stateFamily: "calc",
          functions: {}
        },
        {
          key: topNavOpts.MANAGE,
          text: "ניהול עסקה",
          icon: <AccountTreeIcon color="secondary" />,
          path: "/just-prisa/manage/:dealId",
          component: (props) => <ManageComponent {...props} isFullManageComponent={false} />,
          stateFamily: "manage",
          functions: {}
        }
      ],
      isLoaded: true
    },
    calc: {
      hasResults: false,
      finalLinear47: null,
      finalLinear20: null,
      finalLinear25: null,
      finalInflationLinear10: null,
      finalMutav25: null,
      soldPropertyDate: "",
      datePropertyBought: "",
      prisa: {
        shouldTryPrisa: true,
        personsData: [
          {
            personName: "",
            isMaximal: true,
            personOwningPercent: 1,
            birthOfClient: "",
            dataPerYear: [
              { income: null, zikui: 0 },
              { income: null, zikui: 0 },
              { income: null, zikui: 0 },
              { income: null, zikui: 0 }
            ]
          }
        ]
      }
    },
    manage: { dealDescription: "" }
  };

  constructor(props) {
    super(props);
    this.state.containerTabs.tabs[0].functions = {
      justPrisaCalc: this.justPrisaCalc,
      handleCalcOnChange: this.handleCalcOnChange,
      handlePrisaCalcOnChange: this.handlePrisaCalcOnChange,
      getPrisaMaxYears: this.getPrisaMaxYears,
      updateDealData: this.updateDealData,
      exportDealData: this.exportDealData
    };
    this.state.containerTabs.tabs[1].functions = { handleManageOnChange: this.handleManageOnChange, updateDealData: this.updateDealData };
  }

  componentDidMount() {
    window["scrollTo"]({ top: 0, behavior: "smooth" });
    const pathname = window.location.pathname;
    const startIndex = pathname.indexOf("/", 1) + 1;
    const lastIndex = pathname.lastIndexOf("/");
    const getOpt = pathname.substring(startIndex, lastIndex);
    const dealId = this.props.match.params.dealId;
    dealsService.getDealData(dealId).then((res) => {
      if (res.success) {
        if (!res.dealData || res.dealData.softwareServiceType !== "btaxYam") {
          this.props.history.replace("/my-deals");
        } else {
          const manage = res.dealData.manage ? this.parseStateManageFromServer(res.dealData.manage) : { ...this.state.manage };
          const calc = res.dealData.calc ? this.parseStateCalcFromServer(res.dealData.calc) : { ...this.state.calc };
          const prettyId = res.dealData.prettyId;
          if (getOpt && topNavOpts[getOpt.toUpperCase()]) {
            const containerTabs = { ...this.state.containerTabs, currentNavFocused: topNavOpts[getOpt.toUpperCase()] };
            this.setState({ containerTabs, calc, manage, dealId, prettyId });
          } else {
            const containerTabs = { ...this.state.containerTabs, currentNavFocused: topNavOpts.CALC };
            this.setState({ containerTabs, calc, manage, dealId, prettyId });
          }
        }
      } else {
        this.props.history.replace("/my-deals");
      }
    });
  }

  componentDidUpdate() {
    const pathname = window.location.pathname;
    const startIndex = pathname.indexOf("/", 1) + 1;
    const lastIndex = pathname.lastIndexOf("/");
    const getOpt = pathname.substring(startIndex, lastIndex);
    if (this.state.containerTabs.currentNavFocused !== topNavOpts[getOpt.toUpperCase()]) {
      const containerTabs = { ...this.state.containerTabs, currentNavFocused: topNavOpts[getOpt.toUpperCase()] };
      this.setState({ containerTabs });
    }
  }

  render() {
    return (
      <Fragment>
        <TabContainer
          title={this.state.prettyId ? "מספר עסקה " + this.state.prettyId : ""}
          subTitle={this.state.manage.dealDescription}
          calc={this.state.calc}
          manage={this.state.manage}
          optionalTabs={this.state.containerTabs.tabs}
          currentNavFocused={this.state.containerTabs.currentNavFocused}
          onTopNavChanged={this.onTopNavChanged}
          isLoaded={this.state.containerTabs.isLoaded}
        />
      </Fragment>
    );
  }

  onTopNavChanged = (newState) => {
    const containerTabs = { ...this.state.containerTabs, currentNavFocused: newState, isLoaded: false };
    this.setState({ containerTabs }, () => {
      this.props.history.push("/just-prisa/" + newState.toLowerCase() + "/" + this.state.dealId);
      const containerTabs = { ...this.state.containerTabs, isLoaded: true };
      this.setState({ containerTabs });
    });
  };

  updateDealData = () => {
    // TODO: add validations
    dealsService.updateDealJustPrisa(this.state.dealId, this.state.calc, this.state.manage).then((res) => {
      if (res.success) {
        Swal.fire("העסקה נשמרה בהצלחה", "", "success");
      }
    });
  };

  exportDealData = () => {
    const exportedData = {
      calc: this.state.calc,
      manage: this.state.manage,
      softwareServiceType: "btaxYam"
    };
    console.log(exportedData);
    navigator.clipboard.writeText(JSON.stringify(exportedData, null, 2));
  };

  parseStateManageFromServer = (manage) => {
    return {
      dealDescription: manage.dealDescription ? manage.dealDescription : ""
    };
  };

  parseStateCalcFromServer = (calc) => {
    const personsData = [];
    if (calc.prisa && calc.prisa.personsData && Array.isArray(calc.prisa.personsData)) {
      for (const person of calc.prisa.personsData) {
        let dataPerYear = [];
        if (person.dataPerYear && Array.isArray(person.dataPerYear)) {
          for (const year of person.dataPerYear) {
            dataPerYear.push({
              income: Number.isFinite(year.income) ? year.income : null,
              zikui: Number.isFinite(year.zikui) ? year.zikui : 0
            });
          }
        } else {
          dataPerYear = [
            { income: null, zikui: 0 },
            { income: null, zikui: 0 },
            { income: null, zikui: 0 },
            { income: null, zikui: 0 }
          ];
        }
        personsData.push({
          personName: person.personName ? person.personName : "",
          isMaximal: person.isMaximal ? person.isMaximal : false,
          personOwningPercent: Number.isFinite(person.personOwningPercent) ? person.personOwningPercent : 1,
          birthOfClient: person.birthOfClient ? moment(person.birthOfClient).format("YYYY-MM-DD") : "",
          dataPerYear
        });
      }
    } else {
      personsData.push({
        personName: "",
        isMaximal: true,
        personOwningPercent: 1,
        birthOfClient: "",
        dataPerYear: [
          { income: null, zikui: 0 },
          { income: null, zikui: 0 },
          { income: null, zikui: 0 },
          { income: null, zikui: 0 }
        ]
      });
    }

    const result = {
      hasResults: calc.hasResults ? calc.hasResults : false,
      finalLinear47: Number.isFinite(calc.finalLinear47) ? calc.finalLinear47 : null,
      finalLinear20: Number.isFinite(calc.finalLinear20) ? calc.finalLinear20 : null,
      finalLinear25: Number.isFinite(calc.finalLinear25) ? calc.finalLinear25 : null,
      finalInflationLinear10: Number.isFinite(calc.finalInflationLinear10) ? calc.finalInflationLinear10 : null,
      finalMutav25: Number.isFinite(calc.finalMutav25) ? calc.finalMutav25 : null,
      soldPropertyDate: calc.soldPropertyDate ? moment(calc.soldPropertyDate).format("YYYY-MM-DD") : "",
      datePropertyBought: calc.datePropertyBought ? moment(calc.datePropertyBought).format("YYYY-MM-DD") : "",
      prisa: {
        shouldTryPrisa: true,
        personsData
      }
    };
    if (calc.prisaResults) result.prisaResults = calc.prisaResults;
    return result;
  };

  // Calc Tab functions:
  justPrisaCalcValidations() {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const earlierSoldPropertyDate = (today.substring(0, 4) - 5).toString() + "-01-01";
    if (!this.state.calc.soldPropertyDate) {
      return "תאריך המכירה הינו שדה חובה";
    } else if (this.state.calc.soldPropertyDate > today) {
      return "תאריך המכירה לא יכול להיות תאריך עתידי";
    } else if (this.state.calc.soldPropertyDate < earlierSoldPropertyDate) {
      return "לא ניתן להזין מועד מכירה מלפני " + earlierSoldPropertyDate.substring(0, 4);
    } else if (!this.state.calc.datePropertyBought) {
      return "תאריך הרכישה הינו שדה חובה";
    } else if (this.state.calc.datePropertyBought > today) {
      return "תאריך הרכישה לא יכול להיות תאריך עתידי";
    } else if (this.state.calc.datePropertyBought < "1923-01-01") {
      return "תאריך הרכישה לא יכול להיות לפני 1.1.1923";
    } else if (!Number.isFinite(this.state.calc.finalLinear47)) {
      return "שבח תקופה ראשונה (לינארי ישן) הינו שדה חובה";
    } else if (!Number.isFinite(this.state.calc.finalLinear20)) {
      return "שבח תקופה שניה (לינארי ישן) הינו שדה חובה";
    } else if (!Number.isFinite(this.state.calc.finalLinear25)) {
      return "שבח תקופה שלישית (לינארי ישן) הינו שדה חובה";
    } else if (this.state.calc.datePropertyBought > "1993-12-31" && this.state.calc.finalInflationLinear10 !== 0) {
      return "שבח אינפלציוני (לינארי ישן) צריך להיות 0 אם תאריך הרכישה לאחר 31/12/1993";
    } else if (!Number.isFinite(this.state.calc.finalInflationLinear10)) {
      return "שבח אינפלציוני (לינארי ישן) הינו שדה חובה";
    } else if (!Number.isFinite(this.state.calc.finalMutav25)) {
      return "שבח (לינארי חדש) הינו שדה חובה";
    } else if (
      parseInt(this.state.calc.soldPropertyDate.substring(0, 4)) - parseInt(this.state.calc.datePropertyBought.substring(0, 4)) <
      1
    ) {
      return "אין אפשרות לעשות פריסה כאשר שנת המכירה זהה לשנת הרכישה";
    } else if (this.state.calc.prisa.personsData.length < 1) {
      return "יש לבחור מספר לקוחות";
    }
    for (const person of this.state.calc.prisa.personsData) {
      // if (!person.personName) {
      //   return "שם לקוח הינו שדה חובה";
      // }
      if (!person.birthOfClient) {
        return "תאריך הלידה של הנישום הינו שדה חובה";
      } else if (person.birthOfClient > today) {
        return "תאריך הלידה של הנישום לא יכול להיות תאריך עתידי";
      } else if (person.birthOfClient < moment(new Date()).add(-100, "years").format("YYYY-MM-DD")) {
        return "תאריך הלידה של הנישום לא יכול להיות יותר ממאה שנה אחורה";
      }
      for (let i = 0; i < person.dataPerYear.length; i++) {
        const year = person.dataPerYear[i];
        if ((i === 0 && !person.isMaximal) || i !== 0) {
          if (!Number.isFinite(year.income)) {
            return "הכנסה הינו שדה חובה";
          }
          if (!Number.isFinite(year.zikui)) {
            return "נקודות זיכוי הינו שדה חובה";
          }
          if (year.zikui > 100) {
            return "כמות לא חוקית של נקודות זיכוי";
          }
        }
      }
    }
  }

  justPrisaCalc = () => {
    const err = this.justPrisaCalcValidations();
    if (err) {
      return Swal.fire("", err, "error");
    } else {
      const { /*prisa,*/ hasResults, ...data } = {
        ...this.state.calc
        // shouldTryPrisa: this.state.calc.prisa.shouldTryPrisa,
        // personsData: this.state.calc.prisa.personsData
      };
      dealsService.justPrisaCalc(this.state.dealId, data).then((res) => {
        console.log(res);
        if (res["success"]) {
          const calc = {
            ...this.state.calc,
            prisaResults: res.data,
            hasResults: true
          };
          this.setState({ calc });
        }
      });
    }
  };

  handleCalcOnChange = (e) => {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const calc = { ...this.state.calc, [e.target.name]: val };
    this.handleCalcDependencies(e, calc);
    this.setState({ calc });
  };

  handleCalcDependencies(e, calc) {
    if (calc.hasResults) this.clearCalcResults(calc);
    if (e.target.name === "soldPropertyDate" || e.target.name === "datePropertyBought") {
      const maxYears = this.getPrisaMaxYears(calc.soldPropertyDate, calc.datePropertyBought);
      if (maxYears < 1) {
        this.initializePersonsData({ target: { value: 0 } }, calc.prisa);
      }
      for (const person of calc.prisa.personsData) {
        const prisaYearsToSet = Math.min(maxYears, person.dataPerYear.length);
        this.initializeDataPerYear({ target: { value: prisaYearsToSet } }, person);
      }
    }
  }

  clearCalcResults(calc) {
    calc.hasResults = false;
    if (calc.prisaResults) delete calc.prisaResults;
  }

  getPrisaMaxYears(soldPropertyDate, datePropertyBought) {
    if (!soldPropertyDate || !datePropertyBought) return 4;
    let diff = parseInt(soldPropertyDate.substring(0, 4)) - parseInt(datePropertyBought.substring(0, 4));
    diff = Math.min(4, diff);
    return diff;
  }

  handlePrisaCalcOnChange = (e, prisaPersonData, nLastYear) => {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const prisa = { ...this.state.calc.prisa };
    if (e.target.name === "personsData") {
      this.initializePersonsData(e, prisa);
    } else if (e.target.name === "dataPerYear") {
      this.initializeDataPerYear(e, prisa.personsData[prisaPersonData || 0]);
    } else {
      const personsData = [...prisa.personsData];
      if (e.target.name === "income" || e.target.name === "zikui") {
        personsData[prisaPersonData || 0].dataPerYear[nLastYear || 0][e.target.name] = val;
      } else {
        personsData[prisaPersonData || 0][e.target.name] = val;
      }
      prisa.personsData = personsData;
    }
    const calc = { ...this.state.calc, prisa };
    if (calc.hasResults) this.clearCalcResults(calc);
    this.setState({ calc });
  };

  initializeDataPerYear(e, person) {
    const numOfYears = e.target.value;
    if (numOfYears === person.dataPerYear.length) return;
    const dataPerYear = [];
    for (let i = 0; i < numOfYears; i++) {
      if (person.dataPerYear.length > i) {
        dataPerYear.push({ ...person.dataPerYear[i] });
      } else {
        dataPerYear.push({ income: null, zikui: 0 });
      }
    }
    person.dataPerYear = dataPerYear;
  }

  initializePersonsData(e, prisa) {
    const numOfPersons = e.target.value;
    if (numOfPersons === prisa.personsData.length) return;
    const personsData = [];
    const maxYears = this.getPrisaMaxYears(this.state.calc.soldPropertyDate, this.state.calc.datePropertyBought);
    for (let i = 0; i < numOfPersons; i++) {
      let newPart;
      if (prisa.personsData.length > i) {
        newPart = { ...prisa.personsData[i], personOwningPercent: 1 / numOfPersons };
      } else {
        newPart = {
          personName: "",
          isMaximal: true,
          personOwningPercent: 1 / numOfPersons,
          birthOfClient: "",
          dataPerYear: []
        };
      }
      this.initializeDataPerYear({ target: { value: maxYears } }, newPart);
      personsData.push(newPart);
    }
    prisa.personsData = personsData;
  }

  // Manage Tab functions:
  handleManageOnChange = (e) => {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const manage = { ...this.state.manage, [e.target.name]: val };
    this.setState({ manage });
  };
}

export default PrisaContainer;
