export const greyAndBlue = "#dce3e6";
export const lightBlue = "#9fbacd";
export const blue = "#1e88e5";
export const darkBlue = "#152c3a";
export const darkPurple = "#342444";
export const green = "#4caf50";
export const red = "#f44336";
export const orange = "#ffb109";
export const white = "#ffffff";
export const darkGray = "#787878";
export const gray = "#bdbdbd";
export const lightGray = "#e0e0e0";
export const veryLigntGray = "#f1f1f1";
export const sessionBlue = "#336b88";
