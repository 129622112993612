import React, { Component, Fragment } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { TextField, Grid, Typography, Divider, Button, Card, CardContent, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FilterFrames, CreateNewFolder, GetApp, Save } from "@material-ui/icons";
import LabeledTextField from "../../layout/LabeledTextField";
import { showDateInTextType } from "../../../services/utils/utils";
import calanderIcon from "../../../assets/images/calander-icon.png";
import SumNumDisplayer from "../../layout/sumNumDisplayer";
import filesService from "../../../services/docsService/filesService";
import adminService from "../../../services/adminService";
import fieldsData from "../../../services/docsService/fieldsData";
import Swal from "sweetalert2";
import { connect } from "react-redux";

class DealSumCalc extends Component {
  state = {
    doc2990Fields: {},
    cities: null,
    streets: [],
    showDoc2990Fields: false
  };

  componentDidMount() {
    filesService.getDocsFields(this.props.match.params.dealId, true).then((res) => {
      if (res.success) {
        const doc2990Fields = {
          ...res.data,
          sellerId: res.data.sellerId ? parseInt(res.data.sellerId) : null,
          blockNum: res.data.blockNum ? parseInt(res.data.blockNum) : null,
          subBlock: res.data.subBlock ? parseInt(res.data.subBlock) : null,
          subSubBlock: res.data.subSubBlock ? parseInt(res.data.subSubBlock) : null,
          assetStreetNumber: res.data.assetStreetNumber ? parseInt(res.data.assetStreetNumber) : null,
          assetZipcode: res.data.assetZipcode ? parseInt(res.data.assetZipcode) : null,
          assetHouseNum: res.data.assetHouseNum ? parseInt(res.data.assetHouseNum) : null,
          passPartMekarkeinMechane: res.data.passPartMekarkeinMechane ? parseInt(res.data.passPartMekarkeinMechane) : null,
          passPartMekarkeinMone: res.data.passPartMekarkeinMone ? parseInt(res.data.passPartMekarkeinMone) : null
        };
        this.setState({ doc2990Fields, showDoc2990Fields: true });
      }
    });
  }

  componentDidUpdate() {
    if (!this.state.cities) {
      if (this.props.addresses) {
        let cities = [];
        for (const curAddress of this.props.addresses) {
          if (!cities.includes(curAddress.cityName)) cities.push(curAddress.cityName);
        }
        this.setState({ cities });
      }
    }
  }

  render() {
    const mainCalc = this.props.mainCalc;
    const getTopValues = this.getTopValues();
    const actualPriceBought = this.getActualPriceBought();
    // const iahas = getTopValues.shoviRechishaLehishuvMas / actualPriceBought;
    // getTopValues.shoviMechiraLehishuvMas = this.props.preCalc.valueActualSoldOfProperty * iahas;

    const shevahReali11afterKizuz = Math.max(getTopValues.shevahReali11 - (mainCalc.kizuzHoni || 0) - (mainCalc.kizuzIski || 0), 0);

    const discountRabPercent = this.getMaximalRabinovich();
    const prisaData = mainCalc.prisa.shouldTryPrisa ? this.getPrisaData() : null;

    const sumNumDisplayerStyle = { marginLeft: "-20px" };
    const finalSumNumDisplayerStyle = { marginTop: "50px", marginBottom: "30px" };
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item lg={3} xs={4}>
              <img src={calanderIcon} alt="calander" />
            </Grid>
            <Grid item lg={3} xs={4}>
              <LabeledTextField
                type="text"
                disabled
                value={showDateInTextType(this.props.preCalc.soldPropertyDate)}
                textFieldWidth={150}
                label="יום המכירה"
                labeledTextFieldDirection="column"
                labelWidth={150}
              />
            </Grid>
            <Grid item lg={3} xs={4}>
              <LabeledTextField
                type="text"
                disabled
                value={showDateInTextType(this.getBoughtDate())}
                textFieldWidth={150}
                label="יום הרכישה"
                labeledTextFieldDirection="column"
                labelWidth={150}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={5} justify="flex-start" alignItems="center">
            <Grid item lg={3} xs={4}>
              <LabeledTextField
                type="number"
                disabled
                value={this.props.preCalc.valueActualSoldOfProperty}
                textFieldWidth={150}
                label="שווי המכירה"
                labeledTextFieldDirection="column"
                labelWidth={150}
              />
            </Grid>
            {/* <Grid item lg={3} xs={4}>
              <LabeledTextField
                type="number"
                value={getTopValues.shoviMechiraLehishuvMas}
                disabled
                textFieldWidth={150}
                label="שווי מכירה לחישוב מס"
                labeledTextFieldDirection="column"
                labelWidth={150}
              />
            </Grid> */}
            <Grid item lg={3} xs={4}>
              <LabeledTextField
                type="number"
                value={getTopValues.shoviMechiraHaiavMas}
                disabled
                textFieldWidth={150}
                label="שווי מכירה חייב במס"
                labeledTextFieldDirection="column"
                labelWidth={200}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={5} justify="flex-start" alignItems="center">
            <Grid item lg={3} xs={4}>
              <LabeledTextField
                type="number"
                disabled
                value={actualPriceBought}
                textFieldWidth={150}
                label="שווי הרכישה"
                labeledTextFieldDirection="column"
                labelWidth={150}
              />
            </Grid>
            <Grid item lg={3} xs={4}>
              <LabeledTextField
                type="number"
                disabled
                textFieldWidth={150}
                value={getTopValues.shoviRechishaLehishuvMas}
                label="שווי רכישה לחישוב מס"
                labeledTextFieldDirection="column"
                labelWidth={150}
              />
            </Grid>

            <Grid item lg={3} xs={4}>
              <LabeledTextField
                type="number"
                value={getTopValues.shoviRechisaMasMetoam}
                disabled
                textFieldWidth={150}
                label="שווי רכישה לחישוב מס מתואם"
                labeledTextFieldDirection="column"
                labelWidth={200}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={sumNumDisplayerStyle}>
          <SumNumDisplayer title={'סה"כ שבח בעסקה'} amount={getTopValues.shevahReali11} />
        </Grid>
        {mainCalc.kizuzIski || mainCalc.kizuzHoni ? (
          <Fragment>
            <Grid item>
              <Grid container direction="row" spacing={5} justify="flex-start" alignItems="center">
                <Grid item lg={3} xs={4}>
                  <LabeledTextField
                    value={mainCalc.kizuzIski || 0}
                    type="number"
                    disabled
                    textFieldWidth={150}
                    label="קיזוז הפסדים עסקי, מועבר ושוטף"
                    labeledTextFieldDirection="column"
                    labelWidth={215}
                  />
                </Grid>
                <Grid item lg={3} xs={4}>
                  <LabeledTextField
                    value={mainCalc.kizuzHoni || 0}
                    type="number"
                    disabled
                    textFieldWidth={150}
                    label="קיזוז הפסדים הוני"
                    labeledTextFieldDirection="column"
                    labelWidth={150}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={sumNumDisplayerStyle}>
              <SumNumDisplayer title={'סה"כ שבח לאחר קיזוז הפסדים'} amount={shevahReali11afterKizuz} />
            </Grid>
          </Fragment>
        ) : null}
        <Grid item style={sumNumDisplayerStyle}>
          <SumNumDisplayer title={'סה"כ מס שבח'} amount={this.getFinalMasShevahPrePrisa()} />
        </Grid>
        {discountRabPercent ? (
          <Grid item style={sumNumDisplayerStyle}>
            <SumNumDisplayer title={"הנחת רבינוביץ"} amount={`${discountRabPercent * 100}%`} />
          </Grid>
        ) : null}

        {mainCalc.prisa.shouldTryPrisa ? (
          <>
            <Grid item>
              <Grid container direction="row" spacing={5} justify="flex-start" alignItems="center">
                <Grid item lg={3} xs={4}>
                  <LabeledTextField
                    value={prisaData.maxPrisaYearsAllowed}
                    type="number"
                    disabled
                    textFieldWidth={150}
                    label="תקופת פריסה מומלצת"
                    labeledTextFieldDirection="column"
                    labelWidth={150}
                  />
                </Grid>
                <Grid item lg={3} xs={4}>
                  <LabeledTextField
                    type="number"
                    value={prisaData.totalPrisaRev}
                    disabled
                    textFieldWidth={150}
                    label="סך חיסכון בגין פריסה"
                    labeledTextFieldDirection="column"
                    labelWidth={190}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={sumNumDisplayerStyle}>
              <SumNumDisplayer title='סה"כ מס שבח לאחר פריסה' amount={prisaData.totalPreAfkaa} />
            </Grid>
          </>
        ) : null}

        {mainCalc.afkaa ? (
          <Grid item style={sumNumDisplayerStyle}>
            <SumNumDisplayer title="זיכוי הפקעה" amount={this.getAfterAfkaa()} />
          </Grid>
        ) : null}

        <Grid item style={finalSumNumDisplayerStyle}>
          <SumNumDisplayer title={`סה"כ מס לתשלום`} amount={this.getFinalTax()} isFinalMsg />
        </Grid>

        {this.state.showDoc2990Fields ? (
          <Grid item>
            <div className="mt-50 fs-30">
              <b>מילוי פרטיים אישיים לטופס 2990</b>
            </div>

            <div className="mt-30">
              <Card>
                <CardContent>
                  <div className="flex flex-basic100">
                    <div>
                      <LabeledTextField
                        labeledTextFieldDirection="column"
                        type="select"
                        label="מקום משרד מיסוי המקרקעין"
                        options={fieldsData.lishcatRishumLocation.options}
                        onChange={this.handleOnChange}
                        value={this.state.doc2990Fields.lishcatRishumLocation}
                        name="lishcatRishumLocation"
                      />
                    </div>
                    <div>
                      <LabeledTextField
                        labeledTextFieldDirection="column"
                        type="text"
                        value={this.state.doc2990Fields.sellerFirstName}
                        name="sellerFirstName"
                        label="שם פרטי"
                        maxLength="17"
                        onChange={this.handleOnChange}
                      />
                    </div>
                    <div>
                      <LabeledTextField
                        labeledTextFieldDirection="column"
                        type="text"
                        value={this.state.doc2990Fields.sellerLastName}
                        name="sellerLastName"
                        label="שם משפחה"
                        maxLength="17"
                        onChange={this.handleOnChange}
                      />
                    </div>
                    <Tooltip
                      title="במידה ומדובר בתושב חוץ ללא תעודת זהות, יש לפנות לרשות על מנת לקבל מספר רישוי מיוחד שלהם שישמש לעסקה"
                      placement="bottom-start"
                    >
                      <div>
                        <LabeledTextField
                          labeledTextFieldDirection="column"
                          type="number"
                          value={this.state.doc2990Fields.sellerId}
                          name="sellerId"
                          maxLength="9"
                          noComma
                          sequencesOfZerosAllowed
                          label="מספר זהות"
                          onChange={this.handleOnChange}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="mt-30 flex flex-basic100">
                    <div>
                      <LabeledTextField
                        labeledTextFieldDirection="column"
                        type="number"
                        noComma
                        maxLength="6"
                        value={this.state.doc2990Fields.blockNum}
                        name="blockNum"
                        label="גוש"
                        onChange={this.handleOnChange}
                      />
                    </div>
                    <div>
                      <LabeledTextField
                        labeledTextFieldDirection="column"
                        type="number"
                        noComma
                        maxLength="4"
                        value={this.state.doc2990Fields.subBlock}
                        name="subBlock"
                        label="חלקה"
                        onChange={this.handleOnChange}
                      />
                    </div>
                    <div>
                      <LabeledTextField
                        labeledTextFieldDirection="column"
                        type="number"
                        noComma
                        maxLength="3"
                        value={this.state.doc2990Fields.subSubBlock}
                        name="subSubBlock"
                        label="תת חלקה"
                        onChange={this.handleOnChange}
                      />
                    </div>
                    <div>
                      <LabeledTextField
                        labeledTextFieldDirection="column"
                        value={this.state.doc2990Fields.assetZipcode}
                        name="assetZipcode"
                        type="number"
                        noComma
                        label="מיקוד הנכס"
                        maxLength="7"
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </div>

                  <div className="mt-30 flex flex-basic100">
                    <div>
                      <Typography variant="subtitle1" align="right">
                        עיר הנכס
                      </Typography>

                      <div className="right">
                        <div style={{ width: "190px", display: "inline-block", marginTop: "8px" }}>
                          <Autocomplete
                            fullWidth
                            defaultValue={this.state.doc2990Fields.assetCity}
                            blurOnSelect
                            options={this.state.cities ? this.state.cities : []}
                            openOnFocus
                            freeSolo
                            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                            onInputChange={(e, val) => this.handleOnChange({ target: { name: "assetCity", value: val } })}
                            onChange={(e, val) => this.onCityChange(val)}
                            className="center-div"
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <Typography variant="subtitle1" align="right">
                        רחוב הנכס
                      </Typography>

                      <div className="right">
                        <div style={{ width: "190px", display: "inline-block", marginTop: "8px" }}>
                          <Autocomplete
                            fullWidth
                            defaultValue={this.state.doc2990Fields.assetStreet}
                            blurOnSelect
                            options={this.state.streets}
                            openOnFocus
                            freeSolo
                            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                            onInputChange={(e, val) => this.handleOnChange({ target: { name: "assetStreet", value: val } })}
                            onChange={(e, val) => this.handleOnChange({ target: { name: "assetStreet", value: val } })}
                            className="center-div"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <LabeledTextField
                        labeledTextFieldDirection="column"
                        value={this.state.doc2990Fields.assetStreetNumber}
                        name="assetStreetNumber"
                        type="number"
                        maxLength="5"
                        noComma
                        label="מספר בית"
                        onChange={this.handleOnChange}
                      />
                    </div>
                    <div>
                      <LabeledTextField
                        labeledTextFieldDirection="column"
                        value={this.state.doc2990Fields.assetHouseNum}
                        name="assetHouseNum"
                        type="number"
                        maxLength="4"
                        noComma
                        label="מספר דירה"
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </div>

                  <div className="seller-part-container mt-30">
                    <div className="seller-part-fields">
                      <LabeledTextField
                        labeledTextFieldDirection="column"
                        type="number"
                        noComma
                        maxLength="12"
                        value={this.state.doc2990Fields.passPartMekarkeinMechane}
                        name="passPartMekarkeinMechane"
                        label="מכנה החלק הנמכר"
                        onChange={this.handleOnChange}
                      />

                      <div className="seller-part-division-slash">/</div>
                    </div>

                    <div className="seller-part-fields">
                      <LabeledTextField
                        labeledTextFieldDirection="column"
                        type="number"
                        noComma
                        maxLength="12"
                        value={this.state.doc2990Fields.passPartMekarkeinMone}
                        name="passPartMekarkeinMone"
                        label="מונה החלק הנמכר"
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </div>

                  <div className="mt-20">
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ minWidth: "150px" }}
                      endIcon={<Save style={{ marginRight: "8px" }} />}
                      onClick={this.savePersonalDetailsOf2990}
                    >
                      שמור פרטים אישיים
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </Grid>
        ) : null}

        <Grid item>
          <h2 className="right">הפקת קבצים מקוונים לרשות המיסים</h2>
          <div>
            {this.props.preCalc &&
              this.props.preCalc.preCalcPartsData &&
              this.props.preCalc.preCalcPartsData.map((currentPart, index) => (
                <div key={"part" + index}>
                  {this.props.preCalc.preCalcPartsData.length > 1 && <div className="right mb-5 mt-30">עסקה מספר - {index + 1}</div>}

                  <div className="flex-space-between" style={{ width: 280 }}>
                    {currentPart.needsMutav && (
                      <Button
                        onClick={() => this.generate2990Json("mutav", index)}
                        endIcon={<GetApp style={{ marginRight: "8px" }} />}
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        קובץ מס מוטב
                      </Button>
                    )}
                    {currentPart.needsRegTax && true && (
                      <Button
                        onClick={() => this.generate2990Json("linear", index)}
                        endIcon={<GetApp style={{ marginRight: "8px" }} />}
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        קובץ לינארי ישן
                      </Button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </Grid>

        <Grid item>
          <hr />
          <h2 className="right">פעולות נוספות</h2>

          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ minWidth: "150px" }}
                endIcon={<CreateNewFolder style={{ marginRight: "8px" }} />}
                onClick={this.generate2990}
              >
                הפק טופס 2990
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ minWidth: "150px" }}
                endIcon={<CreateNewFolder style={{ marginRight: "8px" }} />}
                onClick={() =>
                  filesService
                    .generateDeductions(this.props.match.params.dealId)
                    .catch(() => Swal.fire("היתה בעיה ביצירת המסמך", "במידה ובעיה זו ממשיכה בבקשה צרו איתנו קשר", "warning"))
                }
              >
                הפק טופס ניכויים
              </Button>
            </Grid>
            {this.props.role === "admin" ? (
              <Fragment>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ minWidth: "150px" }}
                    endIcon={<FilterFrames style={{ marginRight: "8px" }} />}
                    onClick={this.addDealTest}
                  >
                    שמירה כטסט
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ minWidth: "150px" }}
                    endIcon={<GetApp style={{ marginRight: "8px" }} />}
                    onClick={() => this.props.exportDealData()}
                  >
                    ייצא
                  </Button>
                </Grid>
              </Fragment>
            ) : null}
          </Grid>
        </Grid>

        <Grid item>
          <p className="right fs-20">
            <hr className="mb-20" />
            נכון לשנת 2024, יחיד אשר הכנסתו החייבת (כולל שבח מקרקעין) תעלה על 721,560 ש"ח, יהיה חייב במס נוסף (מס יסף) על כל שקל נוסף בשיעור
            של 3%. מס יסף יחול בגין שבח ממכירת זכות במקרקעין המהווה דירת מגורים במידה ושווי מכירתה מעל 5,382,285 ש"ח והמכירה אינה פטורה ממס
            לפי כל דין.
            <br />
            "הכנסה חייבת" – כהגדרתה בסעיף 121ב לפקודת מס הכנסה. ראה הוראת ביצוע 9/15 רשות המיסים.
          </p>
        </Grid>
      </Grid>
    );
  }

  onCityChange(newCity) {
    this.handleOnChange({ target: { name: "assetCity", value: newCity } });

    const getStreets = this.props.addresses.filter((x) => x.cityName === newCity).map((x) => x.streetName);
    this.setState({ streets: getStreets });
  }

  savePersonalDetailsOf2990 = () => {
    filesService.update2990SpecificFields(this.props.match.params.dealId, this.state.doc2990Fields).then((res) => {
      if (res.success) {
        Swal.fire("נתונים נשמרו בהצלחה", "", "success");
      }
    });
  };

  handleOnChange = (e) => {
    const doc2990Fields = { ...this.state.doc2990Fields, [e.target.name]: e.target.value };
    this.setState({ doc2990Fields });
  };

  generate2990 = () => {
    const dataForGenerate2990Doc = { ...this.state.doc2990Fields };
    dataForGenerate2990Doc.dealId = this.props.match.params.dealId;

    filesService.generate2990(dataForGenerate2990Doc).catch(() => {
      Swal.fire("היתה בעיה ביצירת המסמך", "במידה ובעיה זו ממשיכה בבקשה צרו איתנו קשר", "warning");
    });
  };

  generate2990Json = (dealTaxType, dealIndex) => {
    const dataForGenerate2990Doc = { ...this.state.doc2990Fields };
    dataForGenerate2990Doc.dealId = this.props.match.params.dealId;

    const passPartMekarkeinMechaneParsed = parseFloat(dataForGenerate2990Doc.passPartMekarkeinMechane);
    const passPartMekarkeinMoneParsed = parseFloat(dataForGenerate2990Doc.passPartMekarkeinMechane);

    if (!Number.isInteger(passPartMekarkeinMechaneParsed) || !Number.isInteger(passPartMekarkeinMoneParsed)) {
      return Swal.fire("מונה ומכנה חייבים להיות מספרים שלמים", "", "warning");
    }

    if (passPartMekarkeinMechaneParsed <= 0 || passPartMekarkeinMoneParsed <= 0) {
      return Swal.fire("מונה ומכנה חייבים להיות גדולים מאפס", "", "warning");
    }

    if (
      (!dataForGenerate2990Doc.sellerFirstName && dataForGenerate2990Doc.sellerFirstName !== "") ||
      (!dataForGenerate2990Doc.sellerLastName && dataForGenerate2990Doc.sellerLastName !== "") ||
      !dataForGenerate2990Doc.lishcatRishumLocation ||
      !dataForGenerate2990Doc.sellerId ||
      (!dataForGenerate2990Doc.blockNum && dataForGenerate2990Doc.blockNum !== 0) ||
      (!dataForGenerate2990Doc.subBlock && dataForGenerate2990Doc.subBlock !== 0) ||
      (!dataForGenerate2990Doc.assetStreet && dataForGenerate2990Doc.assetStreet !== "") ||
      (!dataForGenerate2990Doc.assetStreetNumber && dataForGenerate2990Doc.assetStreetNumber !== "")
    ) {
      return Swal.fire("חלק מנתוני הטופס המקוון לא הוזנו. לא ניתן להגיש טופס כך לרשות", "", "warning");
    }

    if (dataForGenerate2990Doc.sellerId.length < 9) {
      return Swal.fire("ת.ז לא חוקית", "", "warning");
    }

    if (parseInt(dataForGenerate2990Doc.subBlock) > 9999 || parseInt(dataForGenerate2990Doc.subBlock) < 0) {
      return Swal.fire("חלקה חייב להיות בין 0 ל-9999", "", "warning");
    }

    filesService.generate2990Json(dataForGenerate2990Doc, dealTaxType, dealIndex, this.props.preCalc.preCalcPartsData.length).catch(() => {
      Swal.fire("היתה בעיה ביצירת המסמך", "במידה ובעיה זו ממשיכה בבקשה צרו איתנו קשר", "warning");
    });
    filesService.update2990SpecificFields(this.props.match.params.dealId, this.state.doc2990Fields);
  };

  addDealTest = () => {
    Swal.fire({
      title: "הזן תיאור בדיקה",
      input: "text",
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonText: "המשך",
      preConfirm: (testDescription) => {
        if (!testDescription) return Swal.showValidationMessage("יש להזין תיאור בדיקה");
        return adminService
          .addDealTest(testDescription, { preCalc: this.props.preCalc, mainCalc: this.props.mainCalc }, "btax")
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      if (result.isConfirmed && result.value.success) {
        Swal.fire({
          icon: "success",
          title: `הבדיקה נשמרה בהצלחה`,
          confirmButtonText: "המשך"
        });
      }
    });
  };

  getBoughtDate() {
    let minDate = moment(new Date()).format("YYYY-MM-DD");
    for (const deduction of this.props.mainCalc.deductions) {
      if (deduction.deduType === "purchase" || deduction.deduType === "gift") {
        if (minDate > deduction.deduDate) {
          minDate = deduction.deduDate;
        }
      } else if (deduction.deduType === "inheritance") {
        const tempDate = this.props.getPurchaseDate(deduction);
        if (minDate > tempDate) {
          minDate = tempDate;
        }
      }
    }
    return minDate;
  }

  getActualPriceBought() {
    let totalBought = 0;
    for (const part of this.props.mainCalc.calculatedData.deductionMat) {
      // totalBought += part[0].amount;
      totalBought += part[0].amountAfterCurrencyConversion || 0;
    }
    return totalBought;
  }

  getTopValues() {
    const topValues = { shevahReali11: 0, shoviRechishaLehishuvMas: 0, shoviMechiraHaiavMas: 0, shoviRechisaMasMetoam: 0 };
    for (const part of this.props.mainCalc.calculatedData.dataPerPart) {
      topValues.shevahReali11 += (part.shevahRealiMutav11 || 0) + (part.shevahRealiRegTax11 || 0);
      topValues.shoviRechishaLehishuvMas += part.sumAmountMutav + part.sumAmountRegTax;
      topValues.shoviMechiraHaiavMas += part.baseDeltaMasMutav + part.zabanMasMutavToPay + part.baseDeltaRegTax + part.zabanMasRegToPay;
      topValues.shoviRechisaMasMetoam += part.sumMemudadMutav + part.sumMemudadRegTax;
    }

    return topValues;
  }

  getFinalMasShevahPrePrisa() {
    if (this.props.mainCalc.prisa.shouldTryPrisa) {
      return this.props.mainCalc.calculatedData.dataPerPart.reduce(
        (a, b) =>
          a +
          (b.finalLinearBeforePrisa20 || 0) +
          (b.finalLinearBeforePrisa25 || 0) +
          (b.finalLinearBeforePrisa47 || 0) +
          (b.finalMutavBeforePrisa25 || 0) +
          (b.finalInflationLinear10 || 0),
        0
      );
    } else {
      return this.props.mainCalc.calculatedData.dataPerPart.reduce(
        (a, b) => a + (b.finalLinearTaxAllPreAfkaa21 || 0) + (b.finalMutavTaxAllPreAfkaa21 || 0),
        0
      );
    }
  }

  getMaximalRabinovich() {
    return this.props.mainCalc.calculatedData.dataPerPart.reduce((a, b) => Math.max(a, b.discountRabPercent || 0), 0);
  }

  getPrisaData() {
    let totalPrisaRev = 0,
      maxPrisaYearsAllowed = 0,
      totalPreAfkaa = 0;

    for (const part of this.props.mainCalc.calculatedData.dataPerPart) {
      maxPrisaYearsAllowed = Math.max(part.prisaData[0].length, maxPrisaYearsAllowed);

      totalPrisaRev += part.finalLinearBeforePrisa47 - part.finalLinear47;
      totalPrisaRev += part.finalMutavBeforePrisa25 - part.finalMutav25;
      totalPrisaRev += part.finalLinearBeforePrisa25 - part.finalLinear25;
      totalPrisaRev += part.finalLinearBeforePrisa20 - part.finalLinear20;

      totalPreAfkaa += (part.finalLinearTaxAllPreAfkaa21 || 0) + (part.finalMutavTaxAllPreAfkaa21 || 0);
    }
    return { totalPrisaRev, maxPrisaYearsAllowed, totalPreAfkaa };
  }

  getAfterAfkaa() {
    return this.props.mainCalc.calculatedData.dataPerPart.reduce(
      (a, b) => a + (b.finalLinearTaxAll24 || 0) + (b.finalMutavTaxAll24 || 0),
      0
    );
  }

  getFinalTax() {
    return this.props.mainCalc.calculatedData.dataPerPart.reduce(
      (a, b) => a + (b.actualFinalLinear26 || 0) + (b.actualFinalMutav26 || 0),
      0
    );
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.loggedinReducer.role
  };
};

export default withRouter(connect(mapStateToProps)(DealSumCalc));
