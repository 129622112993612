import React, { Component } from "react";
import { Divider, Grid, FormControl, FormControlLabel, Typography, RadioGroup, Radio, Button, Box } from "@material-ui/core";
import CustomDialog from "../../../layout/CustomDialog";
import * as customColors from "../../../../theme/customColors";
import Popup from "../../../../assets/images/popup.png";

// Original optional props:
// open
// onClose

// Extended optional props:
// handleResults - force the result.

class HasRabinovichDialog extends Component {
  state = {
    isLandProperty: false,
    hasConstructionPlan: true,
    isPropertyGottenBySeif70: false,
    isPropertyGottenByFree: false,
    isPropertyGottenByRightsExchange: false,
    isPropertyGottenByRelated: false,
    isBusinessInventory: false,
    isChecked: false,
    isZakai: false
  };

  render() {
    return (
      <CustomDialog label="בדיקת זכאות להנחה במס לפי סעיף 48א(ד1) לחוק" open={this.props.open} onClose={this.props.onClose}>
        <Grid container direction="column" spacing={1}>
          <Grid container item direction="row" wrap="nowrap">
            <Grid container item direction="column" xs={8} spacing={1}>
              <Grid item>
                <FormControl component="fieldset">
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body2">האם הממכר במועד רכישתו היה קרקע?</Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isLandProperty).toString()}
                        row
                        name="isLandProperty"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset" disabled={!this.state.isLandProperty}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body2" color={!this.state.isLandProperty ? "textSecondary" : "textPrimary"}>
                        האם קיימת תוכנית המתירה בנייה על הקרקע ותוך 5 שנים מיום הרכישה נבנה על הקרקע בניין, ששטחו לפחות 70% מהשטח המותר
                        לבנייה?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.hasConstructionPlan).toString()}
                        row
                        name="hasConstructionPlan"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset" disabled={!this.state.hasConstructionPlan}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body2" color={!this.state.hasConstructionPlan ? "textSecondary" : "textPrimary"}>
                        האם הממכר התקבל על ידי המוכר לפי סעיף 70 לחוק מיסוי מקרקעין, או לפי חלק ה'2 לפקודת מס הכנסה?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isPropertyGottenBySeif70).toString()}
                        row
                        name="isPropertyGottenBySeif70"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset" disabled={!this.state.hasConstructionPlan || this.state.isPropertyGottenBySeif70}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        variant="body2"
                        color={!this.state.hasConstructionPlan || this.state.isPropertyGottenBySeif70 ? "textSecondary" : "textPrimary"}
                      >
                        האם הממכר התקבל על ידי המוכר בלא תמורה?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isPropertyGottenByFree).toString()}
                        row
                        name="isPropertyGottenByFree"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={!this.state.hasConstructionPlan || this.state.isPropertyGottenBySeif70 || this.state.isPropertyGottenByFree}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        variant="body2"
                        color={
                          !this.state.hasConstructionPlan || this.state.isPropertyGottenBySeif70 || this.state.isPropertyGottenByFree
                            ? "textSecondary"
                            : "textPrimary"
                        }
                      >
                        האם הממכר התקבל על ידי המוכר במסגרת חילוף זכויות במקרקעין, לפי פרק חמישי 3 לחוק מיסוי מקרקעין?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isPropertyGottenByRightsExchange).toString()}
                        row
                        name="isPropertyGottenByRightsExchange"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={
                    !this.state.hasConstructionPlan ||
                    this.state.isPropertyGottenBySeif70 ||
                    this.state.isPropertyGottenByFree ||
                    this.state.isPropertyGottenByRightsExchange
                  }
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        variant="body2"
                        color={
                          !this.state.hasConstructionPlan ||
                          this.state.isPropertyGottenBySeif70 ||
                          this.state.isPropertyGottenByFree ||
                          this.state.isPropertyGottenByRightsExchange
                            ? "textSecondary"
                            : "textPrimary"
                        }
                      >
                        האם הממכר התקבל על ידי המוכר מקרוב? לענין זה, "קרוב" - כהגדרתו בסעיף 19(4) לחוק מיסוי מקרקעין והמונח "שליטה" בהגדרה
                        האמורה יפורש לפי אותו סעיף.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isPropertyGottenByRelated).toString()}
                        row
                        name="isPropertyGottenByRelated"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={
                    !this.state.hasConstructionPlan ||
                    this.state.isPropertyGottenBySeif70 ||
                    this.state.isPropertyGottenByFree ||
                    this.state.isPropertyGottenByRightsExchange ||
                    this.state.isPropertyGottenByRelated
                  }
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        variant="body2"
                        color={
                          !this.state.hasConstructionPlan ||
                          this.state.isPropertyGottenBySeif70 ||
                          this.state.isPropertyGottenByFree ||
                          this.state.isPropertyGottenByRightsExchange ||
                          this.state.isPropertyGottenByRelated
                            ? "textSecondary"
                            : "textPrimary"
                        }
                      >
                        האם הממכר היווה מלאי עסקי בעסק והפך לנכס קבוע באותו העסק, כאמור בסעיף 85 לפקודת מס הכנסה?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isBusinessInventory).toString()}
                        row
                        name="isBusinessInventory"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={this.isZakai}>
                  בדוק זכאות
                </Button>
              </Grid>
            </Grid>
            <Grid container item direction="column" xs={4} spacing={2} justify="flex-start" alignItems="center">
              <Grid item>
                <img alt="popup" src={Popup} width="200px" height="150px" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box width="100%" bgcolor={customColors.darkPurple} p={0.5}>
              <Typography variant="h6" style={{ color: customColors.white }}>
                {`החלטת המערכת: ${this.state.isChecked ? (this.state.isZakai ? "זכאי" : "לא זכאי") : ""}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomDialog>
    );
  }

  isZakai = () => {
    let isZakai = true;
    if (!this.state.hasConstructionPlan) isZakai = false;
    else if (this.state.isPropertyGottenBySeif70) isZakai = false;
    else if (this.state.isPropertyGottenByFree) isZakai = false;
    else if (this.state.isPropertyGottenByRightsExchange) isZakai = false;
    else if (this.state.isPropertyGottenByRelated) isZakai = false;
    else if (this.state.isBusinessInventory) isZakai = false;
    this.setState({ isZakai, isChecked: true });
    // Return results to the mainCalc
    if (this.props.handleResults) this.props.handleResults({ target: { value: isZakai, name: "hasRabinovich" } });
  };

  handleOnChange(e) {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const newState = { ...this.state, [e.target.name]: val };
    this.handleDependencies(e, newState);
    this.setState(newState);
  }

  handleDependencies(e, newState) {
    if (e.target.name === "isLandProperty") {
      if (e.target.value === "false") {
        newState.hasConstructionPlan = true;
      }
    } else if (e.target.name === "hasConstructionPlan") {
      if (e.target.value === "false") {
        newState.isPropertyGottenBySeif70 = false;
        newState.isPropertyGottenByFree = false;
        newState.isPropertyGottenByRightsExchange = false;
        newState.isPropertyGottenByRelated = false;
        newState.isBusinessInventory = false;
      }
    } else if (e.target.name === "isPropertyGottenBySeif70") {
      if (e.target.value === "true") {
        newState.isPropertyGottenByFree = false;
        newState.isPropertyGottenByRightsExchange = false;
        newState.isPropertyGottenByRelated = false;
        newState.isBusinessInventory = false;
      }
    } else if (e.target.name === "isPropertyGottenByFree") {
      if (e.target.value === "true") {
        newState.isPropertyGottenByRightsExchange = false;
        newState.isPropertyGottenByRelated = false;
        newState.isBusinessInventory = false;
      }
    } else if (e.target.name === "isPropertyGottenByRightsExchange") {
      if (e.target.value === "true") {
        newState.isPropertyGottenByRelated = false;
        newState.isBusinessInventory = false;
      }
    } else if (e.target.name === "isPropertyGottenByRelated") {
      if (e.target.value === "true") {
        newState.isBusinessInventory = false;
      }
    }
  }
}

export default HasRabinovichDialog;
