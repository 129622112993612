import React, { Component, Fragment } from "react";
import { Snackbar, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import DealPreCalc from "./dealPreCalc";
import DealMainCalc from "./dealMainCalc";
import DealSumCalc from "./dealSumCalc";
import StepNavBar from "../../layout/StepNavBar";
import "../../../assets/css/deals.css";

class DealCalc extends Component {
  render() {
    const navSteps = [
      { label: "קדם מחשבון", isAllowed: true, isFinished: this.props.preCalc.hasResults },
      { label: "מחשבון", isAllowed: this.props.preCalc.hasResults && this.props.hasTaxToPay(), isFinished: this.props.mainCalc.hasResults },
      { label: "פלט ומסמכי שומה עצמית", isAllowed: this.props.mainCalc.hasResults, isFinished: false }
    ];
    let dealStepContent;
    if (this.props.currentStep === 1) {
      dealStepContent = (
        <DealPreCalc
          {...this.props.preCalc}
          currentStep={this.props.currentStep}
          handlePreCalcOnChange={this.props.handlePreCalcOnChange}
          handlePreCalcPartOnChange={this.props.handlePreCalcPartOnChange}
          handleCombinationOnChange={this.props.handleCombinationOnChange}
          preCalcStart={this.props.preCalcStart}
          handleStepChange={this.props.handleStepChange}
          updateDealData={this.props.updateDealData}
          exportDealData={this.props.exportDealData}
        />
      );
    } else if (this.props.currentStep === 2) {
      dealStepContent = (
        <DealMainCalc
          preCalc={this.props.preCalc}
          {...this.props.mainCalc}
          currentStep={this.props.currentStep}
          getPrisaMaxYears={this.props.getPrisaMaxYears}
          getPrisaMinYears={this.props.getPrisaMinYears}
          getPurchaseDate={this.props.getPurchaseDate}
          handleMainCalcPrisaOnChange={this.props.handleMainCalcPrisaOnChange}
          handleMainCalcOnChange={this.props.handleMainCalcOnChange}
          addMainCalcDeduction={this.props.addMainCalcDeduction}
          addMainCalcDeductionsFromExcel={this.props.addMainCalcDeductionsFromExcel}
          removeMainCalcDeduction={this.props.removeMainCalcDeduction}
          handleMainCalcDeductionOnChange={this.props.handleMainCalcDeductionOnChange}
          mainCalcStart={this.props.mainCalcStart}
          handleStepChange={this.props.handleStepChange}
          updateDealData={this.props.updateDealData}
          exportDealData={this.props.exportDealData}
        />
      );
    } else {
      dealStepContent = (
        <DealSumCalc
          preCalc={this.props.preCalc}
          mainCalc={this.props.mainCalc}
          getPurchaseDate={this.props.getPurchaseDate}
          exportDealData={this.props.exportDealData}
          addresses={this.props.addresses}
        />
      );
    }
    return (
      <Fragment>
        <StepNavBar currentStep={this.props.currentStep} steps={navSteps} handleStepChange={this.props.handleStepChange} />
        {dealStepContent}
        {this.props.currentStep === 1 && this.props.preCalc.hasResults && this.props.isPreCalcMsgAllowed ? (
          <Snackbar
            open={this.props.isSnackbarOpen}
            autoHideDuration={8000}
            onClose={() => this.props.handleSnapbarClose()}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            onClick={() => this.props.handleSnapbarClose()}
          >
            <Alert severity="info">
              <AlertTitle>לתשומת ליבך:</AlertTitle>
              <Typography variant="subtitle2" align="right">
                שינוי בנתון כלשהו בלשונית "קדם מחשבון" משפיע על השדות הבאים בלשונית "מחשבון": תאריך רכישה,
                <br />
                סכום הרכישה במטבע המקורי, ופריסה ולפיכך נתונים אלו יימחקו. עליך יהיה להזין את הנתונים בשדות אלו בשנית
              </Typography>
            </Alert>
          </Snackbar>
        ) : null}
      </Fragment>
    );
  }
}

export default DealCalc;
