import React from "react";
import config from "../../config";

const helpTexts = {
  totalPropertyCostWithoutZaban: (
    <div>
      <strong>זב"ן - זכויות בניה.</strong>
      <p>
        למילוי שווי הדירה ללא זכויות בניה, מומלץ להסתייע בשמאי מקרקעין. במכירה של חלק מהזכויות בנכס, יש להזין לרובריקה זו את השווי
        המלא(100%) של הדירה ללא זכויות הבניה (ולא את החלק היחסי של הדירה ללא הזב"ן)
      </p>
    </div>
  ),
  sellerType: (
    <div>
      <strong>יחיד</strong>
      <p>יראו מוכר ובן זוגו, למעט בן זוג הגר דרך קבע בנפרד, וילדיהם שטרם מלאו להם 18 שנים למעט ילדים נשואים - כמוכר אחד.</p>
      <strong>חברה</strong>
      <p>
        חברה וחברה נכרית כמשמעותן בפקודת החברות, אגודה רשומה כמשמעותה בפקודת האגודות השיתופיות, שותפות הרשומה לפי פקודת השותפויות, אגודה
        כמשמעותה בחוק העותומני על האגודות 1327.
      </p>
    </div>
  ),
  propertyType: (
    <div>
      <strong>הגדרת דירת מגורים</strong>
      <p>
        דירה או חלק מדירה, שבנייתה נסתיימה והיא בבעלותו או בחכירתו של יחיד, ומשמשת למגורים או מיועדת למגורים לפי טיבה, למעט דירה המהווה מלאי
        עסקי לענין מס הכנסה.
      </p>
      <strong>דירת מגורים מזכה</strong>
      <p>
        דירת מגורים ששימשה בעיקרה למגורים לפחות באחת משתי התקופות שלהלן:
        <li>ארבע חמישיות מהתקופה שבשלה מחושב השבח;</li>
        <li>ארבע השנים שקדמו למכירתה;</li>
        לענין הגדרה זו, יראו תקופה שבה לא נעשה בדירה שימוש כלשהו, או תקופה שבה שימשה הדירה לפעולות חינוך, לרבות פעוטון, או דת כפי שקבע שר
        האוצר, באישור ועדת הכספים של הכנסת, כתקופה שבה שימשה הדירה למגורים; לענין התקופה הקבועה בפסקה (1) – לא תובא בחשבון תקופה שקדמה ליום
        הקובע, ולענין התקופה הקבועה בפסקה (2) – לא תובא בחשבון תקופה כאמור, אם ביקש זאת המוכר. (ב) לענין פרק זה יראו מוכר ובן זוגו, למעט בן
        זוג הגר דרך קבע בנפרד, וילדיהם שטרם מלאו להם 18 שנים למעט ילדים נשואים - כמוכר אחד".
      </p>
      <strong>זכות אחרת</strong>
      <p>כל נכס מקרקעין אחר שאינו "דירת מגורים מזכה".</p>
      <strong>איגוד מקרקעין</strong>
      <p>
        איגוד שכל נכסיו, במישרין או בעקיפין, הם זכויות במקרקעין, למעט איגוד שהזכויות בו רשומות למסחר בבורסה כהגדרתה בפקודה ולמעט קרן להשקעות
        במקרקעין כהגדרתה בסעיף 64א2 לפקודה, בתקופה שעד למועד הרישום למסחר בבורסה כהגדרתו בסעיף 64א3(א)(2) לפקודה, ובלבד שמניותיה נרשמו למסחר
        בבורסה כאמור באותו סעיף; ולענין זה לא יראו כנכסיו של האיגוד – מזומנים, מניות, איגרות חוב, ניירות ערך אחרים, ומטלטלין, שאינם משמשים
        לאיגוד לייצור הכנסתו, או שהם משמשים לייצור הכנסתו אולם לדעת המנהל הם טפלים למטרות העיקריות של האיגוד המבוצעות למעשה ולא בדרך
        ארעית;".
      </p>
    </div>
  ),
  hasZaban: (
    <p>
      המערכת מבצעת חישוב של זכויות בניה כאשר מדובר בפיצול אנכי-רעיוני (כשזכויות הבניה הנוספות מהוות חלק מהדירה). המערכת אינה מחשבת פיצול
      אופקי-פיזי (כשזכויות הבניה הינן מופרדות פיזית מהדירה,קרי- אפשרות לבנות יחידה נוספת נפרדת).
    </p>
  ),
  isOnlyProperty: (
    <div>
      <strong>הבהרה</strong>
      <p>
        יכול שתהיה לאדם דירה נוספת ו/או חלק מדירה ואף על זאת תיחשב הדירה הנמכרת כדירתו היחידה בהתאם לאמור בסעיף 49ג לחוק מיסוי מקרקעין וזאת
        במידה והדירה הנוספת נכללת במסגרת החריגים הקבועים בחוק כדלקמן:
        <li> הדירה הנוספת נירכשה כתחליף לדירה הנמכרת {config.apaBoughtAsReplacementToSoldApaAmountOfMonths} חודשים טרם המכירה.</li>
        <li> הדירה הנוספת הושכרה למגורים בשכירות מוגנת לפני יום כ"ב בטבת התשנ"ז (1 בינואר 1997).</li>
        <li> חלקו של המוכר בדירה הנוספת אינו עולה על 1/3.</li>
        <li> הדירה הנוספת הינה דירה שהתקבלה בירושה והתקיימו בה תנאי סעיף 49ב(5)(א) ו-(ב) לחוק.</li>
        <li>
          הדירה הנוספת הינה דירה שהתקבלה בירושה וחלקו של המוכר בה אינו עולה על 50% וזאת ללא קשר לזהות המוריש ולמניין דירות המוריש ביום
          פטירתו.
        </li>
      </p>
    </div>
  ),
  percentHoldingShares: (
    <p>
      בעל מניות מהותי – מי שמחזיק, במישרין או בעקיפין, לבדו או יחד עם אחר, ב-10% לפחות באחד או יותר מסוג כלשהו של אמצעי השליטה בחבר-בני-אדם.
    </p>
  ),
  wayOfGettingProperty: <p>קבלת לפחות 50% ממחיר הדירה במתנה, בתוך שלוש שנים שקדמו לרכישתה, תיחשב כדירה שנתקבלה במתנה</p>,
  kindOfRequestedPtor: <p>בדוק זכאותך לשימוש בפטורים המוצגים לך.</p>,
  isMutavAllowed: (
    <p>
      החל מיום 1.1.2018 אין מגבלה על מספר הפעמים לשימוש בליניארי מוטב ואין מגבלה למכירה לקרוב. אולם יובהר כי כתמיד פקיד השומה יכול לקבוע כי
      היקף המכירות ונסיבותיהם עולות לכדי עסקת אקראי או עסק ולחייב כהכנסה פירותית (שיעורי המס כקבועים בסעיף 121 לפקודת מס הכנסה (נוסח חדש)
      התשכ"א 1961).
    </p>
  ),
  purchaseAmountInOriginalCurrency: (
    <p>
      במכירה של חלק מהזכויות בנכס יש להזין ב"סכום הרכישה במטבע מקורי" את השווי המלא של הרכישה.
      <br />
      המערכת תחשב באופן אוטומטי את החלק היחסי של סכום הרכישה.
      <br />
      לדוגמא:{" "}
      <strong>
        כאשר החלק הנמכר הינו מחצית (50%) מהזכויות בנכס וסכום הרכישה המלא הוא 2,000,000 ש"ח , יש להזין ברובריקת "סכום הרכישה במטבע מקורי" את
        הסך של 2,000,000 ש"ח. כנ"ל לגבי ניכויים ,יש להזין את ערכם המלא. המערכת תבצע חישוב אוטומטי של החלק היחסי של הניכויים.
      </strong>
    </p>
  ),
  depreciation: (
    <div>
      <p>
        <strong>
          א.נישום יחיד בעל דירת מגורים שהושכרה למטרת מגורים יתכן ויהיה חייב בניכוי הפחת (הפחתתו משווי הרכישה) בעת מכירת דירת המגורים בהתאם
          למסלול חיוב ההכנסה מדמי שכירות של הדירה הנמכרת:
        </strong>
        <br />
        <strong>מסלול מס רגיל</strong> (תשלום מס הכנסה מלא בשעורי מס הקבועים בסעיף 121 לפקודה על הכנסה מהשכרה):
      </p>
      <p>ככל ונדרש פחת בשוטף, חייב בהפחתתו משווי הרכישה בעת מכירת דירת המגורים.</p>
      <p>
        ככל ולא נדרש פחת בשוטף אזי לא יופחת הפחת משווי הרכישה בעת מכירת דירת המגורים, ראה פס"ד לילי שמשון (ו"ע 1005/09). יודגש כי מסלול זה
        יחול גם כאשר הדירה הושכרה שלא למטרת מגורים. במסלול זה על מנת למלא את נתוני הפחת במערכת על המשתמש לפנות לרו"ח לקבלת טופס יא'.
      </p>
      <p>
        <strong>מסלול מס מופחת:</strong> ( 10%)
      </p>
      <p>
        תשלום מס מופחת בשיעור של 10% בלבד לפי סעיף 122 לפקודה. החוק קובע כי על אף שנשללה הזכות מהמשכיר לדרוש פחת בשוטף, לצורך חישוב מס שבח
        ינוכה הפחת המירבי שניתן היה לנכות באותה תקופה, בה נהנה משעור מס מופחת.
      </p>
      <p>
        <strong>מסלול פטור מלא</strong> (פטור ממס על הכנסה מהשכרת דירת מגורים עד התיקרה הנקובה בחוק מס הכנסה (פטור ממס על הכנסה מדירת
        מגורים) (הוראת שעה) , התש"ן 1990.: <br />
        בנוגע למסלול פטור מלא, נקבע בהוראת ביצוע מיסוי מקרקעין מס' 5/2007 מיום 27.2.2007 כי: "יש לנכות את הפחת משווי הרכישה שניתן היה לדרוש
        במס הכנסה )ניכוי הפחת משווי הרכישה מקביל למנגנון הוספת הפחת לשווי המכירה אשר נקבע בסעיף 122 לפקודה)".
      </p>
      <p>
        יצויין כי במסלול זה אין קביעה מפורשת בחוק להסדרי ההוצאות והניכויים כפי שיש במסלול מס מופחת. לאור זאת שיקול הדעת בידי הנישום לעניין
        ניכוי .על אף זו, בהתאם לפסק הדין שניתן בו"ע 10216-07-14 וימן נ' מנהל מס שבח מקרקעין, מדירה שהושכרה במסלול פטור מלא, על אף שלא ניתן
        לדרוש פחת בשוטף, יש להפחית משווי הרכישה את הפחת. יודגש כי בהתאם לפסק הדין הנ"ל במידה והדירה הושכרה במסלול פטור מלא אזי יש לנכות את
        הפחת שהצטבר החל מיום 27.2.2007 (מועד הוראת הביצוע הנ"ל) גם אם הדירה הושכרה לפני מועד זה.
      </p>
      <p>
        בעת עריכת חישוב פחת במכירת דירה שהושכרה למגורים יש לקחת בחשבון גם את הוראת ביצוע מיסוי מקרקעין 5/2007 לפיה יש לדרוש פחת בשיעור של 2%
        משווי הדירה כולל מרכיב הקרקע.
      </p>
      <p>
        ב. ברגע שהממכר הינו דירת מגורים שהושכרה ויש בגינו ניכויים, אז בנוסף להכנסת הסכום לרובריקת "ניכויים" (הוצאות הניתנות לניכוי) אז יש גם
        לנכות פחת מכל ניכוי וניכוי שהוא בר פחת, לדוגמא שיפוץ של נכס או תוספת בניה בנכס וכו'. לצורך חישוב הפחת בגין ניכויים ברי פחת ניתן
        להשתמש במחשבון הפחת המופיע בצידו השמאלי של המסך בעת שהמשתמש מקיש ניכוי שהוא בר פחת.
      </p>
    </div>
  ),
  shouldTryPrisa: (
    <div>
      <p>
        1. מהי פריסה? סעיף 48א(ה) לחוק מיסוי מקרקעין מאפשר למוכר לשלם מס שבח בשיעורי מס מופחתים לפי מדרגות מס נמוכות ובהתאם לנקודות הזיכוי
        להם הוא זכאי. הזכאות לחישוב המס בפריסה היא תוך תקופה שאינה עולה על ארבע שנות מס או תקופת הבעלות בנכס, לפי הקצרה שבהן, ומסתיימת בשנת
        מכירת הנכס. הפריסה משמעותית יותר למוכר בעל הכנסות נמוכות ושהינו מעל גיל 60 (שכן מגיל זה שיעורי המס מתחילים מ-10%, ראה סעיף 121
        לפקודת מס הכנסה). יודגש כי "תקופת הבעלות בנכס" הינה מתחילת שנת המס שלאחר מועד רכישת הזכות.
      </p>
      <p>
        2. בהכנסות המוכר יש לכלול את כל הכנסותיו בכל שנה שבגינה מבוקש לבצע פריסה, לרבות הכנסותיו מיגיעה אישית, רווחים מהבורסה, הכנסות מדמי
        שכירות (למעט הכנסות מהשכרת דירת מגורים המומסות במסלול 10% מס) וכו'. מומלץ לבקש מהמוכר לפנות לרו"ח להביא אישור על הכנסותיו ועל זכאותו
        לנקודות זיכוי. רו"ח מתייחס באישורו לכל היבטי המיסוי של המוכר, לרבות היותו רווק או נשוי וזכאותו למספר נקודת זיכוי בהתאם לנתוניו.
      </p>
      <p>
        3. בעת ביצוע פריסה יש לצרף לדיווח לרשויות המס בקשה לפריסת מס שבח מקרקעין לפי טופס מס' 7003. יודגש כי ניתן לבקש פריסה רק במידה והמוכר
        הגיש דוחות לפי סעיף 131 לפקודה לשנות המס שבתקופת הפריסה, למעט שנות מס שלגביהן טרם חלף המועד להגשת אותם דוחות.
      </p>
      <p>
        4. הפריסה מבוצעת רק בגין הכנסותיו של המוכר הרשום כבעל הזכויות בנכס. כך לדוגמא במידה ושני בני הזוג רשומים כבעלי הזכויות בנכס אזי יש
        לבצע שתי שומות עם פריסה (לגבי כל בן זוג בנפרד), כל אחת בגין מחצית שווי המכירה הכוללת פריסה עם הכנסותיו של אותו בן זוג. בעת מכירת נכס
        ע"י מוכר שיש לו בן זוג שאינו רשום כבעל הזכויות בנכס, הפריסה תבוצע רק בגין הכנסותיו של המוכר ולא של בן זוגו, אולם במידה ומהותית גם בן
        הזוג השני הינו בעל זכויות בנכס ניתן לנסות לבצע שתי שומות נפרדות ,כאמור לעיל ,בטענה של חזקת השיתוף בנכסים או כל הלכה משפטית רלוונטית
        אחרת.
      </p>
      <p>
        5. בהתאם להוראות החוק, פריסה מבוצעת רק למי שהינו תושב ישראל, אולם בפסק הדין בו"ע 10516-03-12 ברוך ואח' נ' מדינת ישראל נקבע כי ניתן
        גם לבצע פריסה לתושב חוץ שהינו מאחת המדינות שעמן ישראל חתומה על אמנה למניעת כפל מס.
      </p>
      <p>
        6. לפי הוראת ביצוע מס' 25/98 בעת מכירת מספר נכסים באותה שנת מס, אין לבצע פריסה ליותר מנכס אחד באותה שנת מס, אולם בפסק הדין בו"ע
        005038/05 סגל ישראל נ' מנהל משרד מיסוי מקרקעין ,שבו נמכרו 2 נכסים באותה שנת מס, נקבע כלדקמן:
        <br />
        "בענייננו, החיל המשיב את הפריסה על מכירת חנות אחת בלבד לפי הנחית הוראות בצוע מס שבח מס' 25/98 שלא הוצגה. הוראות בצוע מסייעות
        לנישומים ולרשויות המס, ובתי המשפט מחשיבים אותן ונוהגים על פיהן אם אינן חורגות מהוראות החוק ותכליתו. בענייננו לא הוכחו הוראת חוק או
        טעם לייחוד הפריסה לחנות אחת בלבד. כשנישום מוכר 2 חנויות ומתחייב במסוי על 2 חנויות בגין שבח ריאלי, הפריסה מתייחסת לשבח הראלי ולא
        למספר הנכסים הנמכרים. לפיכך, יש להרחיב את הפריסה על העסקה של מכירת 2 החנויות".
      </p>
    </div>
  ),
  kizuzIski: <p>ניתן לקזז הפסד פרותי מהשנה הנוכחית בלבד, אלא אם הנכס שימש בשנים קודמות לייצור הכנסה.</p>,
  kizuzHoni: <p>ניתן לקזז הפסד הוני שנצבר מיום 1/1/1996.</p>,
  isGilum: (
    <p>
      סעיף 17(ד) לחוק קובע מהו גילום מלא:"גילום מלא" - התמורה בהסכם המכירה, בתוספת סכום המס שהיה על המוכר לשלם על מנת שתיוותר בידו התמורה
      האמורה, אילו הקונה לא היה מקבל על עצמו את תשלום המס". גילום מלא מתרחש בעת שהמוכר והקונה מסכימים ביניהם כי הקונה ישא בחבות מס השבח
      המוטלת על המוכר. במקרה זה למעשה המוכר מבקש כי התמורה שסוכמה תיהיה התמורה שהוא יקבלה נטו. במידה וכך מבוקש בעסקה נשוא השומה ,אזי יש
      להקיש במחשבון על "כן" כך שבמקום המוכר, הקונה הוא זה שישא בנטל חבות מס השבח בעת המכירה.
    </p>
  ),
  afkaa: <p>בהפקעת מקרקעין על ידי המדינה או רשות ניתן לקבל עד 50% הנחה מתשלום מס שבח.</p>,
  depreciationResult: (
    <p>
      בעת שמחשבים פחת במסלול פטור מלא, מועד תיאום הפחת הינו יום הרכישה. בעת שמחשבים פחת במסלול מס של 10% אזי לפי סעיף 122 לפקודת מס הכנסה,
      המועד לתיאום הפחת הינו יום המכירה
    </p>
  ),
  isGiftPropertyFromRelated: (
    <p>
      "קרוב"- אחד מאלה: בן זוג, הורה, הורי הורה, צאצא, צאצאי בן זוג, אח, אחות, בני זוגם של כל אחד מהנ"ל, איגוד שהוא בשליטתו.
      <br />
      "מתנה" - האם קיבלת לפחות 50% ממחיר הדירה המלא במתנה, בתוך שלוש השנים שקדמו לרכישתה? (במקרה של דירה שבנייתה טרם הושלמה, יראו את מועד
      השלמת בנייתה כמועד קבלתה במתנה).
    </p>
  ),
  propertyGiftedDate: <p>ככל שהתקבל חלק מהדירה במתנה, יש להמתין תקופת צינון לגבי אותו חלק, גם אם הוא מהווה פחות מ-50% מהדירה.</p>,
  seif51FilledDate: (
    <div>
      לפי סעיף 51 לחוק, חובת תשלום המס תחול במועד המאוחר ביותר בין התנאים הבאים:
      <br /> 1. התקבלה החזקה בידי הקונה.
      <br />
      2. הקונה שילם 40% מהתמורה.
      <br />
      3. ניתן ייפוי כח בלתי חוזר לצורך העברת הנכס על שם הקונה.
    </div>
  ),
  pinuiBinuiTikratShovi: (
    <div>
      הסבר על "תקרת שווי" לפי סעיף 49כב לחוק:
      <br />
      "תקרת השווי" – אחד מאלה, לפי הגבוה:
      <br />
      1. 150% משווי יחידת המגורים הנמכרת, בניכוי שוויי זכויות לבניה נוספת.
      <br />
      2 .שווי דירת מגורים,באותו מתחם, ששטחה 120 מ"ר;
      <br />
      3. שווי דירת מגורים, באותו מתחם, ששטחה 150% משטחה של יחידת המגורים הנמכרת, ולא יותר מ 200- מ"ר;
      <br />
      והכל בתוספת תשלום בעבור דמי שכירות לתקופת בניית יחידת המגורים החלופית, ולכיסוי הוצאות כרוכות כפי שקבע המנהל.
    </div>
  ),
  pinuiBinuiGotMoneyReducedApaValue: <div>לדוגמא: הנישום היה זכאי לדירת 100 מ"ר אך שינמך לדירת 80 מ"ר ותמורת השינמוך קיבל כסף מהיזם</div>,
  pinuiBinuiPaidMoneyIncreasedApaValue: <div>לדוגמא הנישום היה זכאי לדירת 80 מ"ר אך שדרג, תמורת תשלום נוסף ליזם, לדירת 100 מ"ר</div>,
  sellerMutavTxt: (
    <div>
      "מוכר מוטב" הינו מי שבעת החתימה על הסכם התמ"א 38/2 היה "קשיש", כהגדרתו בחוק, או בעל דירה יחידה שהיה זכאי לפטור דירה יחידה לפי סעיף
      49ב(2) אילו היה מוכר בזמנו את הדירה לצד ג'
    </div>
  )
};

export default helpTexts;
