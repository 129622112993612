import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as customColors from "../../theme/customColors";

// Extended optional props:
// name - name of the table.
// headers - array of the headers.
// rows - array of arrays.
// colorTableName - color the top right cell.
// emphasizeSummary - emphasize the bottom left cell.

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: customColors.greyAndBlue,
    border: "1px solid " + theme.palette.grey[500],
    "&:nth-of-type(1)": {
      backgroundColor: theme.palette.grey[200]
    }
  },
  body: {
    border: "1px solid " + theme.palette.grey[500],
    "&:nth-of-type(1)": {
      backgroundColor: theme.palette.grey[200]
    }
  }
}))(TableCell);

export default function CustomizedTables(props) {
  const isSummaryCell = function (rowIndex, colIndex) {
    const heigt = props.rows.length - 1;
    const width = props.rows[heigt].length - 1;
    return rowIndex === heigt && colIndex === width;
  };
  const isFirstColumn = function (colIndex) {
    return colIndex === 0;
  };

  return (
    <TableContainer style={{ overflow: "visible" }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow style={{ heigt: "200px" }}>
            {props.headers
              ? props.headers.map((header, index) => (
                  <StyledTableCell size="small" key={`header-${props.name}-${index}`}>
                    <Typography
                      color={index === 0 && props.colorTableName ? "secondary" : "textPrimary"}
                      variant="subtitle2"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      {header}
                    </Typography>
                  </StyledTableCell>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows
            ? props.rows.map((row, rowIndex) => (
                <TableRow key={`row-${props.name}-${rowIndex}`}>
                  {row.map((cell, cellIndex) => (
                    <StyledTableCell
                      size="small"
                      key={`cell-${props.name}-${rowIndex}-${cellIndex}`}
                      style={props.emphasizeSummary && isSummaryCell(rowIndex, cellIndex) ? { borderWidth: "3px" } : {}}
                    >
                      <Typography
                        variant="subtitle2"
                        align="center"
                        style={
                          isFirstColumn(cellIndex) || (props.emphasizeSummary && isSummaryCell(rowIndex, cellIndex))
                            ? { fontWeight: "bold" }
                            : {}
                        }
                      >
                        {Number.isFinite(cell)
                          ? cell.toLocaleString(undefined, {
                              minimumFractionDigits: !Number.isInteger(cell) ? 2 : 0,
                              maximumFractionDigits: 2
                            })
                          : cell}
                      </Typography>
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
