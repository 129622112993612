import React from "react";
import LabeledTextField from "./LabeledTextField";
import * as customColors from "../../theme/customColors";

function TextFieldWithIcon(props) {
  return (
    <div className="mt-10 mr-40 relative">
      <span className="text-field-with-icon" style={{ background: props.iconColor ? props.iconColor : customColors.orange }}>
        <i className="material-icons">{props.iconName}</i>
      </span>
      <LabeledTextField
        name={props.name}
        placeholder={props.placeholder}
        textFieldWidth={props.textFieldWidth ? props.textFieldWidth : "325"}
        onKeyPress={props.onKeyPress}
        onChange={props.onChange}
        value={props.value}
        type={props.type}
      />
    </div>
  );
}

export default TextFieldWithIcon;
