import config from "../config";
import axios from "axios";

export default {
  login: (email, password) => {
    return new Promise((resolve) => {
      const stationName = localStorage.getItem("stationName");
      axios.post(config.loginUserEndpoint, { email, password, stationName }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },
  pendingRegister: function (data) {
    return new Promise((resolve) => {
      axios.post(config.pendingRegisterEndpoint, data).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  tryConfEmailToken: (token, email) => {
    return new Promise((resolve) => {
      axios.post(config.confEmailEndpoint, { token, email }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  resendEmailConf: (email) => {
    return new Promise((resolve) => {
      axios.post(config.resendEmailConfEndpoint, { email }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  resetUserPass: (email) => {
    return new Promise((resolve) => {
      axios.post(config.resetUserPassEndpoint, { email }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  changePass: (email, password, token) => {
    return new Promise((resolve) => {
      axios.post(config.changeUserPassEndpoint, { email, password, token }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  informUsContactUsMsg: (data) => {
    return new Promise((resolve) => {
      axios.post(config.informUsContactMsgEndpoint, data).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  toggleReminders: (areRemindersOn) => {
    return new Promise((resolve) => {
      axios.post(config.toggleRemindersEndpoint, { areRemindersOn }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  getClientJobOptions: () => {
    return [
      { key: "lawyer", label: "עו״ד" },
      { key: "accountant", label: "רואה חשבון" },
      { key: "shamai", label: "שמאי" },
      { key: "adviser", label: "יועץ מס" },
      { key: "yazam", label: "יזם" },
      { key: "lawyerAndAccountant", label: "עו״ד ורואה חשבון" },
      { key: "lawyerAndShamai", label: "עו״ד ושמאי" },
      { key: "adviserAndAccountant", label: "יועץ מס ורואה חשבון" },
      { key: "jobOther", label: "אחר" }
    ];
  }
};
