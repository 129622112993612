import React, { useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import VideocamIcon from "@material-ui/icons/Videocam";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccessibleIcon from "@material-ui/icons/Accessible";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

import IndexCalculatorDialog from "../dialogs/indexCalculatorDialog";
import DollarExchangeDialog from "../dialogs/dollarExchangeDialog";
import AccessibilityDialog from "../dialogs/AccessibilityDialog";
import VideoInfoDialog from "../dialogs/videoInfoDialog";

import * as customColors from "../../theme/customColors";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  optionText: {
    color: customColors.darkBlue,
    webkitUserSclect: "none",
    mozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none"
  },
  optionIcon: {
    color: customColors.darkBlue,
    marginRight: "6px"
  },
  optionBoxIcon: {
    width: "38px",
    height: "38px",
    borderRadius: "19px",
    background: customColors.orange
  },
  toolOptionBox: {
    width: "150px",
    height: "45px",
    borderColor: customColors.darkBlue,
    marginBottom: "6px",
    marginLeft: "-100px",
    background: customColors.lightBlue,
    borderTopRightRadius: "23px",
    borderBottomRightRadius: "23px",
    transition: "all 1s",
    "&:hover": {
      marginLeft: 0,
      cursor: "pointer"
    }
  },
  toolBarContainer: {
    position: "fixed",
    top: "25%",
    left: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    zIndex: 1
  }
}));

const CustomToolBar = (props) => {
  const classes = useStyles();
  const [isIndexCalculatorOpen, setIsIndexCalculatorOpen] = useState(false);
  const [isDollarExchangeOpen, setIsDollarExchangeOpen] = useState(false);
  const [isAccessibilityDialogOpen, setIsAccessibilityDialogOpen] = useState(false);
  const [isVideoInfoOpen, setIsVideoInfoOpen] = useState(false);

  const handleClickOpen = (dialog) => {
    if (dialog === "isIndexCalculatorOpen") setIsIndexCalculatorOpen(true);
    else if (dialog === "isDollarExchangeOpen") setIsDollarExchangeOpen(true);
    else if (dialog === "isVideoInfoOpen") setIsVideoInfoOpen(true);
    else if (dialog === "isAccessibilityDialogOpen") setIsAccessibilityDialogOpen(true);
  };

  const handleClose = (dialog) => {
    if (dialog === "isIndexCalculatorOpen") setIsIndexCalculatorOpen(false);
    else if (dialog === "isDollarExchangeOpen") setIsDollarExchangeOpen(false);
    else if (dialog === "isAccessibilityDialogOpen") setIsAccessibilityDialogOpen(false);
    else if (dialog === "isVideoInfoOpen") setIsVideoInfoOpen(false);
  };

  return (
    <Fragment>
      <div className={classes.toolBarContainer}>
        <Box
          className={classes.toolOptionBox}
          border={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={() => window.open("https://www.gov.il/he/departments/israel_tax_authority", "_blank").focus()}
        >
          <Box className={classes.optionBoxIcon} m={0.4} display="flex" flexDirection="row" justifyItems="flex-end" alignItems="center">
            <BusinessCenterIcon className={classes.optionIcon} />
          </Box>
          <Box pr={1}>
            <Typography variant="subtitle1" className={classes.optionText}>
              הוראות ביצוע
            </Typography>
          </Box>
        </Box>
        <Box
          className={classes.toolOptionBox}
          border={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={() => window.open("https://www.misim.gov.il/svsimurechisha/frmFirstPage.aspx?cur=1#nbb", "_blank").focus()}
        >
          <Box className={classes.optionBoxIcon} m={0.4} display="flex" flexDirection="row" justifyItems="flex-end" alignItems="center">
            <AttachMoneyIcon className={classes.optionIcon} />
          </Box>
          <Box pr={1}>
            <Typography variant="subtitle1" className={classes.optionText}>
              מס רכישה
            </Typography>
          </Box>
        </Box>
        <Box
          className={classes.toolOptionBox}
          border={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={() => handleClickOpen("isDollarExchangeOpen")}
        >
          <Box className={classes.optionBoxIcon} m={0.4} display="flex" flexDirection="row" justifyItems="flex-end" alignItems="center">
            <LocalAtmIcon className={classes.optionIcon} />
          </Box>
          <Box pr={1}>
            <Typography variant="subtitle1" className={classes.optionText}>
              המרת מט"ח
            </Typography>
          </Box>
        </Box>
        <Box
          className={classes.toolOptionBox}
          border={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={() => handleClickOpen("isVideoInfoOpen")}
        >
          <Box className={classes.optionBoxIcon} m={0.4} display="flex" flexDirection="row" justifyItems="flex-end" alignItems="center">
            <VideocamIcon className={classes.optionIcon} />
          </Box>
          <Box pr={1}>
            <Typography variant="subtitle1" className={classes.optionText}>
              סרטוני הדרכה
            </Typography>
          </Box>
        </Box>
        <Box
          className={classes.toolOptionBox}
          border={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={() => handleClickOpen("isIndexCalculatorOpen")}
        >
          <Box className={classes.optionBoxIcon} m={0.4} display="flex" flexDirection="row" justifyItems="flex-end" alignItems="center">
            <TrendingUpIcon className={classes.optionIcon} />
          </Box>
          <Box pr={1}>
            <Typography variant="subtitle1" className={classes.optionText}>
              מחשבון מדד
            </Typography>
          </Box>
        </Box>
        <Box
          className={classes.toolOptionBox}
          border={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={() => handleClickOpen("isAccessibilityDialogOpen")}
        >
          <Box className={classes.optionBoxIcon} m={0.4} display="flex" flexDirection="row" justifyItems="flex-end" alignItems="center">
            <AccessibleIcon className={classes.optionIcon} />
          </Box>
          <Box pr={1}>
            <Typography variant="subtitle1" className={classes.optionText}>
              נגישות
            </Typography>
          </Box>
        </Box>
      </div>
      {isIndexCalculatorOpen ? <IndexCalculatorDialog open onClose={() => handleClose("isIndexCalculatorOpen")} /> : null}
      {isDollarExchangeOpen ? <DollarExchangeDialog open onClose={() => handleClose("isDollarExchangeOpen")} /> : null}
      {isAccessibilityDialogOpen ? <AccessibilityDialog open onClose={() => handleClose("isAccessibilityDialogOpen")} /> : null}
      {isVideoInfoOpen ? <VideoInfoDialog open onClose={() => handleClose("isVideoInfoOpen")} /> : null}
    </Fragment>
  );
};

export default CustomToolBar;
