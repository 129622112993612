import citiesList from "../../assets/data/cities";

export const tabNames = {
  SELLERS_INFO: "פרטי המוכרים",
  BUYERS_INFO: "פרטי הקונים",
  THIRD_PARTY: "פרטי צד שלישי",
  ASSET_INFO: "פרטי ממכר",
  LAWYER_INFO: "פרטי מיצגים",
  GENERAL: "פרטים כלליים"
};

// SELLERS_INFO + BUYERS_INFO + THITD_PARTY -> May be duplicated 3 times.

export default {
  buyerFirstName: { name: "buyerFirstName", belongToTab: tabNames.BUYERS_INFO, label: "שם פרטי", type: "text", weight: 1 },
  buyerLastName: { name: "buyerLastName", belongToTab: tabNames.BUYERS_INFO, label: "שם משפחה", type: "text", weight: 2 },
  buyerUnion: { name: "buyerUnion", belongToTab: tabNames.BUYERS_INFO, label: "שם תאגיד", type: "text", weight: 3 },
  buyerIdType: {
    name: "buyerIdType",
    belongToTab: tabNames.BUYERS_INFO,
    type: "select",
    options: [
      { label: "ת.ז", value: "ת.ז" },
      { label: "ח.פ", value: "ח.פ" },
      { label: "דרכון", value: "דרכון" }
    ],
    label: "סוג זיהוי",
    weight: 4
  },
  buyerId: {
    name: "buyerId",
    belongToTab: tabNames.BUYERS_INFO,
    label: "מספר זיהוי",
    type: "text",
    weight: 5
  },
  buyerRightPart: { name: "buyerRightPart", belongToTab: tabNames.BUYERS_INFO, label: "החלק בנכס", type: "text", weight: 6 },
  buyerCity: {
    name: "buyerCity",
    belongToTab: tabNames.BUYERS_INFO,
    label: "עיר",
    type: "text",
    weight: 7
  },
  buyerStreet: {
    name: "buyerStreet",
    belongToTab: tabNames.BUYERS_INFO,
    label: "רחוב",
    type: "text",
    weight: 8
  },
  buyerStreetNum: {
    name: "buyerStreetNum",
    belongToTab: tabNames.BUYERS_INFO,
    label: "מספר בית",
    type: "text",
    weight: 9
  },
  buyerHouseNum: {
    name: "buyerHouseNum",
    belongToTab: tabNames.BUYERS_INFO,
    label: "דירה\\כניסה",
    type: "text",
    weight: 10
  },
  buyerUnitNum: {
    name: "buyerUnitNum",
    belongToTab: tabNames.BUYERS_INFO,
    label: "מס' יחידה",
    type: "text",
    weight: 11
  },
  sellerFirstName: { name: "sellerFirstName", belongToTab: tabNames.SELLERS_INFO, label: "שם פרטי", type: "text", weight: 1 },
  sellerLastName: { name: "sellerLastName", belongToTab: tabNames.SELLERS_INFO, label: "שם משפחה", type: "text", weight: 2 },
  sellerUnion: { name: "sellerUnion", belongToTab: tabNames.SELLERS_INFO, label: "שם תאגיד", type: "text", weight: 3 },
  sellerIdType: {
    name: "sellerIdType",
    belongToTab: tabNames.SELLERS_INFO,
    type: "select",
    options: [
      { label: "ת.ז", value: "ת.ז" },
      { label: "ח.פ", value: "ח.פ" },
      { label: "דרכון", value: "דרכון" }
    ],
    label: "סוג זיהוי",
    weight: 4
  },
  sellerId: {
    name: "sellerId",
    belongToTab: tabNames.SELLERS_INFO,
    label: "מספר זיהוי",
    type: "text",
    weight: 5
  },
  sellerCity: {
    name: "sellerCity",
    belongToTab: tabNames.SELLERS_INFO,
    label: "עיר",
    type: "text",
    weight: 6
  },
  sellerStreet: {
    name: "sellerStreet",
    belongToTab: tabNames.SELLERS_INFO,
    label: "רחוב",
    type: "text",
    weight: 7
  },
  sellerStreetNum: {
    name: "sellerStreetNum",
    belongToTab: tabNames.SELLERS_INFO,
    label: "מספר בית",
    type: "text",
    weight: 8
  },
  sellerHouseNum: {
    name: "sellerHouseNum",
    belongToTab: tabNames.SELLERS_INFO,
    label: "דירה\\כניסה",
    type: "text",
    weight: 9
  },
  sellerMahutHazchut: {
    name: "sellerMahutHazchut",
    belongToTab: tabNames.SELLERS_INFO,
    label: "מהות הזכות במקרקעין",
    type: "text",
    weight: 10
  },
  sellerRightParts: {
    name: "sellerRightParts",
    belongToTab: tabNames.SELLERS_INFO,
    label: "החלקים בזכות",
    type: "text",
    weight: 1
  },
  assetCity: {
    name: "assetCity",
    belongToTab: tabNames.ASSET_INFO,
    label: "הישוב",
    type: "autocomplete",
    freeSolo: false,
    options: citiesList.map((item) => {
      return { label: item, value: item };
    }),
    weight: 1
  },
  blockNum: {
    name: "blockNum",
    belongToTab: tabNames.ASSET_INFO,
    label: "הגוש \\ הספר",
    type: "text",
    weight: 2
  },
  subBlock: {
    name: "subBlock",
    belongToTab: tabNames.ASSET_INFO,
    label: "החלקה\\הדף",
    type: "text",
    weight: 3
  },
  subSubBlock: {
    name: "subSubBlock",
    belongToTab: tabNames.ASSET_INFO,
    label: "תת-חלקה",
    type: "text",
    weight: 4
  },
  assetArea: {
    name: "assetArea",
    belongToTab: tabNames.ASSET_INFO,
    label: "השטח במ''ר",
    type: "text",
    weight: 5
  },
  sellerPartMekarkein: {
    name: "sellerPartMekarkein",
    belongToTab: tabNames.ASSET_INFO,
    label: "חלקו של המוכר במקרקעין",
    type: "text",
    weight: 6
  },
  passPartMekarkein: {
    name: "passPartMekarkein",
    belongToTab: tabNames.ASSET_INFO,
    label: "החלקים המועברים מהמקרקעין",
    type: "text",
    weight: 7
  },
  exchangeAfterSale: {
    name: "exchangeAfterSale",
    belongToTab: tabNames.ASSET_INFO,
    label: "עודף",
    type: "text",
    weight: 8
  },
  assetDescriptionTxt: {
    name: "assetDescriptionTxt",
    belongToTab: tabNames.ASSET_INFO,
    label: "תיאור המקרקעין ו\\או גבולותיהם והשעבודים כמפורט בפנקסי המקרקעין",
    type: "text",
    weight: 9
  },
  streetAsset: {
    name: "streetAsset",
    belongToTab: tabNames.ASSET_INFO,
    label: "רחוב",
    type: "text",
    weight: 10
  },
  fieldNum: {
    name: "fieldNum",
    belongToTab: tabNames.ASSET_INFO,
    label: "מגרש מספר",
    type: "text",
    weight: 10
  },
  assetStreetNum: {
    name: "assetStreetNum",
    belongToTab: tabNames.ASSET_INFO,
    label: "מספר בית",
    type: "text",
    weight: 11
  },
  assetFloor: {
    name: "assetFloor",
    belongToTab: tabNames.ASSET_INFO,
    label: "קומה",
    type: "text",
    weight: 11
  },
  assetDescriptionKivunim: {
    name: "assetDescriptionKivunim",
    belongToTab: tabNames.ASSET_INFO,
    label: "כיוונים",
    type: "text",
    weight: 11
  },
  assetHouseNum: {
    name: "assetHouseNum",
    belongToTab: tabNames.ASSET_INFO,
    label: "דירה\\כניסה",
    type: "text",
    weight: 12
  },
  shtarHahira: {
    name: "shtarHahira",
    belongToTab: tabNames.ASSET_INFO,
    label: "מספר שטר מקורי",
    type: "text",
    weight: 13
  },
  ramiNum: {
    name: "ramiNum",
    belongToTab: tabNames.ASSET_INFO,
    label: "מספר תיק רמי",
    type: "text",
    weight: 13
  },
  propertyBelongsTo: {
    name: "propertyBelongsTo",
    belongToTab: tabNames.ASSET_INFO,
    label: "הבעלים של הממכר",
    options: [
      { label: "מדינת ישראל", value: "belongsToIsrael" },
      { label: "רשות הפיתוח", value: "belongsToPituah" },
      { label: "קרן קיימת לישראל", value: "belongsToKeren" }
    ],
    type: "radio",
    weight: 14
  },
  fixHahiraTerms: {
    name: "fixHahiraTerms",
    belongToTab: tabNames.ASSET_INFO,
    label: "תיקון תנאי חכירה",
    options: [
      { label: "היוון", value: "fixHahiraTermsIvun" },
      { label: "הארכת תקופה", value: "fixHahiraTermsIncrease" }
    ],
    type: "radio",
    weight: 15
  },
  rishumZhutHahira: {
    name: "rishumZhutHahira",
    belongToTab: tabNames.ASSET_INFO,
    label: "רישום זכות",
    options: [
      { label: "חכירה ראשית", value: "rishumZhutHahiraFirst" },
      { label: "חכירת משנה", value: "rishumZhutHahiraOther" }
    ],
    type: "radio",
    weight: 16
  },
  buyerRepresentLastName: {
    name: "buyerRepresentLastName",
    belongToTab: tabNames.LAWYER_INFO,
    label: "פרטי מייצג הרוכש- שם משפחה",
    type: "text",
    weight: 1
  },
  buyerRepresentFirstName: {
    name: "buyerRepresentFirstName",
    belongToTab: tabNames.LAWYER_INFO,
    label: "פרטי מייצג הרוכש-שם פרטי",
    type: "text",
    weight: 2
  },
  // buyerRepresentFirstName: {
  //   name: "buyerRepresentFirstName",
  //   belongToTab: tabNames.LAWYER_INFO,
  //   label: "פרטי מייצג הרוכש-שם פרטי",
  //   type: "text",
  //   weight: 2
  // },
  buyerRepresentIdType: {
    name: "buyerRepresentIdType",
    belongToTab: tabNames.LAWYER_INFO,
    type: "select",
    options: [
      { label: "ת.ז", value: "ת.ז" },
      { label: "ח.פ", value: "ח.פ" },
      { label: "דרכון", value: "דרכון" }
    ],
    label: "פרטי מייצג הרוכש-סוג זיהוי",
    weight: 3
  },
  buyerRepresentId: {
    name: "buyerRepresentId",
    belongToTab: tabNames.LAWYER_INFO,
    label: "פרטי מייצג הרוכש-מספר זיהוי",
    type: "text",
    weight: 4
  },
  sellerRepresentLastName: {
    name: "sellerRepresentLastName",
    belongToTab: tabNames.LAWYER_INFO,
    label: "פרטי מייצג המוכר-שם משפחה",
    type: "text",
    weight: 5
  },
  sellerRepresentFirstName: {
    name: "sellerRepresentFirstName",
    belongToTab: tabNames.LAWYER_INFO,
    label: "פרטי מייצג המוכר-שם פרטי",
    type: "text",
    weight: 6
  },
  sellerRepresentIdType: {
    name: "sellerRepresentIdType",
    belongToTab: tabNames.LAWYER_INFO,
    type: "select",
    options: [
      { label: "ת.ז", value: "ת.ז" },
      { label: "ח.פ", value: "ח.פ" },
      { label: "דרכון", value: "דרכון" }
    ],
    label: "פרטי מייצג המוכר-סוג זיהוי",
    weight: 7
  },
  sellerRepresentId: {
    name: "sellerRepresentId",
    belongToTab: tabNames.LAWYER_INFO,
    label: "פרטי מייצג המוכר-מספר זיהוי",
    type: "text",
    weight: 8
  },
  sellerRepresentCity: {
    name: "sellerRepresentCity",
    belongToTab: tabNames.LAWYER_INFO,
    label: "פרטי מייצג המוכר-עיר",
    type: "text",
    weight: 9
  },
  sellerRepresentStreet: {
    name: "sellerRepresentStreet",
    belongToTab: tabNames.LAWYER_INFO,
    label: "פרטי מייצג המוכר-רחוב",
    type: "text",
    weight: 10
  },
  sellerRepresentStreetNum: {
    name: "sellerRepresentStreetNum",
    belongToTab: tabNames.LAWYER_INFO,
    label: "פרטי מייצג המוכר-מספר בית",
    type: "text",
    weight: 11
  },
  sellerRepresentHouseNum: {
    name: "sellerRepresentHouseNum",
    belongToTab: tabNames.LAWYER_INFO,
    label: "פרטי מייצג המוכר-דירה\\כניסה",
    type: "text",
    weight: 12
  },
  sellerRepresentPhone: {
    name: "sellerRepresentPhone",
    belongToTab: tabNames.LAWYER_INFO,
    label: "פרטי מייצג המוכר-טלפון",
    type: "text",
    weight: 13
  },
  lishcatRishumLocation: {
    name: "lishcatRishumLocation",
    belongToTab: tabNames.GENERAL,
    type: "select",
    options: [
      { label: "תל אביב", value: "תל אביב" },
      { label: "מרכז", value: "מרכז" },
      { label: "חדרה", value: "חדרה" },
      { label: "פתח תקווה", value: "פתח תקווה" },
      { label: "חיפה", value: "חיפה" },
      { label: "ירושלים", value: "ירושלים" },
      { label: "נתניה", value: "נתניה" },
      { label: "באר שבע", value: "באר שבע" },
      { label: "נצרת", value: "נצרת" },
      { label: "רחובות", value: "רחובות" },
      { label: "חולון", value: "חולון" },
      { label: "טבריה", value: "טבריה" }
    ],
    label: "לשכת רישום המקרקעין ב-",
    weight: 1
  },
  billNum: {
    name: "billNum",
    belongToTab: tabNames.GENERAL,
    type: "number",
    label: "שטר מספר",
    weight: 2
  },
  retunNoReturn: {
    name: "retunNoReturn",
    belongToTab: tabNames.GENERAL,
    label: "השטר הזה מעיד",
    options: [
      { label: "שללא תמורה", value: "billSaleNoReturn" },
      { label: "שבתמורה", value: "billSaleReturn" }
    ],
    type: "radio",
    weight: 3
  },
  mustHaveDeal126: {
    name: "mustHaveDeal126",
    belongToTab: tabNames.GENERAL,
    label: "לפי סעיף  126 - על התחייבות לעשות עסקה",
    options: [
      { label: "על התחייבות לעשות עסקה", value: "mustHaveDeal126" },
      { label: "על הימנעות מעשיית עסקה", value: "noDeal126" },
      { label: "על הצורך בהסכמה", value: "needAgreement128" }
    ],
    type: "radio",
    weight: 4
  },
  seif126: {
    name: "seif126",
    belongToTab: tabNames.GENERAL,
    label: 'לפי סעיף 126 לחוק המקרקעין, תשכ"ט- 1969',
    options: [
      { label: 'לפי סעיף 126 לחוק המקרקעין, תשכ"ט', value: "seif126" },
      { label: 'לפי סעיף 128 לחוק המקרקעין, תשכ"ט-1969', value: "seif128" }
    ],
    type: "radio",
    weight: 5
  },
  agreementDocument: {
    name: "agreementDocument",
    belongToTab: tabNames.GENERAL,
    label: "הסכם",
    options: [
      { label: "הסכם", value: "agreementDocument" },
      { label: "ייפוי כח בלתי חוזר", value: "ipuiKoahDocument" },
      { label: "אחר", value: "otherDocument" }
    ],
    type: "radio",
    weight: 6
  },

  unionName: {
    name: "unionName",
    belongToTab: tabNames.GENERAL,
    label: 'אישור עו"ד על בדיקת מסמכי תאגיד-אני הח"מ, מצהיר כי בדקתי את מסמכי תאגיד:',
    type: "text",
    weight: 7
  },
  corporationNum: {
    name: "corporationNum",
    belongToTab: tabNames.GENERAL,
    label: "אישור עו\"ד על בדיקת מסמכי תאגיד -מס' תאגיד:",
    type: "text",
    weight: 8
  },

  signDate: {
    name: "signDate",
    belongToTab: tabNames.GENERAL,
    label: "תאריך חתימה",
    type: "date",
    weight: 8
  }

  // "approvingThatThisDay",
  // "corporationNum", ???
};
