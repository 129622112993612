import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import { getIlsCurrencyDateBased, numberWithCommas } from "../../../services/utils/utils";
import moment from "moment";

let validationType = null;
const MIN_SUPPORTED_DATE = "1950-01-01";
const MAX_SUPPORTED_DATE = moment().format("YYYY-MM-DD");

export default function Combination({ setFormInvalidMsg, setIsFormInvalid, setDataToSave }) {
  const [isCombinationBeforeAug2013, setIsCombinationBeforeAug2013] = useState(null);
  const [hadPtorChapterFive, setHadPtorChapterFive] = useState(null);
  const [dayApaPurchased, setDayApaPurchased] = useState(null);
  const [combinationDate, setCombinationDate] = useState(null);
  const [totalPropertyCost, setTotalPropertyCost] = useState("");
  const [percentLeftFromCombinationToSoldProperty, setPercentLeftFromCombinationToSoldProperty] = useState("");
  const [valueBuildingApaCombination, setValueBuildingApaCombination] = useState("");
  const [valueTotalCombinationSet, setValueTotalCombinationSet] = useState("");

  useEffect(() => {
    if (!hadPtorChapterFive) {
      setFormInvalidMsg("");
      setIsFormInvalid(true);
      return;
    }
    validateForm();
  }, [isCombinationBeforeAug2013]);

  useEffect(() => {
    validateForm();
  }, [hadPtorChapterFive]);

  useEffect(() => {
    validateForm();
  }, [
    dayApaPurchased,
    combinationDate,
    totalPropertyCost,
    percentLeftFromCombinationToSoldProperty,
    valueBuildingApaCombination,
    valueTotalCombinationSet
  ]);

  return (
    <div>
      <FormControl>
        <FormLabel>למתי נקבע מועד המכירה של העסקה?</FormLabel>
        <RadioGroup value={isCombinationBeforeAug2013} row onChange={(e) => setIsCombinationBeforeAug2013(e.target.value)}>
          <FormControlLabel value="beforeAug2013" control={<Radio color="primary" />} label="לפני 1.8.2013" />
          <FormControlLabel value="afterAug2013" control={<Radio color="primary" />} label="אחרי 1.8.2013" />
        </RadioGroup>
      </FormControl>

      {isCombinationBeforeAug2013 === "beforeAug2013" ? (
        <FormControl>
          <FormLabel>האם בעסקת הקומבינציה התבקש פטור לפי פרק חמישי 1?</FormLabel>
          <RadioGroup value={hadPtorChapterFive} row onChange={(e) => setHadPtorChapterFive(e.target.value)}>
            <FormControlLabel value="askedPtorChapterFive" control={<Radio color="primary" />} label="כן" />
            <FormControlLabel value="didntAskPtorChapterFive" control={<Radio color="primary" />} label="לא" />
          </RadioGroup>
        </FormControl>
      ) : isCombinationBeforeAug2013 ? (
        <div>{getCombinationFinalInputsOne()}</div>
      ) : null}

      <div>
        {isCombinationBeforeAug2013 === "beforeAug2013" && hadPtorChapterFive ? (
          <div>
            {hadPtorChapterFive === "askedPtorChapterFive" ? (
              <div>{getCombinationFinalInputsTwo()}</div>
            ) : (
              <div>{getCombinationFinalInputsOne()}</div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );

  function getCombinationFinalInputsTwo() {
    validationType = 2;

    return (
      <table className="fs-18" cellSpacing="10">
        <tbody>
          <tr>
            <td className="td-size-200">יום המכירה שנקבע בעסקת הקומבינציה</td>
            <td>
              <TextField type="date" labelWidth={110} onChange={(e) => setCombinationDate(e.target.value)} value={combinationDate} />
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td className="td-size-200">שווי המכירה של הדירה שנקבע בעסקת הקומבינציה</td>
            <td>
              <TextField
                className="ironit-input"
                textFieldWidth={140}
                labelWidth={190}
                onChange={(e) => setValueTotalCombinationSet(e.target.value.replace(/,/g, ""))}
                value={numberWithCommas(valueTotalCombinationSet)}
                currency="₪"
              />
            </td>
            <td>
              {getIlsCurrencyDateBased(combinationDate) && (
                <div className="ironit-currency-label">{getIlsCurrencyDateBased(combinationDate)}</div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function getCombinationFinalInputsOne() {
    validationType = 1;

    return (
      <table className="fs-18" cellSpacing="10">
        <tbody>
          <tr>
            <td className="td-size-200">יום המכירה שנקבע בעסקת הקומבינציה</td>
            <td>
              <TextField type="date" onChange={(e) => setCombinationDate(e.target.value)} value={combinationDate} />
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>יום הרכישה המקורי של הדירה</td>
            <td>
              <TextField type="date" onChange={(e) => setDayApaPurchased(e.target.value)} value={dayApaPurchased} />
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>שווי רכישה מקורי של מלוא המגרש בזמן רכישתו</td>
            <td>
              <TextField
                className="ironit-input"
                onChange={(e) => setTotalPropertyCost(e.target.value.replace(/,/g, ""))}
                value={numberWithCommas(totalPropertyCost)}
              />
            </td>
            <td>
              {getIlsCurrencyDateBased(dayApaPurchased) && (
                <div className="ironit-currency-label">{getIlsCurrencyDateBased(dayApaPurchased)}</div>
              )}
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>החלק באחוזים שנשאר לבעלים מעסקת הקומבינציה היחסי לדירה הנמכרת</td>
            <td>
              <TextField
                className="ironit-input"
                onChange={(e) => setPercentLeftFromCombinationToSoldProperty(e.target.value.replace(/,/g, ""))}
                value={numberWithCommas(percentLeftFromCombinationToSoldProperty)}
              />
            </td>
            <td>%</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>שווי עלויות הבניה בקומבינציה של הדירה הנמכרת</td>
            <td>
              <TextField
                className="ironit-input"
                onChange={(e) => setValueBuildingApaCombination(e.target.value.replace(/,/g, ""))}
                value={numberWithCommas(valueBuildingApaCombination)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function validateForm() {
    if (validationType === 1) {
      if (dayApaPurchased && combinationDate) {
        if (dayApaPurchased > combinationDate) {
          setFormInvalidMsg("תאריך הקומבינציה חייב להיות לאחר תאריך הרכישה המקורי");
          setIsFormInvalid(true);
          return;
        }
        if (dayApaPurchased < MIN_SUPPORTED_DATE || combinationDate < MIN_SUPPORTED_DATE) {
          setFormInvalidMsg("תאריך חייב להיות לאחר " + MIN_SUPPORTED_DATE);
          setIsFormInvalid(true);
          return;
        }
        if (dayApaPurchased > MAX_SUPPORTED_DATE || combinationDate > MAX_SUPPORTED_DATE) {
          setFormInvalidMsg("תאריך חייב להיות לפני " + MAX_SUPPORTED_DATE);
          setIsFormInvalid(true);
          return;
        }
      }

      if (percentLeftFromCombinationToSoldProperty) {
        const getParsedValue = parseInt(percentLeftFromCombinationToSoldProperty);
        if (getParsedValue > 100 || getParsedValue < 1) {
          setFormInvalidMsg("החלק באחוזים שנשאר לבעלים חייב להיות בין 1 ל-100");
          setIsFormInvalid(true);
          return;
        }
      }

      if (
        dayApaPurchased &&
        combinationDate &&
        totalPropertyCost &&
        percentLeftFromCombinationToSoldProperty &&
        valueBuildingApaCombination
      ) {
        if (isCombinationBeforeAug2013 === "beforeAug2013" && combinationDate >= "2013-01-01") {
          setFormInvalidMsg("עסקת הקומבינציה נקבעה לפני שנת 2013. בשל כך שדה 'יום המכירה שנקבע בעסקת הקומבינציה' חייב להיות לפני 2013");
          setIsFormInvalid(true);
          return;
        }

        if (isCombinationBeforeAug2013 === "afterAug2013" && combinationDate < "2013-01-01") {
          setFormInvalidMsg("עסקת הקומבינציה נקבעה לאחר שנת 2013. בשל כך שדה 'יום המכירה שנקבע בעסקת הקומבינציה' חייב להיות אחרי 2013");
          setIsFormInvalid(true);
          return;
        }

        setFormInvalidMsg("");
        setIsFormInvalid(false);
        // purchaseDate, purchaseValue, deductionDate, deductionValue
        setDataToSave({
          purchaseValue: (totalPropertyCost * parseInt(percentLeftFromCombinationToSoldProperty)) / 100,
          purchaseDate: dayApaPurchased,
          deductionValue: valueBuildingApaCombination,
          deductionDate: combinationDate
        });
      } else {
        setFormInvalidMsg("");
        setIsFormInvalid(true);
      }
    } else if (validationType === 2) {
      if (valueTotalCombinationSet && combinationDate) {
        if (combinationDate < MIN_SUPPORTED_DATE) {
          setFormInvalidMsg("תאריך חייב להיות לאחר " + MIN_SUPPORTED_DATE);
          setIsFormInvalid(true);
          return;
        }
        if (combinationDate > MAX_SUPPORTED_DATE) {
          setFormInvalidMsg("תאריך חייב להיות לפני " + MAX_SUPPORTED_DATE);
          setIsFormInvalid(true);
          return;
        }
        if (isCombinationBeforeAug2013 === "beforeAug2013" && combinationDate > "2013-01-01") {
          setFormInvalidMsg("עסקת הקומבינציה נקבעה לפני שנת 2013. בשל כך שדה 'יום המכירה שנקבע בעסקת הקומבינציה' חייב להיות לפני 2013");
          setIsFormInvalid(true);
          return;
        }

        setFormInvalidMsg("");
        setIsFormInvalid(false);
        setDataToSave({
          purchaseValue: valueTotalCombinationSet,
          purchaseDate: combinationDate
        });
      } else {
        setFormInvalidMsg("");
        setIsFormInvalid(true);
      }
    } else {
      setFormInvalidMsg("");
      setIsFormInvalid(true);
    }
  }
}
