import fieldsData, { tabNames } from "./fieldsData";

export const getDocFields = (docTypes) => {
  // #1 merge all fields of needed docs
  let totalNeededFieldsForDocs = [];
  for (const key in docTypes) {
    if (key === "taboAndRMIType" || !docTypes[key]) continue;
    totalNeededFieldsForDocs = totalNeededFieldsForDocs.concat(
      docsObj[key].filter((item) => {
        return !totalNeededFieldsForDocs.includes(item);
      })
    );
  }

  // #2 get all fields data (description)
  let sortedFields = [];
  for (const fieldKey of totalNeededFieldsForDocs) {
    // const temp = fieldsData[fieldKey];
    sortedFields.push(fieldsData[fieldKey]);
  }

  // #3 sort by weight
  sortedFields = sortedFields.sort((a, b) => {
    if (!a.weight) return 1;
    if (!b.weight) return -1;
    return a.weight - b.weight;
  });

  // #4 divide by sections
  const finalResunts = [
    { name: tabNames.SELLERS_INFO, panel: "sellersPanel", questions: [] },
    { name: tabNames.BUYERS_INFO, panel: "buyersPanel", questions: [] },
    { name: tabNames.THIRD_PARTY, panel: "thirdPanel", questions: [] },
    { name: tabNames.ASSET_INFO, panel: "assetPanel", questions: [] },
    { name: tabNames.LAWYER_INFO, panel: "lawyersPanel", questions: [] },
    { name: tabNames.GENERAL, panel: "generalPanel", questions: [] }
  ];

  for (const currentField of sortedFields) {
    const getIndex = finalResunts.findIndex((item) => currentField.belongToTab && item.name === currentField.belongToTab);
    finalResunts[getIndex].questions.push(currentField);
  }

  return finalResunts.filter((item) => item.questions[0]);
};
// eaaratAzharaAllSides: false;
// eaaratAzharaOneSide: false;
// fillDetailFileOnline: false;
// ipuiKoachBuyer: false;
// ipuiKoachEaaratAzhara: false;
// ipuiKoachSeller: false;
// noTaboLeasingRight: false;
// shtarMecher: true;
// taboAndRMIType: "tabo";
// taboCancelLeasingRight: false;
// taboFixLeasingRight: false;
// taboPassingLeasingRight: false;
// taboRegisterLeasingRight: false;
const docsObj = {
  shtarMecher: [
    "lishcatRishumLocation",
    "billNum",
    "retunNoReturn", // this is radio field example
    "sellerFirstName",
    "sellerLastName",
    "sellerUnion",
    "sellerIdType",
    "sellerId",
    "buyerFirstName",
    "buyerLastName",
    "buyerUnion",
    "buyerIdType",
    "buyerId",
    "buyerRightPart",
    "assetCity",
    "blockNum",
    "subBlock",
    "assetArea",
    "sellerPartMekarkein",
    "passPartMekarkein",
    "exchangeAfterSale",
    "assetDescriptionTxt" //=> TODO ask gal.. its writen wrong
    // "approvingThatThisDay",
    // "corporationNum"
  ],
  eaaratAzharaOneSide: [
    "lishcatRishumLocation",
    "seif126",
    "assetCity",
    "blockNum",
    "subBlock",
    "streetAsset",
    "assetStreetNum",
    "assetHouseNum",
    "mustHaveDeal126",
    "sellerFirstName",
    "sellerLastName",
    "sellerUnion",
    "sellerIdType",
    "sellerId",
    "sellerCity",
    "sellerStreet",
    "sellerStreetNum",
    "sellerHouseNum",
    "sellerMahutHazchut",
    "sellerRightParts",
    "buyerFirstName",
    "buyerLastName",
    "buyerUnion",
    "buyerIdType",
    "buyerId",
    "buyerCity",
    "buyerStreet",
    "buyerStreetNum",
    "buyerHouseNum",
    "buyerUnitNum",
    "sellerRepresentLastName",
    "sellerRepresentFirstName",
    "sellerRepresentCity",
    "sellerRepresentStreet",
    "sellerRepresentStreetNum",
    "sellerRepresentHouseNum",
    "sellerRepresentPhone",
    "unionName",
    "corporationNum"
  ],
  eaaratAzharaAllSides: [
    "lishcatRishumLocation",
    "seif126",
    "assetCity",
    "blockNum",
    "subBlock",
    "subSubBlock",
    "streetAsset",
    "assetStreetNum",
    "assetHouseNum",
    "mustHaveDeal126",
    "sellerFirstName",
    "assetFloor",
    "assetDescriptionKivunim",
    "fieldNum",
    "sellerLastName",
    "sellerUnion",
    "sellerIdType",
    "sellerId",
    "sellerCity",
    "sellerStreet",
    "sellerStreetNum",
    "sellerHouseNum",
    "sellerMahutHazchut",
    "sellerRightParts",
    "buyerFirstName",
    "buyerLastName",
    "buyerUnion",
    "buyerIdType",
    "buyerId",
    "buyerCity",
    "buyerStreet",
    "buyerStreetNum",
    "buyerHouseNum",
    "buyerUnitNum",
    "agreementDocument",
    "assetDescriptionTxt",
    // "whatOtherDocument",
    // "otherDoc",
    "sellerRepresentLastName",
    "sellerRepresentFirstName",
    "sellerRepresentCity",
    "sellerRepresentStreet",
    "sellerRepresentStreetNum",
    "sellerRepresentHouseNum",
    "sellerRepresentPhone",
    "unionName",
    "corporationNum",
    "signDate"
  ],
  ipuiKoachBuyer: [
    "buyerLastName",
    "buyerFirstName",
    "buyerIdType",
    "buyerId",
    "sellerLastName",
    "sellerFirstName",
    "sellerIdType",
    "sellerId",
    "buyerRepresentLastName",
    "buyerRepresentFirstName",
    "sellerRepresentLastName",
    "sellerRepresentFirstName",
    "blockNum",
    "subBlock",
    "subSubBlock",
    "streetAsset",
    "assetStreetNum",
    "assetCity",
    "signDate"
  ],
  ipuiKoachSeller: [
    "sellerLastName",
    "sellerFirstName",
    "sellerIdType",
    "sellerId",
    "buyerLastName",
    "buyerFirstName",
    "buyerIdType",
    "buyerId",
    "sellerRepresentLastName",
    "sellerRepresentFirstName",
    "buyerRepresentLastName",
    "buyerRepresentFirstName",
    "blockNum",
    "subBlock",
    "subSubBlock",
    "streetAsset",
    "assetStreetNum",
    "assetCity",
    "signDate"
  ],
  ipuiKoachEaaratAzhara: [
    "buyerLastName",
    "buyerFirstName",
    "buyerIdType",
    "buyerId",
    "sellerLastName",
    "sellerFirstName",
    "sellerIdType",
    "sellerId",
    "sellerRepresentLastName",
    "sellerRepresentFirstName",
    "streetAsset",
    "assetCity",
    "assetStreetNum",
    "blockNum",
    "subBlock",
    "subSubBlock",
    "signDate"
  ],
  noTaboLeasingRight: [
    "lishcatRishumLocation",
    "sellerLastName",
    "sellerFirstName",
    "sellerIdType",
    "sellerId",
    "sellerPartMekarkein",
    "buyerLastName",
    "buyerFirstName",
    "buyerIdType",
    "buyerId",
    "buyerRightPart",
    "assetCity",
    "assetDescriptionTxt",
    "blockNum",
    "subBlock",
    "subSubBlock",
    "assetStreetNum",
    "assetHouseNum",
    "assetArea"
  ],
  // LAST FOUR ARE THE SAME DOC JUST DIFFERENT WITHIN "שטרות מנהל רשום בטאבו"
  taboPassingLeasingRight: [
    "lishcatRishumLocation",
    "assetCity",
    "blockNum",
    "subBlock",
    "subSubBlock",
    "propertyBelongsTo",
    "ramiNum",
    "assetArea",
    "shtarHahira",
    "retunNoReturn",
    "sellerLastName",
    "sellerFirstName",
    "sellerIdType",
    "sellerId",
    "buyerFirstName",
    "buyerLastName",
    "buyerIdType",
    "buyerId",
    "buyerRightPart",
    "sellerPartMekarkein",
    "passPartMekarkein",
    "exchangeAfterSale",
    "assetDescriptionTxt"
  ],
  taboFixLeasingRight: [
    "lishcatRishumLocation",
    "assetCity",
    "blockNum",
    "subBlock",
    "subSubBlock",
    "propertyBelongsTo",
    "ramiNum",
    "assetArea",
    "shtarHahira",
    "fixHahiraTerms",
    "sellerLastName",
    "sellerFirstName",
    "sellerIdType",
    "sellerId",
    "sellerPartMekarkein",
    "passPartMekarkein",
    "exchangeAfterSale",
    "assetDescriptionTxt"
  ],
  taboCancelLeasingRight: [
    "lishcatRishumLocation",
    "assetCity",
    "blockNum",
    "subBlock",
    "subSubBlock",
    "propertyBelongsTo",
    "ramiNum",
    "assetArea",
    "shtarHahira",
    "sellerLastName",
    "sellerFirstName",
    "sellerIdType",
    "sellerId",
    "sellerPartMekarkein",
    "passPartMekarkein",
    "exchangeAfterSale",
    "assetDescriptionTxt"
  ],
  taboRegisterLeasingRight: [
    "lishcatRishumLocation",
    "assetCity",
    "blockNum",
    "subBlock",
    "subSubBlock",
    "propertyBelongsTo",
    "ramiNum",
    "shtarHahira",
    "assetArea",
    "rishumZhutHahira",
    "sellerLastName",
    "sellerFirstName",
    "sellerIdType",
    "sellerId",
    "sellerPartMekarkein",
    "passPartMekarkein",
    "exchangeAfterSale",
    "assetDescriptionTxt"
  ]
};
