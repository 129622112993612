import config from "../config";
import axios from "axios";

const dealsService = {
  preCalcStart: function (preCalc, dealId, isDealDateChanged, mainCalc) {
    return new Promise((resolve) => {
      axios.post(config.preCalcStartEndpoint, { preCalc, mainCalc, dealId, isDealDateChanged }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  preCalcStartDummy: function () {
    return new Promise((resolve) => {
      axios.post(config.preCalcStartEndpoint, { preCalc: this.preCalcDummyData() }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  mainCalcStart: function (data, dealId) {
    return new Promise((resolve) => {
      data.dealId = dealId;
      axios.post(config.mainCalcStartEndpoint, data).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  justPrisaCalc: function (dealId, data) {
    return new Promise((resolve) => {
      axios.post(config.justPrisaCalcEndpoint, { dealId, justPrisa: data }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  initNewDeal: function (dealDescription, softwareServiceType) {
    return new Promise((resolve) => {
      const buildLink = `${config.initNewDealEndpoint}?dealDescription=${dealDescription}&softwareServiceType=${softwareServiceType}`;
      axios.get(buildLink).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  initNewDealWithPayload: function (dealDescription, data) {
    return new Promise((resolve) => {
      axios.post(config.initNewDealWithPayloadEndpoint, { dealDescription, ...data }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAllDeals: function ({ dealPrettyId, dealName, isTotalCount, softwareServiceType, skipAmount }) {
    return new Promise((resolve) => {
      axios
        .get(
          `${config.getAllDealsEndpoint}?dealPrettyId=${dealPrettyId || ""}&dealName=${dealName || ""}&isTotalCount=${
            isTotalCount || ""
          }&skipAmount=${skipAmount || ""}&softwareServiceType=${softwareServiceType || "btax"}`
        )
        .then((serverRes) => {
          if (serverRes.data) return resolve(serverRes.data);
          resolve({ err: "Problem with the request" });
        });
    });
  },

  getDealData: function (dealId) {
    return new Promise((resolve) => {
      axios.get(config.getDealDataEndpoint + "?dealId=" + dealId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  updateDealData: function (dealId, calc, manage, isDealDateChanged) {
    return new Promise((resolve) => {
      axios.post(config.updateDealDataEndpoint, { dealId, calc, manage, isDealDateChanged }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  updateDealJustPrisa: function (dealId, calc, manage) {
    return new Promise((resolve) => {
      axios.post(config.updateDealJustPrisaEndpoint, { dealId, calc, manage }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  copyDeal: function (dealId) {
    return new Promise((resolve) => {
      axios.post(config.copyDealEndpoint, { dealId }).then((serverRes) => {
        console.log(serverRes);
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  deleteDeal: function (dealId) {
    return new Promise((resolve) => {
      axios.delete(config.deleteDealEndpoint + "?dealId=" + dealId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  transferDeal: function (newOwnerEmail, dealIdBeingSharedOrTransferred, dealNewLawyerName) {
    return new Promise((resolve) => {
      axios.post(config.transferDealEndpoint, { newOwnerEmail, dealIdBeingSharedOrTransferred, dealNewLawyerName }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  shareDeal: function (newOwnerEmail, dealIdBeingSharedOrTransferred) {
    return new Promise((resolve) => {
      axios.post(config.shareDealEndpoint, { newOwnerEmail, dealIdBeingSharedOrTransferred }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  cancelShareDeal: function (ownerToRemove, dealIdBeingSharedOrTransferred) {
    return new Promise((resolve) => {
      axios.post(config.cancelShareDealEndpoint, { ownerToRemove, dealIdBeingSharedOrTransferred }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getCalculatedMadad: function (startDate, endDate, amount) {
    return new Promise((resolve) => {
      const buildLink = `${config.getCalculatedMadadEndpoint}?startDate=${startDate}&endDate=${endDate}&amount=${amount}`;
      axios.get(buildLink).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  execute49a: function (dealOne, dealTwo) {
    return new Promise((resolve) => {
      axios.post(config.execute49aEndpoint, { dealOne, dealTwo }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAddresses: function () {
    return new Promise((resolve) => {
      axios.get(config.getAddressesEndpoint, { preventLoading: true }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getLawyersInOffice: () => {
    return new Promise((resolve) => {
      axios.get(config.getLawyersInOfficeEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  isPurchaseDeduction: (deduType) => deduType === "purchase" || deduType === "inheritance" || deduType === "gift",
  // justPrisaDummyData: function () {
  //   return {
  //     finalLinear47: 10000,
  //     finalLinear25: 10000,
  //     finalLinear20: 10000,
  //     inflationAmount10: 10000,
  //     finalMutav25: 10000,
  //     datePropertyBought: new Date("2015-03-15"),
  //     soldPropertyDate: new Date("2020-03-15"),

  //     shouldTryPrisa: true,
  //     personsData: [
  //       {
  //         birthOfClient: new Date("2000-03-15"),
  //         dataPerYear: [
  //           { income: null, zikui: null },
  //           { income: 0, zikui: 0 },
  //           { income: 0, zikui: 0 },
  //           { income: 0, zikui: 0 }
  //         ],
  //         isMaximal: true,
  //         personName: "gal",
  //         personOwningPercent: 0.5
  //       },
  //       {
  //         birthOfClient: new Date("2000-03-15"),
  //         dataPerYear: [
  //           { income: null, zikui: null },
  //           { income: 0, zikui: 0 },
  //           { income: 0, zikui: 0 },
  //           { income: 0, zikui: 0 }
  //         ],
  //         isMaximal: true,
  //         personName: "itay",
  //         personOwningPercent: 0.5
  //       }
  //     ]
  //   };
  // },

  getDeductionTypeOptions: function () {
    return [
      { label: "הוצאה כללית להשבחת הנכס", value: "expenseGeneralImprovement" },
      { label: "הוצאה לרכישת זכות יחודית", value: "expenseUniqueRight" },
      { label: "הוצאות השבחה לנכס סמוך", value: "expenseNearPropertyImprovement" },
      { label: "מס רכישה בקשר לרכישה", value: "purchaseTax" },
      { label: "דמי תיווך ברכישת הזכות", value: "purchaseMediator" },
      { label: "דמי תיווך במכירת הזכות", value: "soldMediator" },
      { label: 'שכ"ט עו"ד ברכישה ומכירה', value: "expenseLawyerSoldAndBought" },
      { label: "מס רכוש עד 31/3/72", value: "masRehushUpTo72April" },
      { label: "מס רכוש 31/12/85 - 1/4/72", value: "masRehushFrom72AprilTo86" },
      { label: "מס רכוש החל משנת 1986", value: "masRehushFrom86" },
      { label: "דמי הסכמה / הוון", value: "expenseAgreement" },
      { label: "דמי עמילות", value: "expenseAmilot" },
      { label: "היטל השבחה", value: "eitelAshbaha" },
      { label: "הוצאות נותן המתנה", value: "expenseGifter" },
      { label: "הוצאה בקשר להליכי שומה", value: "expenseShumaProcess" },
      { label: "תשלום למס מכירה", value: "taxSold" },
      { label: "ריבית ריאלית", value: "interest" },
      { label: "הוצאה בגין פינוי", value: "expenseEvacuation" },
      { label: 'שכ"ט שמאי / מודד', value: "expenseAssesor" },
      { label: "הוצאה לשם הוכחת זכות", value: "expenseProvingRight" }
    ];
  }
};

export default dealsService;

function buildGetReqUrl(endpoint, querys = {}) {
  //build url from endpoint and querys (obj like - {searchKey:"ab",client:"john"})
  const urlQueryPart = `${Object.entries(querys)
    .filter((entry) => !!entry[1] || entry[1] === "" || entry[1] === 0)
    .map((entry) => `${entry[0]}=${entry[1]}`)
    .join("&")}`;
  const url = `${endpoint}?${urlQueryPart}`;
  return url;
}
