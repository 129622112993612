import React, { Component, Fragment } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import DescriptionIcon from "@material-ui/icons/Description";
import SaveIcon from "@material-ui/icons/Save";
import ChooseDocs from "./chooseDocs";
import "../../../assets/css/docs.css";
import * as customColors from "../../../theme/customColors";
import QuestionsPage from "./questionsPage";
import DocsHistory from "./docsHistory";

class DealDocs extends Component {
  render() {
    let content;
    if (this.props.currentStep === 1) {
      content = <ChooseDocs {...this.props} />;
    } else if (this.props.currentStep === 2) {
      content = <QuestionsPage {...this.props} />;
    } else if (this.props.currentStep === 3) {
      content = <DocsHistory {...this.props} />;
    }
    return (
      <Fragment>
        <Grid container direction="column">
          <Grid item>
            <Box height="16px" />
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Grid item xs={4}>
                <Box
                  onClick={() => this.props.changeDocsStep(1)}
                  border={1}
                  borderBottom={this.props.currentStep === 1 ? 0 : 1}
                  p={1}
                  borderColor={customColors.gray}
                  bgcolor={this.props.currentStep === 1 ? customColors.lightGray : null}
                  style={{ cursor: "pointer" }}
                >
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <MessageIcon color="secondary" />
                    </Grid>
                    <Grid item>
                      <Typography>בחירת טפסים</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  onClick={() => (this.props.isFillDocumnetsAllowed ? this.props.changeDocsStep(2) : null)}
                  border={1}
                  borderBottom={this.props.currentStep === 2 ? 0 : 1}
                  p={1}
                  borderColor={customColors.gray}
                  bgcolor={this.props.currentStep === 2 ? customColors.lightGray : null}
                  style={{ cursor: this.props.isFillDocumnetsAllowed ? "pointer" : "default" }}
                >
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <DescriptionIcon color="secondary" />
                    </Grid>
                    <Grid item>
                      <Typography color={this.props.isFillDocumnetsAllowed ? "textPrimary" : "textSecondary"}>מילוי טפסים</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  onClick={() => this.props.changeDocsStep(3)}
                  border={1}
                  borderBottom={this.props.currentStep === 3 ? 0 : 1}
                  p={1}
                  borderColor={customColors.gray}
                  bgcolor={this.props.currentStep === 3 ? customColors.lightGray : null}
                  style={{ cursor: "pointer" }}
                >
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <SaveIcon color="secondary" />
                    </Grid>
                    <Grid item>
                      <Typography>טפסים שהופקו</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box border={1} borderTop={0} paddingTop={3} p={1} borderColor={customColors.gray} style={{ marginTop: "-33px" }}>
              {content}
            </Box>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default DealDocs;
