import React, { Component } from "react";
import { Divider, Grid, FormControl, FormControlLabel, Typography, RadioGroup, Radio, Button, Box } from "@material-ui/core";
import CustomDialog from "../../../layout/CustomDialog";
import * as customColors from "../../../../theme/customColors";
import Popup from "../../../../assets/images/popup.png";

// Original optional props:
// open
// onClose

// Extended optional props:
// handleResults - force the result.

class IsInheritancePtorAllowedDialog extends Component {
  state = {
    isSellOfAllTheProperty: true,
    isTestatorIsForeignResident: false,
    isTestatorOnlyPropertyInStateOfResidence: false,
    isTestatorRelatedToSeller: true,
    isTestatorHadOnePropertyBeforeDeath: true,
    isTestatorShouldHadPtorMasShevah: true,
    isChecked: false,
    isZakai: false
  };

  render() {
    return (
      <CustomDialog label="בדיקת זכאות לדירה בירושה" open={this.props.open} onClose={this.props.onClose}>
        <Grid container direction="column" spacing={1}>
          <Grid container item direction="row" wrap="nowrap">
            <Grid container item direction="column" xs={8} spacing={1}>
              <Grid item>
                <FormControl component="fieldset">
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body2">האם המכירה הינה של כל זכויות המוכר בדירה?</Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isSellOfAllTheProperty).toString()}
                        row
                        name="isSellOfAllTheProperty"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset" disabled={!this.state.isSellOfAllTheProperty}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography color={!this.state.isSellOfAllTheProperty ? "textSecondary" : "textPrimary"} variant="body2">
                        האם המוריש (נותן הירושה) הינו תושב חוץ?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isTestatorIsForeignResident).toString()}
                        row
                        name="isTestatorIsForeignResident"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset" disabled={!this.state.isSellOfAllTheProperty || !this.state.isTestatorIsForeignResident}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          !this.state.isSellOfAllTheProperty || !this.state.isTestatorIsForeignResident ? "textSecondary" : "textPrimary"
                        }
                        variant="body2"
                      >
                        האם ברשות המוכר הוכחה רשמית מהרשויות במדינת תושבותו של המוריש כי אין בבעלות המוריש דירת מגורים במדינת תושבותו?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isTestatorOnlyPropertyInStateOfResidence).toString()}
                        row
                        name="isTestatorOnlyPropertyInStateOfResidence"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={
                    !this.state.isSellOfAllTheProperty ||
                    (this.state.isTestatorIsForeignResident && !this.state.isTestatorOnlyPropertyInStateOfResidence)
                  }
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          !this.state.isSellOfAllTheProperty ||
                          (this.state.isTestatorIsForeignResident && !this.state.isTestatorOnlyPropertyInStateOfResidence)
                            ? "textSecondary"
                            : "textPrimary"
                        }
                        variant="body2"
                      >
                        האם המוכר הינו: בן זוגו של המוריש, או צאצא של המוריש (בן או נכד), או בן זוגו של צאצא של המוריש?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isTestatorRelatedToSeller).toString()}
                        row
                        name="isTestatorRelatedToSeller"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={
                    !this.state.isSellOfAllTheProperty ||
                    (this.state.isTestatorIsForeignResident && !this.state.isTestatorOnlyPropertyInStateOfResidence)
                  }
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          !this.state.isSellOfAllTheProperty ||
                          (this.state.isTestatorIsForeignResident && !this.state.isTestatorOnlyPropertyInStateOfResidence)
                            ? "textSecondary"
                            : "textPrimary"
                        }
                        variant="body2"
                      >
                        האם המוריש היה בעליה של דירה אחת לפני פטירתו?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isTestatorHadOnePropertyBeforeDeath).toString()}
                        row
                        name="isTestatorHadOnePropertyBeforeDeath"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={
                    !this.state.isSellOfAllTheProperty ||
                    (this.state.isTestatorIsForeignResident && !this.state.isTestatorOnlyPropertyInStateOfResidence)
                  }
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          !this.state.isSellOfAllTheProperty ||
                          (this.state.isTestatorIsForeignResident && !this.state.isTestatorOnlyPropertyInStateOfResidence)
                            ? "textSecondary"
                            : "textPrimary"
                        }
                        variant="body2"
                      >
                        אילו היה המוריש בחיים ומוכר את הדירה האם הוא היה פטור ממס שבח?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isTestatorShouldHadPtorMasShevah).toString()}
                        row
                        name="isTestatorShouldHadPtorMasShevah"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={this.isZakai}>
                  בדוק זכאות
                </Button>
              </Grid>
            </Grid>
            <Grid container item direction="column" xs={4} spacing={2} justify="flex-start" alignItems="center">
              <Grid item>
                <img alt="popup" src={Popup} width="200px" height="150px" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box width="100%" bgcolor={customColors.darkPurple} p={0.5}>
              <Typography variant="h6" style={{ color: customColors.white }}>
                {`החלטת המערכת: ${this.state.isChecked ? (this.state.isZakai ? "זכאי" : "לא זכאי") : ""}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomDialog>
    );
  }

  isZakai = () => {
    let isZakai = true;
    if (!this.state.isSellOfAllTheProperty) isZakai = false;
    else if (this.state.isTestatorIsForeignResident && !this.state.isTestatorOnlyPropertyInStateOfResidence) isZakai = false;
    else if (
      !this.state.isTestatorRelatedToSeller ||
      !this.state.isTestatorHadOnePropertyBeforeDeath ||
      !this.state.isTestatorShouldHadPtorMasShevah
    )
      isZakai = false;
    this.setState({ isZakai, isChecked: true });
    // Return results to the preCalc
    if (this.props.handleResults) this.props.handleResults({ target: { value: isZakai, name: "isInheritancePtorAllowed" } });
  };

  handleOnChange(e) {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const newState = { ...this.state, [e.target.name]: val };
    this.handleDependencies(e, newState);
    this.setState(newState);
  }

  handleDependencies(e, newState) {
    if (e.target.name === "isSellOfAllTheProperty") {
      if (e.target.value === "false") {
        newState.isTestatorIsForeignResident = false;
        newState.isTestatorOnlyPropertyInStateOfResidence = false;
        newState.isTestatorRelatedToSeller = true;
        newState.isTestatorHadOnePropertyBeforeDeath = true;
        newState.isTestatorShouldHadPtorMasShevah = true;
      }
    } else if (e.target.name === "isTestatorIsForeignResident") {
      newState.isTestatorOnlyPropertyInStateOfResidence = false;
      newState.isTestatorRelatedToSeller = true;
      newState.isTestatorHadOnePropertyBeforeDeath = true;
      newState.isTestatorShouldHadPtorMasShevah = true;
    } else if (e.target.name === "isTestatorOnlyPropertyInStateOfResidence") {
      if (e.target.value === "false") {
        newState.isTestatorRelatedToSeller = true;
        newState.isTestatorHadOnePropertyBeforeDeath = true;
        newState.isTestatorShouldHadPtorMasShevah = true;
      }
    }
  }
}

export default IsInheritancePtorAllowedDialog;
