import React, { Component } from "react";
import pdfFile from "../../assets/images/TermsAndConditions.pdf";
import {
  Card,
  CardContent,
  Button,
  Checkbox,
  Typography,
  Grid,
  Box,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";
import PdfViewer from "../layout/pdfViewer";
import TextFieldWithIcon from "../layout/textFieldWithIcon";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { setLoaderOn, setLoaderOff } from "../../store/actions/appSettingActions";
import logo from "../../assets/images/logo.png";
import config from "../../config";
import usersService from "../../services/usersService";
import Swal from "sweetalert2";
import { validateEmail } from "../../services/utils/utils";
import * as customColors from "../../theme/customColors";

class PendingRegister extends Component {
  state = {
    currentStepNum: 1,
    hasAgreedToTerms: false,

    officeName: "",
    officeAddress: "",
    officePhone: "",
    firstName: "",
    lastName: "",
    licenseNumber: "",
    email: "",
    password: "",
    passRepeat: "",
    mobile: ""
  };

  constructor() {
    super();
    this.reRef = React.createRef();
  }

  render() {
    return (
      <div className="container-sm mb-40">
        <ReCAPTCHA sitekey={config.reCaptchaKey} size="invisible" ref={this.reRef} />

        <Card>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box width="100%" height="70px" bgcolor={customColors.darkBlue} display="flex" alignItems="center" justifyContent="center">
                  <Typography align="center" variant="h4" style={{ color: customColors.white }}>
                    רישום למערכת ביטקס
                  </Typography>
                </Box>
              </Grid>

              {this.state.currentStepNum === 1 ? (
                <>
                  <Grid item>
                    <PdfViewer pdfFile={pdfFile} />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      style={{ marginRight: "0" }}
                      labelPlacement="end"
                      control={
                        <Checkbox
                          color="primary"
                          onClick={() => {
                            this.setState({ hasAgreedToTerms: !this.state.hasAgreedToTerms });
                          }}
                          checked={this.state.hasAgreedToTerms}
                        />
                      }
                      label='אני מאשר כי קראתי את התקנון, אני מסכים לכל האמור בו ונותן בזאת הסכמתי לקבלת דיוור ישיר לכתובת הדואר האלקטרוני או באמצעות
                    מסרון. ראה/י סעיף בתקנון שכותרתו "הסכמה לקבלת דבר פרסומת"'
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      style={{ width: "130px" }}
                      variant="contained"
                      color="secondary"
                      onClick={() => this.props.history.push("/sessions/login")}
                    >
                      חזור
                    </Button>
                    <Button
                      style={{ width: "130px", float: "left" }}
                      variant="contained"
                      color="secondary"
                      disabled={!this.state.hasAgreedToTerms}
                      onClick={this.isAllowedMoveToStepTwo}
                    >
                      המשך
                    </Button>
                    {/* </div> */}
                  </Grid>
                </>
              ) : // </div>
              this.state.currentStepNum === 2 ? (
                <Grid item>
                  <div className="mt-30">
                    <TextFieldWithIcon
                      value={this.state.officeName}
                      onChange={this.onChange}
                      onKeyPress={(e) => this.handleOnKeyPress(e)}
                      name="officeName"
                      iconName="person"
                      placeholder="שם משרד"
                    />
                    <TextFieldWithIcon
                      value={this.state.officeAddress}
                      onChange={this.onChange}
                      onKeyPress={(e) => this.handleOnKeyPress(e)}
                      name="officeAddress"
                      iconName="home"
                      placeholder="כתובת משרד"
                    />
                    <TextFieldWithIcon
                      value={this.state.officePhone}
                      onChange={this.onChange}
                      onKeyPress={(e) => this.handleOnKeyPress(e)}
                      name="officePhone"
                      iconName="phone"
                      placeholder="טלפון משרד"
                    />
                    <div className="mt-10 mb-10">
                      <div className="inline-block ml-20">
                        <TextFieldWithIcon
                          value={this.state.firstName}
                          onChange={this.onChange}
                          onKeyPress={(e) => this.handleOnKeyPress(e)}
                          name="firstName"
                          iconName="person"
                          placeholder="שם פרטי"
                        />
                      </div>
                      <div className="inline-block">
                        <TextFieldWithIcon
                          value={this.state.lastName}
                          onChange={this.onChange}
                          onKeyPress={(e) => this.handleOnKeyPress(e)}
                          name="lastName"
                          iconName="person"
                          placeholder="שם משפחה"
                        />
                      </div>
                    </div>
                    <div className="mt-10 mb-10">
                      <div className="inline-block relative">
                        <TextFieldWithIcon
                          value={this.state.licenseNumber}
                          onChange={this.onChange}
                          onKeyPress={(e) => this.handleOnKeyPress(e)}
                          name="licenseNumber"
                          iconName="horizontal_split"
                          placeholder="מספר רישיון"
                        />
                      </div>

                      <div className="inline-block relative mr-60 align-job-type-icon">
                        {this.state.clientJobTitle ? null : <div className="prof-con">מקצוע</div>}
                        <span className="text-field-with-icon" style={{ background: customColors.orange }}>
                          <i className="material-icons">engineering</i>
                        </span>
                        <FormControl margin="dense" style={{ marginTop: 4, minWidth: 326 }}>
                          <Select
                            size="small"
                            variant="outlined"
                            name="clientJobTitle"
                            value={this.state.clientJobTitle}
                            onChange={(e) => this.onChange(e)}
                            displayEmpty
                          >
                            {usersService.getClientJobOptions().map((item) => {
                              return (
                                <MenuItem key={item.key} value={item.key}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <TextFieldWithIcon
                      value={this.state.email}
                      onChange={this.onChange}
                      onKeyPress={(e) => this.handleOnKeyPress(e)}
                      name="email"
                      iconName="person"
                      placeholder='דוא"ל'
                    />
                    <TextFieldWithIcon
                      value={this.state.password}
                      onChange={this.onChange}
                      onKeyPress={(e) => this.handleOnKeyPress(e)}
                      name="password"
                      iconName="lock"
                      type="password"
                      placeholder="סיסמה"
                    />
                    <TextFieldWithIcon
                      value={this.state.passRepeat}
                      onChange={this.onChange}
                      onKeyPress={(e) => this.handleOnKeyPress(e)}
                      name="passRepeat"
                      iconName="lock"
                      type="password"
                      placeholder="אישור סיסמה"
                    />
                    <TextFieldWithIcon
                      value={this.state.mobile}
                      onChange={this.onChange}
                      onKeyPress={(e) => this.handleOnKeyPress(e)}
                      name="mobile"
                      iconName="phone"
                      placeholder="טלפון סלולרי"
                    />

                    <div className="mt-30">
                      <Button
                        style={{ width: "130px" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => this.setState({ currentStepNum: 1 })}
                      >
                        חזור
                      </Button>

                      <Button style={{ width: "130px", float: "left" }} variant="contained" color="secondary" onClick={this.createAcc}>
                        צור חשבון
                      </Button>
                    </div>
                  </div>
                </Grid>
              ) : (
                <div>
                  <h1 className="primary-color center">חשבונך הוסף בהצלחה</h1>
                  <div className="center">
                    <img src={logo} alt="logo" />
                  </div>
                  <h2 className="center">אנו מודים לך על ההרשמה</h2>
                  <h2 className="center">מישהו מצוות BTax יחזור אליך בקרוב</h2>

                  <div className="center mt-30 mb-20">
                    <Button
                      style={{ width: "150px" }}
                      variant="contained"
                      color="secondary"
                      onClick={() => this.props.history.push("/sessions/login")}
                    >
                      מעבר להתחברות
                    </Button>
                  </div>
                </div>
              )}
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  isAllowedMoveToStepTwo = () => {
    // if (this.state.hasAgreedToTerms) {
    this.setState({ currentStepNum: 2 });
    // }
  };

  handleOnKeyPress(e) {
    if (e.key === "Enter") {
      this.createAcc();
    }
  }

  createAcc = async () => {
    const data = this.getRegistrationData();
    if (!data) return;

    // recaptcha
    var captchaToken = null;
    this.props.setLoaderOn();

    // captchaToken = await this.reRef.current.executeAsync();
    this.reRef.current.reset();

    data.captchaToken = captchaToken;
    usersService.pendingRegister(data).then((res) => {
      if (res["success"]) {
        if (res.userPending) {
          Swal.fire("לקוח יקר", "פנייתך הינה כבר בטיפולנו. ניתן ליצור קשר עם שירות הלקוחות שלנו במידת הצורך", "success");
        } else if (res.userActive) {
          Swal.fire("", ' דוא"ל קיים במערכת', "warning");
        } else if (res.pendingCreated) {
          Swal.fire("", "בקשתך להתחברות למערכת התקבלה בהצלחה וניצור איתך קשר בהקדם", "success");
        }
      }
    });
  };

  getRegistrationData = () => {
    const state = this.state;

    if (!validateEmail(state.email)) {
      Swal.fire("", 'פורמט דוא"ל לא חוקי', "warning");
      return;
    }

    if (!state.officeName || !state.officeAddress) {
      Swal.fire("", "שם משרד וכתובת משרד הינם שדות חובה", "warning");
      return;
    }

    if (!state.firstName || !state.lastName) {
      Swal.fire("", "שם פרטי ושם משפחה הינם שדות חובה", "warning");
      return;
    }

    if (state.officePhone.length < 8 || state.mobile.length < 8) {
      Swal.fire("", "הוזן מספר טלפון לא חוקי של המשרד או הטלפון הסלולרי", "warning");
      return;
    }

    if (state.password.length < 6) {
      Swal.fire("", "לפחות 6 תווים חובה לסיסמה", "warning");
      return;
    }

    if (state.password !== state.passRepeat) {
      Swal.fire("", "הסיסמה ואישור הסיסמה אינן תואמות", "warning");
      return;
    }

    if (!state.clientJobTitle) {
      Swal.fire("", "יש להזין מקצוע", "warning");
      return;
    }

    const data = {
      officeName: state.officeName,
      officeAddress: state.officeAddress,
      officePhone: state.officePhone,
      firstName: state.firstName,
      lastName: state.lastName,
      licenseNumber: state.licenseNumber,
      clientJobTitle: state.clientJobTitle,
      email: state.email,
      password: state.password,
      mobile: state.mobile
    };

    return data;
  };
}

const mapDispatchToProps = (dispatch, userLoginData) => {
  return {
    setLoaderOn: () => dispatch(setLoaderOn()),
    setLoaderOff: () => dispatch(setLoaderOff())
  };
};

export default connect(null, mapDispatchToProps)(PendingRegister);
