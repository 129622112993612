import React, { useState, useRef } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import ReplyIcon from "@material-ui/icons/Reply";
import CheckIcon from "@material-ui/icons/Check";
import Swal from "sweetalert2";

export default function MoveDealMenus(props) {
  const {
    officeLawyers,
    dealSharingOptionsEvent,
    setDealSharingOptionsEvent,
    shareDeal,
    cancelShareDeal,
    transferDeal,
    dealIdBeingSharedOrTransferred,
    dealsPrettyIdAndDesc
  } = props;

  const [lawyersAnchorEl, setLawyersAnchorEl] = useState(null);

  const nestedMenuType = useRef();

  const handleCloseDealSharingOptions = () => {
    setDealSharingOptionsEvent(null);
  };

  const handleOpenLawyersMenu = (event, newNestedMenuType) => {
    nestedMenuType.current = newNestedMenuType;
    setLawyersAnchorEl(event.currentTarget);
  };

  const handleCloseLawyersMenu = (e) => {
    setLawyersAnchorEl(null);
  };

  const handleChangeLawyerOwnershipOrShareDeal = (newOwnerEmail, dealNewLawyerName, isDealSharedWithLawyer) => {
    if (nestedMenuType.current === "transferMenu") {
      Swal.fire({
        title: "העברת עסקה",
        text: `להעביר עסקה לעורך הדין - ${dealNewLawyerName}?`,
        confirmButtonText: "כן",
        cancelButtonText: "לא",
        icon: "info",
        showCancelButton: true
      }).then((swalResult) => {
        if (swalResult.value) {
          transferDeal(newOwnerEmail, dealIdBeingSharedOrTransferred.dealId, dealNewLawyerName).then(() => {
            handleCloseLawyersMenu();
            handleCloseDealSharingOptions();
          });
        }
      });
    } else {
      // sharingMenu
      if (isDealSharedWithLawyer) {
        // Shared already.. stop sharing?
        Swal.fire({
          title: "הפסקת שיתוף עסקה",
          text: `להפסיק את שיתוף העסקה עם עורך הדין - ${dealNewLawyerName}?`,
          confirmButtonText: "כן",
          cancelButtonText: "לא",
          icon: "info",
          showCancelButton: true
        }).then((swalResult) => {
          if (swalResult.value) {
            cancelShareDeal(newOwnerEmail, dealIdBeingSharedOrTransferred.dealId);
          }
        });
      } else {
        Swal.fire({
          title: "שיתוף עסקה",
          text: `לשתף עסקה עם עורך הדין - ${dealNewLawyerName}?`,
          confirmButtonText: "כן",
          cancelButtonText: "לא",
          icon: "info",
          showCancelButton: true
        }).then((swalResult) => {
          if (swalResult.value) {
            shareDeal(newOwnerEmail, dealIdBeingSharedOrTransferred.dealId);
          }
        });
      }
    }
  };

  const isDealSharingOptionsOpen = Boolean(dealSharingOptionsEvent);
  const isLawyersMenuOpen = Boolean(lawyersAnchorEl);

  const getLawyerList = () => {
    if (!dealIdBeingSharedOrTransferred || !officeLawyers) return [];
    // Transfer can be done to anyone whos not owning the deal already.
    if (nestedMenuType.current === "transferMenu") {
      return officeLawyers
        .filter((officeLawyer) => officeLawyer.email !== dealIdBeingSharedOrTransferred.belongToEmail)
        .map((officeLawyer) => {
          delete officeLawyer.isDealSharedWithLawyer;
          return officeLawyer;
        });
    } else {
      // Deal can be shared with anyone thats not owning the deal already
      return officeLawyers
        .filter((officeLawyer) => officeLawyer.email !== dealIdBeingSharedOrTransferred.belongToEmail)
        .map((officeLawyer) => {
          const currentDeal = dealsPrettyIdAndDesc.find((currentDeal) => currentDeal._id === dealIdBeingSharedOrTransferred.dealId);
          if (currentDeal.listOfSharedLawyers?.includes(officeLawyer.email)) {
            officeLawyer.isDealSharedWithLawyer = true;
          } else {
            officeLawyer.isDealSharedWithLawyer = false;
          }
          return officeLawyer;
        });
    }
  };

  const lawyerList = getLawyerList();

  return (
    <>
      <Menu
        id="sharing-menu"
        anchorEl={dealSharingOptionsEvent}
        open={isDealSharingOptionsOpen}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseDealSharingOptions}
        MenuListProps={{
          "aria-labelledby": "sharing-button"
        }}
      >
        <MenuItem onClick={(e) => handleOpenLawyersMenu(e, "sharingMenu")}>
          שיתוף עסקה
          <ShareIcon className="mr-15 blue-color" />
        </MenuItem>
        <MenuItem onClick={(e) => handleOpenLawyersMenu(e, "transferMenu")}>
          העברת עסקה
          <ReplyIcon className="mr-10 blue-color" />
        </MenuItem>
      </Menu>

      <Menu
        id="lawyers-menu"
        anchorEl={lawyersAnchorEl}
        open={isLawyersMenuOpen}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handleCloseLawyersMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {/* Im removing current "owning" email. i cant change deal to myself pretty much */}
        {lawyerList.map((officeLawyer) => (
          <MenuItem
            key={officeLawyer._id}
            onClick={() =>
              handleChangeLawyerOwnershipOrShareDeal(
                officeLawyer.email,
                `${officeLawyer.firstName} ${officeLawyer.lastName}`,
                officeLawyer.isDealSharedWithLawyer
              )
            }
          >
            {`${officeLawyer.firstName} ${officeLawyer.lastName}`}

            {officeLawyer.isDealSharedWithLawyer && <CheckIcon className="mr-10 success-color" />}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
