import { SET_LOGGED_IN, LOGOUT, TOGGLE_REMINDERS_STATE } from "./actionTypes";

export const setLoggedIn = (userDataToSet) => {
  return (dispatch, getstate) => {
    return new Promise((resolve) => {
      const role = userDataToSet["role"];
      if (role) localStorage.setItem("role", role);

      if (userDataToSet["areRemindersOn"]) localStorage.setItem("areRemindersOn", userDataToSet["areRemindersOn"]);
      if (userDataToSet.shouldRememberEmail) localStorage.setItem("emailForLogin", userDataToSet.email);
      else localStorage.removeItem("emailForLogin");

      localStorage.setItem("token", userDataToSet["token"]);
      localStorage.setItem("email", userDataToSet["email"]);
      localStorage.setItem("jobType", userDataToSet["jobType"]);
      localStorage.setItem("isLoggedin", "true");
      localStorage.setItem("fullName", userDataToSet["fullName"]);
      localStorage.setItem("belongToCardNum", userDataToSet["belongToCardNum"]);
      localStorage.setItem("abilities", userDataToSet["abilities"]);
      localStorage.setItem("softwareServiceType", userDataToSet["softwareServiceType"]);
      if (userDataToSet["newStationName"]) localStorage.setItem("stationName", userDataToSet["newStationName"]);

      dispatch({ type: SET_LOGGED_IN, payload: userDataToSet });
      return resolve();
    });
  };
};

export const setLoggedOut = () => {
  return (dispatch, getstate) => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("lastName");
    localStorage.removeItem("firstName");
    localStorage.removeItem("fullName");
    localStorage.removeItem("isLoggedin");
    localStorage.removeItem("role");
    localStorage.removeItem("belongToCardNum");
    localStorage.removeItem("abilities");
    localStorage.removeItem("softwareServiceType");
    localStorage.removeItem("areRemindersOn");

    dispatch({ type: LOGOUT });
    window.location.href = "/sessions/login";
  };
};

export const toggleReminderState = (newState) => {
  return (dispatch, getstate) => {
    localStorage.setItem("areRemindersOn", newState);
    dispatch({ type: TOGGLE_REMINDERS_STATE, payload: newState });
  };
};
