import { connect } from "react-redux";

const AccessibilityProvider = ({ children, screenZoomLevel, fontSizeLevel, lineHeightLevel, letterSpaceLevel, linkHighlightLevel, mouseCursorLevel }) => {
   document.body.className = '';

  if (screenZoomLevel) {
    document.body.classList.add(screenZoomLevel);
  }
  if (fontSizeLevel) {
    document.body.classList.add(fontSizeLevel);
  }
  if (lineHeightLevel) {
    document.body.classList.add(lineHeightLevel);
  }
  if (letterSpaceLevel) {
    document.body.classList.add(letterSpaceLevel);
  }
  if (linkHighlightLevel) {
    document.body.classList.add(linkHighlightLevel);
  }
  if (mouseCursorLevel) {
    document.body.classList.add(mouseCursorLevel);
  }
  
  return <div>{children}</div>;
};

const mapStateToProps = (state) => {
  return {
    screenZoomLevel: state.appSettingsReducer.screenZoomLevel,
    fontSizeLevel: state.appSettingsReducer.fontSizeLevel,
    lineHeightLevel: state.appSettingsReducer.lineHeightLevel,
    letterSpaceLevel: state.appSettingsReducer.letterSpaceLevel,
    linkHighlightLevel: state.appSettingsReducer.linkHighlightLevel,
    mouseCursorLevel: state.appSettingsReducer.mouseCursorLevel
  };
};

export default connect(mapStateToProps)(AccessibilityProvider);
