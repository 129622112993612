import React, { Component, Fragment } from "react";
import { Divider, Grid, FormControl, FormControlLabel, Typography, RadioGroup, Radio, Button, Box } from "@material-ui/core";
import CustomDialog from "../../../layout/CustomDialog";
import * as customColors from "../../../../theme/customColors";
import Popup from "../../../../assets/images/popup.png";
import config from "../../../../config";
// Original optional props:
// open
// onClose

// Extended optional props:
// handleResults - force the result.
// isBefore2018

class IsSinglePropertyPtorAllowedDialog extends Component {
  state = {
    isSellOfAllTheProperty: true,
    isSellerIsForeignResident: false,
    isSellerHasAnotherPropertyInStateOfResidence: false,
    hasProofOfOnlyPropertyInStateOfResidence: false,
    isOnlyPropertyOfSellerFamily: true,
    isSellerSoldWithOnlyPropertyPtor: false,
    isSellerHasRightFor18Months: true,
    isSellerHadOntherPropertyIn2014: false,
    isPropertyExcludedBefore: false,
    isChecked: false,
    isZakai: false
  };

  render() {
    return (
      <CustomDialog label="בדיקת זכאות לפטור דירה יחידה, לפי סעיף 49ב(2)" open={this.props.open} onClose={this.props.onClose} maxWidth="md">
        <Grid container direction="column" spacing={1}>
          <Grid container item direction="row" wrap="nowrap">
            <Grid container item direction="column" xs={8} spacing={1}>
              <Grid item>
                <FormControl component="fieldset">
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body2">האם המכירה הינה של כל זכויות המוכר בדירה?</Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isSellOfAllTheProperty).toString()}
                        row
                        name="isSellOfAllTheProperty"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset" disabled={!this.state.isSellOfAllTheProperty}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography color={!this.state.isSellOfAllTheProperty ? "textSecondary" : "textPrimary"} variant="body2">
                        האם המוכר תושב חוץ?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isSellerIsForeignResident).toString()}
                        row
                        name="isSellerIsForeignResident"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset" disabled={!this.state.isSellOfAllTheProperty || !this.state.isSellerIsForeignResident}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          !this.state.isSellOfAllTheProperty || !this.state.isSellerIsForeignResident ? "textSecondary" : "textPrimary"
                        }
                        variant="body2"
                      >
                        האם ברשותו דירת מגורים נוספת במדינת תושבותו?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isSellerHasAnotherPropertyInStateOfResidence).toString()}
                        row
                        name="isSellerHasAnotherPropertyInStateOfResidence"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={
                    !this.state.isSellOfAllTheProperty ||
                    !this.state.isSellerIsForeignResident ||
                    this.state.isSellerHasAnotherPropertyInStateOfResidence
                  }
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          !this.state.isSellOfAllTheProperty ||
                          !this.state.isSellerIsForeignResident ||
                          this.state.isSellerHasAnotherPropertyInStateOfResidence
                            ? "textSecondary"
                            : "textPrimary"
                        }
                        variant="body2"
                      >
                        האם ברשותו הוכחה רשמית מהרשויות במדינת תושבותו, כי אין ברשותו דירת מגורים במדינת תושבותו?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.hasProofOfOnlyPropertyInStateOfResidence).toString()}
                        row
                        name="hasProofOfOnlyPropertyInStateOfResidence"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset" disabled={!this.state.isSellOfAllTheProperty}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body2">האם הדירה הנמכרת הינה דירה יחידה של התא המשפחתי של המוכר?</Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isOnlyPropertyOfSellerFamily).toString()}
                        row
                        name="isOnlyPropertyOfSellerFamily"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset" disabled={!this.state.isSellOfAllTheProperty}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body2">
                        אם המוכר מכר דירה אחרת "בפטור דירה יחידה לפי סעיף 49ב'(2)" במהלך ה- 18 חודשים שקדמו למכירה?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isSellerSoldWithOnlyPropertyPtor).toString()}
                        row
                        name="isSellerSoldWithOnlyPropertyPtor"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset" disabled={!this.state.isSellOfAllTheProperty}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body2">האם המוכר הוא בעל הזכות בדירה הנמכרת במשך 18 חודשים לפחות ממועד השלמת בנייתה?</Typography>
                      <Typography variant="body2">
                        (בדירת ירושה תילקח בחשבון התקופה בה החזיק המוריש בדירה מיום היותה דירת מגורים מזכה).
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isSellerHasRightFor18Months).toString()}
                        row
                        name="isSellerHasRightFor18Months"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              {this.props.isBefore2018 ? (
                <Fragment>
                  <Grid item>
                    <Divider light />
                  </Grid>
                  <Grid item>
                    <FormControl component="fieldset" disabled={!this.state.isSellOfAllTheProperty}>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="body2">האם ב 1/1/2014 היתה למוכר דירה נוספת מעבר לדירה הנמכרת?</Typography>
                          <Typography variant="body2">לענין דירה נוספת או חלק ממנה ראה הבהרה מצד שמאל.</Typography>
                        </Grid>
                        <Grid item>
                          <RadioGroup
                            value={Boolean(this.state.isSellerHadOntherPropertyIn2014).toString()}
                            row
                            name="isSellerHadOntherPropertyIn2014"
                            onChange={(e) => this.handleOnChange(e)}
                          >
                            <Grid container direction="row" spacing={2}>
                              <Grid item>
                                <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                              </Grid>
                              <Grid item>
                                <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Fragment>
              ) : null}
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset" disabled={!this.state.isSellOfAllTheProperty}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body2">
                        האם הדירה הנמכרת הוחרגה בעבר מהגדרת "דירה יחידה" לפי סעיף 49 ג' (2) או (3), במסגרת מכירת דירת מגורים אחרת שנמכרה
                        בפטור דירה יחידה לפי סעיף 49ב'(2)?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isPropertyExcludedBefore).toString()}
                        row
                        name="isPropertyExcludedBefore"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={this.isZakai}>
                  בדוק זכאות
                </Button>
              </Grid>
            </Grid>
            <Grid container item direction="column" xs={4} justify="flex-start" alignItems="center">
              <Grid item>
                <img alt="popup" src={Popup} width="200px" height="150px" />
              </Grid>
              <Grid item>
                <Box p={1}>
                  <Box borderColor="grey.500" border={1} bgcolor="grey.300" p={1}>
                    <Typography variant="body2" paragraph style={{ fontWeight: "bold" }}>
                      הבהרה:
                    </Typography>
                    <Typography variant="body2" paragraph>
                      יכול שתהיה לאדם דירה נוספת ו/או חלק מדירה ואף על זאת תיחשב הדירה הנמכרת כדירתו היחידה בהתאם לאמור בסעיף 49ג לחוק מיסוי
                      מקרקעין וזאת במידה והדירה הנוספת נכללת במסגרת החריגים הקבועים בחוק כדלקמן:
                    </Typography>
                    <Typography variant="body2" paragraph>
                      (1) הדירה הנוספת נירכשה כתחליף לדירה הנמכרת {config.apaBoughtAsReplacementToSoldApaAmountOfMonths} חודשים טרם המכירה.
                    </Typography>
                    <Typography variant="body2" paragraph>
                      (2) הדירה הנוספת הושכרה למגורים בשכירות מוגנת לפני יום כ"ב בטבת התשנ"ז (1 בינואר 1997).
                    </Typography>
                    <Typography variant="body2" paragraph>
                      (3) חלקו של המוכר בדירה הנוספת אינו עולה על 1/3.
                    </Typography>
                    <Typography variant="body2" paragraph>
                      (4) הדירה הנוספת הינה דירה שהתקבלה בירושה והתקיימו בה תנאי סעיף 49ב(5)(א) ו-(ב) לחוק.
                    </Typography>
                    <Typography variant="body2" paragraph>
                      (5) הדירה הנוספת הינה דירה שהתקבלה בירושה וחלקו של המוכר בה אינו עולה על 50% וזאת ללא קשר לזהות המוריש ולמניין דירות
                      המוריש ביום פטירתו.
                    </Typography>
                    <Typography variant="body2" paragraph>
                      (6) הדירה הנוספת הינה דירה המשמשת למגורים או מיועדת למגורים לפי טיבה שהיא בבעלותו של איגוד, ולמוכר יש באמצעות זכויותיו
                      באיגוד, במישרין או בעקיפין, חלק שאינו עולה על שליש בבעלות בה, ולגבי מוכר שירש את זכויותיו באיגוד - חלק שאינו עולה על
                      מחצית בבעלות בה.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box width="100%" bgcolor={customColors.darkPurple} p={0.5}>
              <Typography variant="h6" style={{ color: customColors.white }}>
                {`החלטת המערכת: ${this.state.isChecked ? (this.state.isZakai ? "זכאי" : "לא זכאי") : ""}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomDialog>
    );
  }

  isZakai = () => {
    let isZakai = true;
    if (!this.state.isSellOfAllTheProperty) isZakai = false;
    else if (this.state.isSellerIsForeignResident && this.state.isSellerHasAnotherPropertyInStateOfResidence) isZakai = false;
    else if (
      this.state.isSellerIsForeignResident &&
      !this.state.isSellerHasAnotherPropertyInStateOfResidence &&
      !this.state.hasProofOfOnlyPropertyInStateOfResidence
    )
      isZakai = false;
    else if (
      !this.state.isOnlyPropertyOfSellerFamily ||
      this.state.isSellerSoldWithOnlyPropertyPtor ||
      !this.state.isSellerHasRightFor18Months ||
      this.state.isSellerHadOntherPropertyIn2014 ||
      this.state.isPropertyExcludedBefore
    )
      isZakai = false;
    this.setState({ isZakai, isChecked: true });
    // Return results to the preCalc
    if (this.props.handleResults) this.props.handleResults({ target: { value: isZakai, name: "isSinglePropertyPtorAllowed" } });
  };

  handleOnChange(e) {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const newState = { ...this.state, [e.target.name]: val };
    this.handleDependencies(e, newState);
    this.setState(newState);
  }

  handleDependencies(e, newState) {
    if (e.target.name === "isSellOfAllTheProperty") {
      if (e.target.value === "false") {
        newState.isSellerIsForeignResident = false;
        newState.isSellerHasAnotherPropertyInStateOfResidence = false;
        newState.hasProofOfOnlyPropertyInStateOfResidence = false;
        newState.isOnlyPropertyOfSellerFamily = true;
        newState.isSellerSoldWithOnlyPropertyPtor = false;
        newState.isSellerHasRightFor18Months = true;
        newState.isSellerHadOntherPropertyIn2014 = false;
        newState.isPropertyExcludedBefore = false;
      }
    } else if (e.target.name === "isSellerIsForeignResident") {
      if (e.target.value === "false") {
        newState.isSellerHasAnotherPropertyInStateOfResidence = false;
        newState.hasProofOfOnlyPropertyInStateOfResidence = false;
      }
    } else if (e.target.name === "isSellerHasAnotherPropertyInStateOfResidence") {
      if (e.target.value === "true") {
        newState.hasProofOfOnlyPropertyInStateOfResidence = false;
      }
    }
  }
}

export default IsSinglePropertyPtorAllowedDialog;
