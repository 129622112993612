import React, { Component } from "react";
import { Divider, Grid, FormControl, FormControlLabel, Typography, RadioGroup, Radio, Button, Box } from "@material-ui/core";
import CustomDialog from "../../../layout/CustomDialog";
import LabeledTextField from "../../../layout/LabeledTextField";
import * as customColors from "../../../../theme/customColors";
import Popup from "../../../../assets/images/popup.png";

// Original optional props:
// open
// onClose

// Extended optional props:
// handleResults - force the result.

class IsMutavAllowedDialog extends Component {
  state = {
    propertyGotBefore2013: true,
    isSellerUsedMasMutavBefore: false,
    sellerUsedMasMutavAmount: 1,
    sellerSellAnotherPropertyWithPtor: false,
    isBuyetRelatedToSeller: false,
    isSoldAsHalukatIzavon: false,
    isChecked: false,
    isZakai: false
  };

  render() {
    return (
      <CustomDialog label="בדיקת זכאות מס מוטב סעיף 48א(ב2)" open={this.props.open} onClose={this.props.onClose}>
        <Grid container direction="column" spacing={1}>
          <Grid container item direction="row" wrap="nowrap">
            <Grid container item direction="column" xs={8} spacing={1}>
              <Grid item>
                <FormControl component="fieldset">
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body2">האם הדירה התקבלה לפני יום 1/8/2013?</Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.propertyGotBefore2013).toString()}
                        row
                        name="propertyGotBefore2013"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="body2">האם המוכר עשה שימוש בעבר בפטור מס מוטב?</Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isSellerUsedMasMutavBefore).toString()}
                        row
                        name="isSellerUsedMasMutavBefore"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid container item direction="column">
                <Grid item>
                  <Typography color={!this.state.isSellerUsedMasMutavBefore ? "textSecondary" : "textPrimary"} variant="body2">
                    כמה פעמים השתמש המוכר בפטור מס מוטב?
                  </Typography>
                </Grid>
                <Grid item>
                  <LabeledTextField
                    disabled={!this.state.isSellerUsedMasMutavBefore}
                    type="select"
                    name="sellerUsedMasMutavAmount"
                    textFieldWidth={100}
                    onChange={(e) => this.handleOnChange(e)}
                    value={this.state.sellerUsedMasMutavAmount}
                    options={[
                      { value: 1, label: 1 },
                      { value: 2, label: 2 }
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={!this.state.isSellerUsedMasMutavBefore || this.state.sellerUsedMasMutavAmount === 2}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          !this.state.isSellerUsedMasMutavBefore || this.state.sellerUsedMasMutavAmount === 2
                            ? "textSecondary"
                            : "textPrimary"
                        }
                        variant="body2"
                      >
                        האם המוכר מכר דירה אחרת בפטור ממס, לרבות פטור חלקי (הוראת שעה), נתן דירה במתנה למי שאינו ילדו או בן זוגו, בארבע
                        השנים שקדמו למכירת הדירה?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.sellerSellAnotherPropertyWithPtor).toString()}
                        row
                        name="sellerSellAnotherPropertyWithPtor"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={this.state.sellerSellAnotherPropertyWithPtor || this.state.sellerUsedMasMutavAmount === 2}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          this.state.sellerSellAnotherPropertyWithPtor || this.state.sellerUsedMasMutavAmount === 2
                            ? "textSecondary"
                            : "textPrimary"
                        }
                        variant="body2"
                      >
                        האם מכירת הדירה כיום בתמורה או שלא בתמורה הינה לקרוב של המוכר כדלהלן?
                      </Typography>
                      <Typography
                        color={
                          this.state.sellerSellAnotherPropertyWithPtor || this.state.sellerUsedMasMutavAmount === 2
                            ? "textSecondary"
                            : "textPrimary"
                        }
                        variant="body2"
                      >
                        (1) בן-זוג;
                      </Typography>
                      <Typography
                        color={
                          this.state.sellerSellAnotherPropertyWithPtor || this.state.sellerUsedMasMutavAmount === 2
                            ? "textSecondary"
                            : "textPrimary"
                        }
                        variant="body2"
                      >
                        (2) הורה, הורי הורה, צאצא, צאצאי בן-זוג ובני-זוגם של כל אחד מאלה;
                      </Typography>
                      <Typography
                        color={
                          this.state.sellerSellAnotherPropertyWithPtor || this.state.sellerUsedMasMutavAmount === 2
                            ? "textSecondary"
                            : "textPrimary"
                        }
                        variant="body2"
                      >
                        (3) אח או אחות ובני-זוגם;
                      </Typography>
                      <Typography
                        color={
                          this.state.sellerSellAnotherPropertyWithPtor || this.state.sellerUsedMasMutavAmount === 2
                            ? "textSecondary"
                            : "textPrimary"
                        }
                        variant="body2"
                      >
                        (4) איגוד שהוא בשליטתו;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isBuyetRelatedToSeller).toString()}
                        row
                        name="isBuyetRelatedToSeller"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={!this.state.isBuyetRelatedToSeller || this.state.sellerUsedMasMutavAmount === 2}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          !this.state.isBuyetRelatedToSeller || this.state.sellerUsedMasMutavAmount === 2 ? "textSecondary" : "textPrimary"
                        }
                        variant="body2"
                      >
                        האם הנכס נמכר כחלק מחלוקת עזבון?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isSoldAsHalukatIzavon).toString()}
                        row
                        name="isSoldAsHalukatIzavon"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={this.isZakai}>
                  בדוק זכאות
                </Button>
              </Grid>
            </Grid>
            <Grid container item direction="column" xs={4} spacing={2} justify="flex-start" alignItems="center">
              <Grid item>
                <img alt="popup" src={Popup} width="200px" height="150px" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box width="100%" bgcolor={customColors.darkPurple} p={0.5}>
              <Typography variant="h6" style={{ color: customColors.white }}>
                {`החלטת המערכת: ${this.state.isChecked ? (this.state.isZakai ? "זכאי" : "לא זכאי") : ""}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomDialog>
    );
  }

  isZakai = () => {
    let isZakai = true;
    if (this.state.sellerUsedMasMutavAmount === 2) isZakai = false;
    else if (this.state.sellerSellAnotherPropertyWithPtor) isZakai = false;
    else if (this.state.isBuyetRelatedToSeller && !this.state.isSoldAsHalukatIzavon) isZakai = false;
    this.setState({ isZakai, isChecked: true });
    // Return results to the preCalc
    if (this.props.handleResults) this.props.handleResults({ target: { value: isZakai, name: "isMutavAllowed" } });
  };

  handleOnChange(e) {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const newState = { ...this.state, [e.target.name]: val };
    this.handleDependencies(e, newState);
    this.setState(newState);
  }

  handleDependencies(e, newState) {
    if (e.target.name === "isSellerUsedMasMutavBefore") {
      if (e.target.value === "false") {
        newState.sellerUsedMasMutavAmount = 1;
        newState.sellerSellAnotherPropertyWithPtor = false;
        newState.isBuyetRelatedToSeller = false;
        newState.isSoldAsHalukatIzavon = false;
      }
    } else if (e.target.name === "sellerUsedMasMutavAmount") {
      if (e.target.value === 2) {
        newState.sellerSellAnotherPropertyWithPtor = false;
        newState.isBuyetRelatedToSeller = false;
        newState.isSoldAsHalukatIzavon = false;
      }
    } else if (e.target.name === "sellerSellAnotherPropertyWithPtor") {
      if (e.target.value === "true") {
        newState.isBuyetRelatedToSeller = false;
        newState.isSoldAsHalukatIzavon = false;
      }
    } else if (e.target.name === "isBuyetRelatedToSeller") {
      if (e.target.value === "false") {
        newState.isSoldAsHalukatIzavon = false;
      }
    }
  }
}

export default IsMutavAllowedDialog;
