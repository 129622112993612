import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import { getDocFields } from "../../../services/docsService/docsMerger";
import SectionPanel from "./sectionPanel";
import filesService from "../../../services/docsService/filesService";
import Swal from "sweetalert2";

// Multipliers // Irrelevant
// var SELLER_SECTION = "פרטי המוכרים";
// var BUYER_SECTION = "פרטי הקונים";
// var THIRD_SECTION = "צד שלישי";

const MAX_QUESTION_GROUP_DUPLICATE = 3;

class QuestionsPage extends Component {
  state = {
    dealId: "",
    docTypes: {},
    complexQuestions: {}, // Using for convert radio type questions.
    panelsData: [],
    sellersPanel: [],
    buyersPanel: [],
    thirdPanel: [],
    assetPanel: [],
    lawyersPanel: [],
    generalPanel: []
  };

  componentDidMount() {
    const dealId = this.props.match.params.dealId;
    const panelsData = getDocFields(this.props.docTypes);
    const panels = panelsData.reduce((panels, panel) => {
      panels[panel.panel] = [
        panel.questions.reduce((questions, question) => {
          questions[question.name] = question.type === "number" ? null : "";
          return questions;
        }, {})
      ];
      return panels;
    }, {});
    const complexQuestions = {};
    for (const panel of panelsData) {
      for (const question of panel.questions) {
        if (question.type === "radio") {
          complexQuestions[question.name] = question.options.reduce((obj, opt) => {
            obj[opt.value] = true;
            return obj;
          }, {});
        }
      }
    }
    // Get previous fields of this deal.
    filesService.getDocsFields(dealId).then((res) => {
      if (res.success && res.data) {
        for (const panelName in panels) {
          const getCurPanelQuestions = panelsData[panelsData.findIndex((x) => x.panel === panelName)].questions;
          const getCurPanelRadios = getCurPanelQuestions.filter((x) => x.type === "radio");

          for (const key in panels[panelName][0]) {
            if (res.data[key]) {
              panels[panelName][0][key] = res.data[key];
              // Check if there are duplicates.
              for (let i = 2; i <= MAX_QUESTION_GROUP_DUPLICATE; i++) {
                if (res.data[key + i.toString()]) {
                  if (!panels[panelName][i - 1]) {
                    // Duplicate questions.
                    panels[panelName].push(
                      panelsData
                        .find((item) => item.panel === panelName)
                        .questions.reduce((a, b) => {
                          return { ...a, [b.name]: b.text === "number" ? null : "" };
                        }, {})
                    );
                  }
                  // TODO - here is the problem
                  panels[panelName][i - 1][key] = res.data[key + i.toString()];
                }
              }
            } else {
              const getRadioData = this.getRadioFieldData(key, getCurPanelRadios);
              if (getRadioData) {
                for (const curOption of getRadioData.options) {
                  if (res.data[curOption.value]) {
                    panels[panelName][0][key] = curOption.value;
                    // Check if there are duplicates.
                    for (let i = 2; i <= MAX_QUESTION_GROUP_DUPLICATE; i++) {
                      if (res.data[curOption.value + i.toString()]) {
                        if (!panels[panelName][i - 1]) {
                          // Duplicate questions.
                          panels[panelName].push(
                            panelsData
                              .find((item) => item.panel === panelName)
                              .questions.reduce((a, b) => {
                                return { ...a, [b.name]: b.text === "number" ? null : "" };
                              }, {})
                          );
                        }
                        panels[panelName][i - 1][key] = curOption.value; //res.data[key + i.toString()];
                      }
                    }
                    break;
                  }
                }
              }
            }
          }
        }
      }
      this.setState({ ...panels, panelsData, docTypes: this.props.docTypes, dealId, complexQuestions });
    });
  }

  render() {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            {this.state.panelsData.map((item, panelIndex) => {
              return (
                <Grid item key={`${item.panel}-${panelIndex}`}>
                  <SectionPanel
                    panelState={this.state[item.panel]}
                    sectionName={item.name}
                    panel={item.panel}
                    questions={item.questions}
                    handleOnChange={this.handleOnChange}
                    panelIndex={panelIndex}
                    addDuplicateQuestions={this.addDuplicateQuestions}
                    removeDuplicateQuestions={this.removeDuplicateQuestions}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ minWidth: "120px" }}
                endIcon={<CreateNewFolderIcon style={{ marginRight: "8px" }} />}
                onClick={() => this.generateDocs()}
              >
                הפק טפסים
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ minWidth: "120px" }}
                endIcon={<SaveIcon style={{ marginRight: "8px" }} />}
                onClick={() => this.updateDocsFields()}
              >
                שמור
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  handleOnChange = (e, panel, index) => {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const newPanel = { ...this.state[panel][index], [e.target.name]: val };
    const panelPanel = [...this.state[panel]];
    panelPanel[index] = newPanel;
    this.setState({ [panel]: panelPanel });
  };

  addDuplicateQuestions = (panelName) => {
    const panelData = this.state.panelsData.find((item) => item.panel === panelName);
    const questions = panelData.questions.reduce((a, b) => {
      return { ...a, [b.name]: b.text === "number" ? null : "" };
    }, {});
    const dupsArray = [...this.state[panelName]];
    dupsArray.push(questions);
    this.setState({ [panelName]: dupsArray });
  };

  removeDuplicateQuestions = (panelName, index) => {
    const dupsArray = [...this.state[panelName]];
    this.setState({ [panelName]: dupsArray.filter((item, i) => i !== index) });
  };

  parseAllQuestions() {
    const results = {};
    for (const key in this.state) {
      if (key.endsWith("Panel")) {
        for (let i = 0; i < this.state[key].length; i++) {
          const questionGroup = this.state[key][i];
          for (const question in questionGroup) {
            if (this.state.complexQuestions[question]) {
              results[questionGroup[question] + (i > 0 ? `${i + 1}` : "")] = this.state.complexQuestions[question][questionGroup[question]];
            } else results[question + (i > 0 ? `${i + 1}` : "")] = questionGroup[question];
          }
        }
      }
    }
    return results;
  }

  generateDocs() {
    const fields = this.parseAllQuestions();
    const docTypes = [];
    for (const key in this.state.docTypes) {
      if (typeof this.state.docTypes[key] === "boolean" && this.state.docTypes[key]) docTypes.push(key);
    }
    filesService
      .generateDocs(docTypes, fields, this.state.dealId)
      .catch(() => Swal.fire("היתה בעיה ביצירת המסמך", "במידה ובעיה זו ממשיכה בבקשה צרו איתנו קשר", "warning"));
  }

  updateDocsFields() {
    const fields = this.parseAllQuestions();
    filesService.updateDocsFields(this.state.dealId, fields).then((res) => {});
  }

  getRadioFieldData = (key, getCurPanelRadios) => {
    for (const curRadioQuestion of getCurPanelRadios) {
      if (curRadioQuestion.name === key) return curRadioQuestion;
    }
    return false;
  };
}

export default withRouter(QuestionsPage);
