import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Divider, Grid, FormControl, FormControlLabel, Checkbox, Button, Typography, Radio, RadioGroup, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SaveIcon from "@material-ui/icons/Save";
import GetAppIcon from "@material-ui/icons/GetApp";
import CalculatorIcon from "../../layout/CalculatorIcon";
import CustomAcordion from "../../layout/CustomAcordion";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CustomLink from "../../layout/CustomLink";
import LabeledTextField from "../../layout/LabeledTextField";
import helpTexts from "../../../assets/texts/helpTexts";
import HelpDialog from "../../layout/HelpDialog";
import HasRabinovichDialog from "./dialogs/hasRabinovichDialog";
import DepreciationCalculatorDialog from "./dialogs/depreciationCalculatorDialog";
import PrisaComponent from "../../shared/prisaComponent";
import { getAmountOptions } from "../../../services/utils/utils";
import fileService from "../../../services/docsService/filesService";
import { AttachFile } from "@material-ui/icons";
import Swal from "sweetalert2";
import filesService from "../../../services/docsService/filesService";
import dealsService from "../../../services/dealsService";
import config from "../../../config";

class DealMainCalc extends Component {
  state = {
    dialogs: {
      dialogPart: 0,
      hasRabinovichDialogOpen: false,
      depreciationCalculatorDialogOpen: false
    },
    isManualOptionsShown:
      this.props.overrideTkufaPercent && Object.values(this.props.overrideTkufaPercent).some((val) => val) ? true : false
  };

  render() {
    console.log(this.props);
    const purchaseParts = this.props.deductions.map((deduction, index) => {
      let content;
      if (deduction.deduType === "purchase") {
        const currencyOpts = this.getCurrencyOptions(deduction.deduDate);
        content = (
          <CustomAcordion summaryText={`פרטי רכישה מספר ${index + 1}`} defaultExpanded={true}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container direction="row" spacing={5} alignItems="center">
                  <Grid item>
                    <LabeledTextField
                      type="date"
                      label="תאריך הרכישה"
                      name="deduDate"
                      textFieldWidth={170}
                      onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                      value={deduction.deduDate}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={1} alignItems="center">
                      <Grid item>
                        <LabeledTextField
                          type="number"
                          label="סכום הרכישה במטבע המקורי"
                          name="amount"
                          textFieldWidth={140}
                          helpDialog={helpTexts.purchaseAmountInOriginalCurrency}
                          onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                          value={deduction.amount}
                        />
                      </Grid>
                      <Grid item>
                        <LabeledTextField
                          type="select"
                          name="deduCurrency"
                          textFieldWidth={110}
                          onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                          value={deduction.deduCurrency}
                          options={currencyOpts}
                          background
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {deduction.deduDate < "2004-01-01" && deduction.deduDate > "2001-11-06" ? (
                <Fragment>
                  <Grid item>
                    <Divider light />
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth component="fieldset">
                      <Grid container direction="column" alignItems="flex-start">
                        <Grid item>
                          <FormControlLabel
                            labelPlacement="end"
                            control={
                              <Checkbox
                                checked={deduction.hasRabinovich}
                                value={!deduction.hasRabinovich}
                                onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                                name="hasRabinovich"
                                color="primary"
                              />
                            }
                            label="מס האם זכאי להנחה במס שבח, בגין רכישה שבוצעה בתקופה הקובעת, לפי סעיף 48א(ד1)?"
                          />
                        </Grid>
                        <Grid item>
                          <CustomLink
                            label="בדוק זכאות"
                            onClick={() => {
                              this.openDialog("hasRabinovichDialogOpen", index);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Fragment>
              ) : null}
            </Grid>
          </CustomAcordion>
        );
      } else if (deduction.deduType === "inheritance") {
        const propertyPurchaseDate = this.props.getPurchaseDate(deduction);
        const currencyOpts = this.getCurrencyOptions(propertyPurchaseDate);
        content = (
          <CustomAcordion summaryText={`פרטי רכישה מספר ${index + 1}`} defaultExpanded={true}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container direction="row" spacing={5} alignItems="center">
                  <Grid item>
                    <LabeledTextField
                      type="date"
                      label="תאריך פטירת המוריש"
                      name="testatorPassingDate"
                      textFieldWidth={170}
                      onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                      value={deduction.testatorPassingDate}
                    />
                  </Grid>
                  {deduction.testatorPassingDate >= "1981-04-01" ? (
                    <Grid item>
                      <LabeledTextField
                        type="date"
                        label="תאריך הרכישה של המוריש"
                        name="testatorBoughtPropertyDate"
                        textFieldWidth={170}
                        onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                        value={deduction.testatorBoughtPropertyDate}
                      />
                    </Grid>
                  ) : null}
                  <Grid item>
                    {deduction.testatorPassingDate ? (
                      <Grid container direction="row" spacing={1} alignItems="center">
                        <Grid item>
                          <LabeledTextField
                            type="number"
                            label={
                              deduction.testatorPassingDate >= "1981-04-01"
                                ? "סכום הרכישה של המוריש במטבע המקורי"
                                : 'שווי הרכישה ביום פטירת המוריש, במטבע המקורי, עפי דו"ח העזבון'
                            }
                            name="amount"
                            helpDialog={helpTexts.purchaseAmountInOriginalCurrency}
                            textFieldWidth={140}
                            onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                            value={deduction.amount}
                          />
                        </Grid>
                        <Grid item>
                          <LabeledTextField
                            type="select"
                            name="deduCurrency"
                            textFieldWidth={110}
                            onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                            value={deduction.deduCurrency}
                            options={currencyOpts}
                            background
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              {deduction.testatorBoughtPropertyDate < "2004-01-01" && deduction.testatorBoughtPropertyDate > "2001-11-06" ? (
                <Fragment>
                  <Grid item>
                    <Divider light />
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth component="fieldset">
                      <Grid container direction="column" alignItems="flex-start">
                        <Grid item>
                          <FormControlLabel
                            labelPlacement="end"
                            control={
                              <Checkbox
                                checked={deduction.hasRabinovich}
                                value={!deduction.hasRabinovich}
                                onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                                name="hasRabinovich"
                                color="primary"
                              />
                            }
                            label="מס האם זכאי להנחה במס שבח, בגין רכישה שבוצעה בתקופה הקובעת, לפי סעיף 48א(ד1)?"
                          />
                        </Grid>
                        <Grid item>
                          <CustomLink
                            label="בדוק זכאות"
                            onClick={() => {
                              this.openDialog("hasRabinovichDialogOpen", index);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Fragment>
              ) : null}
            </Grid>
          </CustomAcordion>
        );
      } else if (deduction.deduType === "gift") {
        const currencyOpts = this.getCurrencyOptions(deduction.deduDate);
        content = (
          <CustomAcordion summaryText={`פרטי רכישה מספר ${index + 1}`} defaultExpanded={true}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container direction="row" spacing={5} alignItems="center">
                  <Grid item>
                    <LabeledTextField
                      type="date"
                      label="תאריך הרכישה של נותן המתנה"
                      name="deduDate"
                      textFieldWidth={170}
                      onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                      value={deduction.deduDate}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={1} alignItems="center">
                      <Grid item>
                        <LabeledTextField
                          type="number"
                          label="סכום הרכישה של נותן המתנה במטבע המקורי"
                          name="amount"
                          textFieldWidth={140}
                          helpDialog={helpTexts.purchaseAmountInOriginalCurrency}
                          onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                          value={deduction.amount}
                        />
                      </Grid>
                      <Grid item>
                        <LabeledTextField
                          type="select"
                          name="deduCurrency"
                          textFieldWidth={110}
                          onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                          value={deduction.deduCurrency}
                          options={currencyOpts}
                          background
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {deduction.deduDate < "2004-01-01" && deduction.deduDate > "2001-11-06" ? (
                <Fragment>
                  <Grid item>
                    <Divider light />
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth component="fieldset">
                      <Grid container direction="column" alignItems="flex-start">
                        <Grid item>
                          <FormControlLabel
                            labelPlacement="end"
                            control={
                              <Checkbox
                                checked={deduction.hasRabinovich}
                                value={!deduction.hasRabinovich}
                                onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                                name="hasRabinovich"
                                color="primary"
                              />
                            }
                            label="מס האם זכאי להנחה במס שבח, בגין רכישה שבוצעה בתקופה הקובעת, לפי סעיף 48א(ד1)?"
                          />
                        </Grid>
                        <Grid item>
                          <CustomLink
                            label="בדוק זכאות"
                            onClick={() => {
                              this.openDialog("hasRabinovichDialogOpen", index);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Fragment>
              ) : null}
            </Grid>
          </CustomAcordion>
        );
      }
      return content ? (
        <Grid key={`purchse-details-${index + 1}`} item>
          {content}
        </Grid>
      ) : null;
    });

    const depreciations = (
      <Grid item>
        <CustomAcordion className="relative" summaryText="ניכויים" defaultExpanded={true}>
          <div className="import-deductions-con">
            <label htmlFor="deduction-template">
              <span className="pointer rotate45" style={{ verticalAlign: "-5px" }}>
                העלאת ניכויים
                <AttachFile />
              </span>
            </label>

            <input
              id="deduction-template"
              style={{ visibility: "hidden", position: "absolute" }}
              type="file"
              onChange={this.onUploadDeductionFile}
            />

            <span
              onClick={() =>
                fileService.downloadDeductionTemplate(() => {
                  Swal.fire("לא ניתן להוריד את הקובץ כרגע", "יש לנסות מאוחר יותר", "warning");
                })
              }
              className="pointer"
              style={{ verticalAlign: "-5px" }}
            >
              הורד קובץ ניכויים
              <GetAppIcon />
            </span>
          </div>
          <Grid container direction="column" spacing={2}>
            {this.props.deductions.map((deduction, index) => {
              let propertyPurchaseDate;
              if (deduction.deduType === "inheritance") {
                propertyPurchaseDate = this.props.getPurchaseDate(deduction);
                //   deduction.testatorPassingDate >= "1981-04-01" ? deduction.testatorBoughtPropertyDate : deduction.testatorPassingDate;
              } else {
                propertyPurchaseDate = deduction.deduDate;
              }
              const isPurchaseDeduction = dealsService.isPurchaseDeduction(deduction.deduType);
              const deduTypeOpts = dealsService.getDeductionTypeOptions();
              const deduCurrencyOpts = this.getCurrencyOptions(propertyPurchaseDate);
              const depCurrencyOpts = this.getCurrencyOptions(propertyPurchaseDate, deduction.depreciation);
              const attributionOpts = this.getAtrributionOptions();
              const belongToPartsOpts = getAmountOptions(this.props.preCalc.preCalcPartsData.length);

              return (
                <Fragment key={"temp" + index}>
                  <Grid container item direction="row" spacing={1}>
                    <Grid item>
                      {isPurchaseDeduction ? (
                        <LabeledTextField type="text" disabled={true} value="רכישה" name="deduType" label={`ניכוי (${index + 1})`} />
                      ) : (
                        <LabeledTextField
                          type="select"
                          value={deduction.deduType}
                          name="deduType"
                          label={`ניכוי (${index + 1})`}
                          onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                          options={deduTypeOpts}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <LabeledTextField
                        type="date"
                        disabled={isPurchaseDeduction}
                        value={propertyPurchaseDate}
                        textFieldWidth={170}
                        name="deduDate"
                        label="תאריך"
                        onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                      />
                    </Grid>
                    <Grid item>
                      <LabeledTextField
                        type="number"
                        disabled={isPurchaseDeduction}
                        value={deduction.amount}
                        textFieldWidth={120}
                        name="amount"
                        label="סכום"
                        onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                      />
                    </Grid>
                    <Grid item>
                      <LabeledTextField
                        type="select"
                        disabled={isPurchaseDeduction}
                        value={deduction.deduCurrency}
                        textFieldWidth={110}
                        background
                        name="deduCurrency"
                        options={deduCurrencyOpts}
                        onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                      />
                    </Grid>
                    {this.props.preCalc.sellerType === "single" && this.props.preCalc.propertyType === "dmm" ? (
                      <Grid item>
                        <LabeledTextField
                          type="select"
                          disabled={isPurchaseDeduction}
                          value={deduction.attribution}
                          textFieldWidth={120}
                          name="attribution"
                          options={attributionOpts}
                          label="ייחוס"
                          onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                        />
                      </Grid>
                    ) : null}
                    {this.props.preCalc.stepsBought > 1 ? (
                      <Grid item>
                        <LabeledTextField
                          type="select"
                          disabled={isPurchaseDeduction}
                          multiple
                          value={deduction.belongToParts}
                          textFieldWidth={120}
                          name="belongToParts"
                          options={belongToPartsOpts}
                          label="חלק רכישה"
                          onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                        />
                      </Grid>
                    ) : null}
                    <Grid item>
                      <LabeledTextField
                        type="number"
                        disabled={true}
                        value={
                          deduction.depreciation && Number.isFinite(deduction.depreciation.depreciationResult)
                            ? deduction.depreciation.depreciationResult
                            : ""
                        }
                        textFieldWidth={120}
                        name="depreciationResult"
                        label="פחת"
                        onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                      />
                    </Grid>
                    <Grid item>
                      <LabeledTextField
                        type="select"
                        disabled
                        value={deduction.depreciation.depCurrency || deduction.deduCurrency}
                        textFieldWidth={110}
                        background
                        name="depCurrency"
                        options={depCurrencyOpts}
                        onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                      />
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <CalculatorIcon
                            fontSize="large"
                            color="secondary"
                            style={{
                              cursor: !deduction.amount || !propertyPurchaseDate ? "default" : "pointer",
                              verticalAlign: "middle"
                            }}
                            title={!deduction.amount || !propertyPurchaseDate ? "" : "מחשבון פחת"}
                            onClick={() => {
                              if (!deduction.amount || !propertyPurchaseDate) return;
                              this.openDialog("depreciationCalculatorDialogOpen", index);
                            }}
                          />
                          <div className="phat-title">(מחשבון פחת)</div>
                        </Grid>
                        <Grid item>
                          <HelpDialog label="פחת" helpDialog={helpTexts.depreciation} />
                        </Grid>

                        {!isPurchaseDeduction && (
                          <>
                            {(this.props.preCalc.howPropertyIsSold === "partOfPropertySold" ||
                              this.props.preCalc.howPropertyIsSold === "combination") && (
                              <Grid item>
                                <Tooltip title="בסימון אפשרות זו, ההוצאה תנוכה באופן מלא ולא באופן יחסי">
                                  <Checkbox
                                    checked={deduction.applyFullDeductionAmount || false}
                                    onChange={(e) =>
                                      this.props.handleMainCalcDeductionOnChange(
                                        { target: { value: e.target.checked, name: "applyFullDeductionAmount" } },
                                        index
                                      )
                                    }
                                    color="primary"
                                  />
                                </Tooltip>
                              </Grid>
                            )}
                            <Grid item>
                              <Tooltip title="מחק">
                                <DeleteIcon
                                  fontSize="small"
                                  style={{ cursor: "pointer", verticalAlign: "middle" }}
                                  onClick={() => this.props.removeMainCalcDeduction(index)}
                                />
                              </Tooltip>
                            </Grid>

                            {(deduction.isInsertDeductionAsNegativeVaue || this.props.role === "admin") && (
                              <Grid item>
                                <FormControlLabel
                                  labelPlacement="end"
                                  control={
                                    <Checkbox
                                      disabled={this.props.role !== "admin"}
                                      checked={deduction.isInsertDeductionAsNegativeVaue}
                                      value={!deduction.isInsertDeductionAsNegativeVaue}
                                      onChange={(e) => this.props.handleMainCalcDeductionOnChange(e, index)}
                                      name="isInsertDeductionAsNegativeVaue"
                                      color="primary"
                                    />
                                  }
                                  label="ניכוי שלילי"
                                />
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider light />
                  </Grid>
                </Fragment>
              );
            })}
            <Grid container item direction="row" alignItems="flex-start">
              <Button
                variant="contained"
                color="secondary"
                // Max deductions allowed is 300.
                disabled={this.props.deductions.length >= config.maxDeductionsAllowed}
                style={{ minWidth: "130px" }}
                endIcon={<AddIcon style={{ marginRight: "8px" }} />}
                onClick={this.props.addMainCalcDeduction}
              >
                הוספת ניכוי
              </Button>
            </Grid>
          </Grid>
        </CustomAcordion>
      </Grid>
    );

    const maxYears = this.props.getPrisaMaxYears();
    const minYears = this.props.getPrisaMinYears();
    const prisa =
      this.props.preCalc.sellerType === "single" ? (
        <Grid item>
          <PrisaComponent
            {...this.props.prisa}
            // isClientsNumDisabled={!this.props.abilities.includes("prisaExt")}
            isClientsNumDisabled={true}
            // isNumOfYearsDisabled={!this.props.abilities.includes("prisaExt")}
            shouldTryPrisaShown
            soldPropertyDate={this.props.preCalc.soldPropertyDate}
            maxYears={maxYears}
            minYears={minYears}
            options={getAmountOptions(6)}
            handlePrisaOnChange={this.props.handleMainCalcPrisaOnChange}
          />
        </Grid>
      ) : null;

    const anotherQuestions = (
      <Grid item>
        <CustomAcordion summaryText="שאלות נוספות" defaultExpanded={true}>
          <Grid container direction="column" spacing={2}>
            <Grid container item direction="row" spacing={10} alignItems="center">
              <Grid item>
                <LabeledTextField
                  type="number"
                  value={this.props.kizuzIski}
                  textFieldWidth={185}
                  name="kizuzIski"
                  label="קיזוז הפסד עסקי"
                  helpDialog={helpTexts.kizuzIski}
                  onChange={(e) => this.props.handleMainCalcOnChange(e)}
                />
              </Grid>
              <Grid item>
                <LabeledTextField
                  type="number"
                  value={this.props.kizuzHoni}
                  textFieldWidth={185}
                  name="kizuzHoni"
                  label="קיזוז הפסד הוני"
                  helpDialog={helpTexts.kizuzHoni}
                  onChange={(e) => this.props.handleMainCalcOnChange(e)}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider light />
            </Grid>
            <Grid container item direction="row" spacing={10} alignItems="center">
              <Grid item>
                <FormControl component="fieldset">
                  <Grid container item direction="row" spacing={1} alignItems="center">
                    <Grid item>
                      <Typography variant="subtitle1" align="right">
                        חישוב המס בדרך של גילום
                      </Typography>
                    </Grid>
                    <Grid item>
                      <HelpDialog helpDialog={helpTexts.isGilum} label="חישוב המס בדרך של גילום" />
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        row
                        name="isGilum"
                        value={Boolean(this.props.isGilum).toString()}
                        onChange={(e) => this.props.handleMainCalcOnChange(e)}
                      >
                        <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                        <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <Grid container item direction="row" spacing={1} alignItems="center">
                    <Grid item>
                      <Typography variant="subtitle1" align="right">
                        האם יש זיכוי הפקעה
                      </Typography>
                    </Grid>
                    <Grid item>
                      <HelpDialog helpDialog={helpTexts.afkaa} label="האם יש זיכוי הפקעה" />
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        row
                        name="afkaa"
                        value={Boolean(this.props.afkaa).toString()}
                        onChange={(e) => this.props.handleMainCalcOnChange(e)}
                      >
                        <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                        <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </CustomAcordion>
      </Grid>
    );
    const dialogs = (
      <Grid item>
        {this.state.dialogs.hasRabinovichDialogOpen ? (
          <HasRabinovichDialog
            handleResults={(e) => this.props.handleMainCalcDeductionOnChange(e, this.state.dialogs.dialogPart)}
            onClose={() => this.closeDialog("hasRabinovichDialogOpen")}
            open={true}
          />
        ) : null}
        {this.state.dialogs.depreciationCalculatorDialogOpen ? (
          <DepreciationCalculatorDialog
            handleResults={(e) => this.props.handleMainCalcDeductionOnChange(e, this.state.dialogs.dialogPart)}
            deduction={this.props.deductions[this.state.dialogs.dialogPart]}
            sellerType={this.props.preCalc.sellerType}
            propertyType={this.props.preCalc.propertyType}
            soldPropertyDate={this.props.preCalc.soldPropertyDate}
            getPurchaseDate={this.props.getPurchaseDate}
            onClose={() => this.closeDialog("depreciationCalculatorDialogOpen")}
            open={true}
          />
        ) : null}
      </Grid>
    );

    const preCalcPartsData = this.props.preCalc?.preCalcPartsData;
    const needsMutav = preCalcPartsData && preCalcPartsData[0] && preCalcPartsData[0].needsMutav;
    const needsRegTax = preCalcPartsData && preCalcPartsData[0] && preCalcPartsData[0].needsRegTax;

    return (
      <Grid container direction="column" spacing={2}>
        <Grid container item direction="column">
          {purchaseParts}
          {depreciations}
          {prisa}
          {anotherQuestions}

          {preCalcPartsData?.length === 1 && (
            <div className="right">
              <Button
                style={{ color: "lightgray" }}
                endIcon={
                  this.state.isManualOptionsShown ? (
                    <ArrowDownwardIcon style={{ marginRight: "8px" }} />
                  ) : (
                    <ArrowBackIcon style={{ marginRight: "8px" }} />
                  )
                }
                variant="text"
                color="primary"
                onClick={() => this.setState({ isManualOptionsShown: !this.state.isManualOptionsShown })}
              >
                חישובים מתקדמים
              </Button>

              {this.state.isManualOptionsShown && (
                <CustomAcordion summaryText="שינוי הגדרות חישוב עסקה" defaultExpanded>
                  <div>
                    <div className="blue-color">
                      <b> הערה חשובה מאוד</b>
                      <br />
                      <span>- במידה ואינך יודע\ת בדיוק מה את\ה עושה אנו מאוד ממליצים לא למלא שדות אלו ולהמשיך רגיל את חישוב העסקה</span>
                    </div>

                    <div className="mt-20">בשדות הבאים ניתן לשנות את אחוז המס לתשלום (ניתן להזין אחוז בין 1 ל-50):</div>
                    <div className="mt-20">ככל ואינך משנה שיעור מס בתקופה מסויימת - אינך נדרש למלא שדה זה.</div>
                    <div className="mt-10">
                      <Grid container direction="column" spacing={6}>
                        <Grid item>
                          <Grid container direction="row" spacing={3}>
                            {needsRegTax && (
                              <>
                                <Grid item>
                                  <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                      <Typography variant="h6" align="right">
                                        שבח תקופה ראשונה
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <LabeledTextField
                                        type="number"
                                        name="overrideLinear47"
                                        value={this.props.overrideTkufaPercent?.overrideLinear47 || ""}
                                        onChange={this.onOverrideTkufaPercentChange}
                                        currency="%"
                                        textFieldWidth={130}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="subtitle2" align="right">
                                        (שיעור מס לפני שינוי: 47%)
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                      <Typography variant="h6" align="right">
                                        שבח תקופה שניה
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <LabeledTextField
                                        type="number"
                                        name="overrideLinear20"
                                        value={this.props.overrideTkufaPercent?.overrideLinear20 || ""}
                                        onChange={this.onOverrideTkufaPercentChange}
                                        currency="%"
                                        textFieldWidth={130}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="subtitle2" align="right">
                                        (שיעור מס לפני שינוי: 20%)
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                      <Typography variant="h6" align="right">
                                        שבח תקופה שלישית
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <LabeledTextField
                                        type="number"
                                        name="overrideLinear25"
                                        value={this.props.overrideTkufaPercent?.overrideLinear25 || ""}
                                        onChange={this.onOverrideTkufaPercentChange}
                                        currency="%"
                                        textFieldWidth={130}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="subtitle2" align="right">
                                        (שיעור מס לפני שינוי: 25%)
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                      <Typography variant="h6" align="right">
                                        שבח אינפלציוני
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <LabeledTextField
                                        type="number"
                                        name="overrideInflation10"
                                        value={this.props.overrideTkufaPercent?.overrideInflation10 || ""}
                                        onChange={this.onOverrideTkufaPercentChange}
                                        currency="%"
                                        textFieldWidth={130}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="subtitle2" align="right">
                                        (שיעור מס לפני שינוי: 10%)
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            )}

                            {needsMutav && (
                              <Grid item>
                                <Grid container direction="column" spacing={1}>
                                  <Grid item>
                                    <Typography variant="h6" align="right">
                                      שבח לינארי מוטב
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <LabeledTextField
                                      type="number"
                                      name="overrideMutav25"
                                      value={this.props.overrideTkufaPercent?.overrideMutav25 || ""}
                                      onChange={this.onOverrideTkufaPercentChange}
                                      currency="%"
                                      textFieldWidth={130}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="subtitle2" align="right">
                                      (שיעור מס לפני שינוי: 25%)
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </CustomAcordion>
              )}
            </div>
          )}

          {dialogs}
        </Grid>
        <Grid container item direction="row" spacing={2}>
          <Grid item>
            <Button
              style={{ minWidth: "130px" }}
              endIcon={<PlayArrowIcon style={{ marginRight: "8px" }} />}
              variant="contained"
              color="secondary"
              onClick={this.props.mainCalcStart}
            >
              חשב מס
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{ minWidth: "130px" }}
              endIcon={<SaveIcon style={{ marginRight: "8px" }} />}
              variant="contained"
              color="secondary"
              onClick={this.props.updateDealData}
            >
              שמירה
            </Button>
          </Grid>
          {this.props.role === "admin" ? (
            <Grid item>
              <Button
                style={{ minWidth: "130px" }}
                endIcon={<GetAppIcon style={{ marginRight: "8px" }} />}
                variant="contained"
                color="primary"
                onClick={this.props.exportDealData}
              >
                ייצא
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  }

  onUploadDeductionFile = (e) => {
    const deductionFile = e.target.files[0];
    if (!deductionFile || !deductionFile.name.toLowerCase().includes(".xlsx")) {
      Swal.fire("פורמט לא תקין", "ניתן להעלות רק את הקובץ המובנה שלנו שניתן להוריד על לחיצה על הכפתור משמאל", "warning");
      return;
    }

    Swal.fire({
      title: "להעלות קובץ עם ניכויים?",
      text: "יש להקפיד על שימוש בפורמט שלנו בלבד אחרת לא נוכל להעלות את הניכויים שלך",
      icon: "info",
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        filesService.translateDeduTemplateToObj(deductionFile).then((res) => {
          document.getElementById("deduction-template").value = "";

          if (res.success) {
            this.props.addMainCalcDeductionsFromExcel(res.data);
          }
        });
      }
    });
  };

  openDialog(dialogName, part) {
    this.setState({ dialogs: { ...this.state.dialogs, [dialogName]: true, dialogPart: part || 0 } });
  }

  closeDialog(dialogName) {
    this.setState({ dialogs: { ...this.state.dialogs, [dialogName]: false, dialogPart: 0 } });
  }

  getCurrencyOptions(date, depreciation) {
    const options = [];
    if (!date) {
      options.push({ label: "שקל חדש", value: "newShekel" });
      options.push({ label: "דולר", value: "usd" });
    } else if (depreciation && depreciation.depType === "regTax") {
      return [{ label: "שקל חדש", value: "newShekel" }];
    } else {
      if (date >= "1986-01-01") {
        options.push({ label: "שקל חדש", value: "newShekel" });
      } else if (date >= "1980-02-24") {
        options.push({ label: "שקל ישן", value: "oldShekel" });
      } else {
        options.push({ label: "לירה", value: "lira" });
      }
      if (date >= "1977-10-31") {
        options.push({ label: "דולר", value: "usd" });
      }
    }
    return options;
  }

  getPrisaYearsOptions(maxYears) {
    const options = [];
    for (let i = 1; i <= maxYears; i++) {
      options.push({ label: i === 1 ? "שנה אחת אחורה" : `${i} שנים אחורה`, value: i });
    }
    return options;
  }

  getAtrributionOptions() {
    const options = [{ label: "דירה", value: "apartment" }];
    if (this.props.preCalc.hasZaban) {
      options.push({ label: 'זב"ן', value: "zaban" });
      options.push({ label: 'דירה וזב"ן', value: "both" });
    }
    return options;
  }

  onOverrideTkufaPercentChange = (e) => {
    let { name, value } = e.target;

    if (value > 50) return;
    if (value === 0) value = "";
    const overrideTkufaPercent = this.props.overrideTkufaPercent || {};

    this.props.handleMainCalcOnChange({ target: { name: "overrideTkufaPercent", value: { ...overrideTkufaPercent, [name]: value } } });
  };
}

const mapStateToProps = (state) => {
  return {
    abilities: state.loggedinReducer.abilities,
    role: state.loggedinReducer.role
  };
};

export default connect(mapStateToProps)(DealMainCalc);
