import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true
};

function PdfViewer(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isPaginationShown, setIsPaginationShown] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div
      onMouseOver={() => {
        if (!isPaginationShown) setIsPaginationShown(true);
      }}
      onMouseOut={() => {
        if (isPaginationShown) setIsPaginationShown(false);
      }}
      className="pdf-viewer-con"
      style={{ width: props.width ? props.width : "600px" }}
    >
      <Document file={props.pdfFile} options={options} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <div className="pdf-page-controls" style={{ opacity: isPaginationShown ? 1 : 0 }}>
        <button
          className={`${pageNumber === 1 ? "" : "btn-enabled"}`}
          onClick={() => {
            if (pageNumber === 1) return;
            setPageNumber(pageNumber - 1);
          }}
          type="button"
        >
          ‹
        </button>
        <span>
          עמוד {pageNumber} מתוך {numPages}
        </span>
        <button
          className={`${pageNumber === numPages ? "" : "btn-enabled"}`}
          onClick={() => {
            if (pageNumber === numPages) return;
            setPageNumber(pageNumber + 1);
          }}
          type="button"
        >
          ›
        </button>
      </div>
    </div>
  );
}

export default PdfViewer;
