import React, { Component } from "react";
import { Grid, Typography, Link, Divider, Button, Box } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

import CustomDialog from "../layout/CustomDialog";
import LabeledTextField from "../layout/LabeledTextField";
import { validateEmail } from "../../services/utils/utils";
import usersService from "../../services/usersService";
import Swal from "sweetalert2";

class ContactUs extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    subject: "",
    message: ""
  };

  render() {
    return (
      <CustomDialog label="צור קשר" open={this.props.open} onClose={this.props.onClose} maxWidth="md">
        <Grid container direction="row" spacing={3}>
          <Grid container item direction="column" spacing={1} xs={4}>
            <Grid item>
              <Typography variant="h4">מידע ליצירת קשר</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                אם יש לך שאלות, הצעות או פידבק אז בבקשה תרגיש חופשי ליצור איתנו קשר. כל שעליך לעשות זה לבחור את דרך ההתקשרות איתנו. אנו נשמח
                מאוד לשמוע ממך.
              </Typography>
            </Grid>
            <Grid container item direction="column">
              <Grid item>
                <Typography variant="h5">דוא"ל ליצירת קשר</Typography>
              </Grid>
              <Grid item>
                <Link variant="subtitle1" href="mailto:info@b-tax.co.il">
                  info@b-tax.co.il
                </Link>
              </Grid>
            </Grid>
            <Grid container item direction="column">
              <Grid item>
                <Typography variant="h5">מספר טלפון</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">050-592-7888</Typography>
              </Grid>
            </Grid>
            <Grid container item direction="column">
              <Grid item>
                <Typography variant="h5">כתובת</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">הארבעה 28, תל אביב</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                style={{ minWidth: "100px" }}
                endIcon={<SendIcon style={{ marginRight: "8px" }} />}
                variant="contained"
                color="secondary"
                onClick={this.sendMessage}
              >
                שלח
              </Button>
            </Grid>
          </Grid>
          <Grid container item direction="row" xs={1} justify="center">
            <Divider orientation="vertical" />
          </Grid>
          <Grid container item direction="column" spacing={1} xs={7}>
            <Grid item>
              <Typography variant="h4">כתוב לנו</Typography>
            </Grid>
            <Grid item>
              <LabeledTextField
                type="text"
                value={this.state.firstName}
                name="firstName"
                labelWidth={100}
                textFieldWidth={370}
                label="שם פרטי"
                onChange={this.handleOnChange}
              />
            </Grid>
            <Grid item>
              <LabeledTextField
                type="text"
                value={this.state.lastName}
                name="lastName"
                labelWidth={100}
                textFieldWidth={370}
                label="שם משפחה"
                onChange={this.handleOnChange}
              />
            </Grid>
            <Grid item>
              <LabeledTextField
                type="text"
                value={this.state.email}
                name="email"
                labelWidth={100}
                textFieldWidth={370}
                label="דואר אלקטרוני"
                onChange={this.handleOnChange}
              />
            </Grid>
            <Grid item>
              <LabeledTextField
                type="text"
                value={this.state.mobile}
                name="mobile"
                labelWidth={100}
                textFieldWidth={370}
                label="טלפון"
                onChange={this.handleOnChange}
              />
            </Grid>
            <Grid item>
              <LabeledTextField
                type="text"
                value={this.state.subject}
                name="subject"
                labelWidth={100}
                textFieldWidth={370}
                label="נושא"
                onChange={this.handleOnChange}
              />
            </Grid>
            <Grid item>
              <LabeledTextField
                type="text"
                placeholder="כתוב לנו הודעה"
                value={this.state.message}
                name="message"
                multiline
                rows={7}
                textFieldWidth={478}
                onChange={this.handleOnChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box width="100%" height="8px" />
      </CustomDialog>
    );
  }

  handleOnChange = (e) => {
    let val = e.target.value;
    const newState = { ...this.state, [e.target.name]: val };
    this.setState(newState);
  };

  validateMessage = () => {
    const state = this.state;
    if (!state.firstName || !state.lastName) {
      Swal.fire("חסר שדות", "חסר השדות שם פרטי ושם משפחה", "warning");
      return;
    }
    if (state.mobile.length < 6) {
      Swal.fire("", "מספר הטפלון שהוזן אינו חוקי", "warning");
      return;
    }

    if (!state.subject.length || !state.message.length) {
      Swal.fire("", "לא הוזנו נושא או הודעת הפניה", "warning");
      return;
    }

    if (!validateEmail(state.email)) {
      Swal.fire("", 'פורמט דוא"ל לא חוקי', "warning");
      return;
    }

    return {
      firstName: state.firstName,
      lastName: state.lastName,
      mobile: state.mobile,
      subject: state.subject,
      message: state.message,
      email: state.email
    };
  };

  sendMessage = () => {
    const validatedData = this.validateMessage();
    if (!validatedData) return;
    usersService.informUsContactUsMsg(validatedData).then((res) => {
      if (res.success) {
        Swal.fire("בוצע", "פנייתך הגיע אלינו ונחזור אליך בקרוב", "success");
        this.setState({ firstName: "", lastName: "", email: "", mobile: "", subject: "", message: "" });
      }
    });
  };
}

export default ContactUs;
