import React, { Component } from "react";
import { Grid, Box, Divider, Typography, Button } from "@material-ui/core";
import { connect } from "react-redux";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SaveIcon from "@material-ui/icons/Save";
import FilterFramesIcon from "@material-ui/icons/FilterFrames";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import GetAppIcon from "@material-ui/icons/GetApp";
import filesService from "../../../services/docsService/filesService";
import Swal from "sweetalert2";

import adminService from "../../../services/adminService";
import PrisaComponent from "../../shared/prisaComponent";
import * as customColors from "../../../theme/customColors";
import CustomAcordion from "../../layout/CustomAcordion";
import LabeledTextField from "../../layout/LabeledTextField";
import CustomBasicTable from "../../layout/CustomBasicTable";
import { getAmountOptions } from "../../../services/utils/utils";
import { CreateNewFolder } from "@material-ui/icons";
import { withRouter } from "react-router";

class JustPrisaCalc extends Component {
  render() {
    const tablesData = this.props.hasResults ? this.resultsTableCalculation() : null;
    return (
      <Grid container direction="column">
        <Grid item>
          <Box width="100%" height="20px" />
        </Grid>
        <Grid item>
          <CustomAcordion summaryText="קדם פריסה" defaultExpanded>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={7}>
                <Grid container direction="column" spacing={6}>
                  <Grid item>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant="h5" align="right" color="secondary">
                          חישוב לינארי ישן
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2" align="right">
                          (יש להזין את הנתונים שאושרו על ידי רשות המיסים כפי שמופיע בשומה בסעיף "<strong>יתרה לאחר קיזוז הפסד</strong>")
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={3}>
                      <Grid item>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Typography variant="h6" align="right">
                              שבח תקופה ראשונה
                            </Typography>
                          </Grid>
                          <Grid item>
                            <LabeledTextField
                              type="number"
                              name="finalLinear47"
                              value={this.props.finalLinear47}
                              onChange={this.props.handleCalcOnChange}
                              currency="₪"
                              textFieldWidth={130}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2" align="right">
                              (שיעור מס 47%)
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Typography variant="h6" align="right">
                              שבח תקופה שניה
                            </Typography>
                          </Grid>
                          <Grid item>
                            <LabeledTextField
                              type="number"
                              name="finalLinear20"
                              value={this.props.finalLinear20}
                              onChange={this.props.handleCalcOnChange}
                              currency="₪"
                              textFieldWidth={130}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2" align="right">
                              (שיעור מס 20%)
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Typography variant="h6" align="right">
                              שבח תקופה שלישית
                            </Typography>
                          </Grid>
                          <Grid item>
                            <LabeledTextField
                              type="number"
                              name="finalLinear25"
                              value={this.props.finalLinear25}
                              onChange={this.props.handleCalcOnChange}
                              currency="₪"
                              textFieldWidth={130}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2" align="right">
                              (שיעור מס 25%)
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Typography variant="h6" align="right">
                              שבח אינפלציוני
                            </Typography>
                          </Grid>
                          <Grid item>
                            <LabeledTextField
                              type="number"
                              name="finalInflationLinear10"
                              value={this.props.finalInflationLinear10}
                              onChange={this.props.handleCalcOnChange}
                              currency="₪"
                              textFieldWidth={130}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2" align="right">
                              (שיעור מס 10%)
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item direction="row" xs={1} justify="center">
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant="h5" align="right" color="secondary">
                          חישוב לינארי חדש
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Box width="80%">
                          <Typography variant="subtitle2" align="right">
                            (יש להזין את הנתונים שאושרו על ידי רשות המיסים כפי שמופיע בשומה בסעיף "<strong>יתרה לאחר קיזוז הפסד</strong>")
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant="h6" align="right">
                          שבח
                        </Typography>
                      </Grid>
                      <Grid item>
                        <LabeledTextField
                          type="number"
                          name="finalMutav25"
                          value={this.props.finalMutav25}
                          onChange={this.props.handleCalcOnChange}
                          currency="₪"
                          textFieldWidth={130}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2" align="right">
                          (שיעור מס 25%)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CustomAcordion>
        </Grid>
        <Grid item>
          <PrisaComponent
            {...this.props.prisa}
            soldPropertyDateShown
            datePropertyBoughtShown
            soldPropertyDate={this.props.soldPropertyDate}
            datePropertyBought={this.props.datePropertyBought}
            maxYears={this.props.getPrisaMaxYears(this.props.soldPropertyDate, this.props.datePropertyBought)}
            options={getAmountOptions(6)}
            handlePrisaOnChange={this.props.handlePrisaCalcOnChange}
            handleOnChange={this.props.handleCalcOnChange}
          />
        </Grid>
        {this.props.hasResults ? (
          <Grid item>
            <CustomAcordion summaryText="תוצאות" defaultExpanded>
              <Grid container direction="column" spacing={3}>
                <Grid container item direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <CheckCircleRoundedIcon style={{ verticalAlign: "middle", color: customColors.green, fontSize: "40px" }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">תוצאות לאחר פריסה</Typography>
                  </Grid>
                </Grid>
                {Array.isArray(tablesData)
                  ? tablesData.map((table, index) => (
                      <Grid container item direction="row" key={`part-${index + 1}`}>
                        <Grid item>
                          <CustomBasicTable
                            name={`part-${index + 1}`}
                            headers={table.headers}
                            rows={table.body}
                            colorTableName
                            emphasizeSummary
                          />
                        </Grid>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </CustomAcordion>
          </Grid>
        ) : null}
        <Grid item>
          <Box width="100%" height="16px" />
        </Grid>
        <Grid item container direction="row" spacing={2}>
          {this.props.hasResults ? (
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ minWidth: "150px" }}
                endIcon={<CreateNewFolder style={{ marginRight: "8px" }} />}
                onClick={this.generateJustPrisa}
              >
                הפק טופס
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item>
                <Button
                  style={{ minWidth: "130px" }}
                  endIcon={<PlayArrowIcon style={{ marginRight: "8px" }} />}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.props.justPrisaCalc();
                  }}
                >
                  חשב
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{ minWidth: "130px" }}
                  endIcon={<SaveIcon style={{ marginRight: "8px" }} />}
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.updateDealData()}
                >
                  שמירה
                </Button>
              </Grid>
            </>
          )}
          {this.props.hasResults && this.props.role === "admin" ? (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "130px" }}
                endIcon={<FilterFramesIcon style={{ marginRight: "8px" }} />}
                onClick={this.addJustPrisaTest}
              >
                שמירה כטסט
              </Button>
            </Grid>
          ) : null}
          {this.props.role === "admin" ? (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "130px" }}
                endIcon={<GetAppIcon style={{ marginRight: "8px" }} />}
                onClick={() => this.props.exportDealData()}
              >
                ייצא
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  }

  generateJustPrisa = () => {
    filesService.generateJustPrisa({ dealId: this.props.match.params.dealId }).catch(() => {
      Swal.fire("היתה בעיה ביצירת המסמך", "במידה ובעיה זו ממשיכה בבקשה צרו איתנו קשר", "warning");
    });
  };

  addJustPrisaTest = () => {
    const data = {
      hasResults: this.props.hasResults,
      prisaResults: this.props.prisaResults,
      finalLinear47: this.props.finalLinear47,
      finalLinear20: this.props.finalLinear20,
      finalLinear25: this.props.finalLinear25,
      finalInflationLinear10: this.props.finalInflationLinear10,
      finalMutav25: this.props.finalMutav25,
      soldPropertyDate: this.props.soldPropertyDate,
      datePropertyBought: this.props.datePropertyBought,
      prisa: this.props.prisa
    };
    Swal.fire({
      title: "הזן תיאור בדיקה",
      input: "text",
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonText: "המשך",
      preConfirm: (testDescription) => {
        if (!testDescription) return Swal.showValidationMessage("יש להזין תיאור בדיקה");
        return adminService
          .addJustPrisaTest(testDescription, { justPrisa: data }, "btaxYam")
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      if (result.isConfirmed && result.value.success) {
        Swal.fire({
          icon: "success",
          title: `הבדיקה נשמרה בהצלחה`,
          confirmButtonText: "המשך"
        });
      }
    });
  };

  resultsTableCalculation() {
    const numOfClients = this.props.prisaResults.length;
    const tables = this.props.prisaResults.map((part, index) => {
      const table = {};
      const personName = `- ${this.props.prisa.personsData[index].personName} -`;
      table.headers = [personName, "תקופה ראשונה", "תקופה שניה", "תקופה שלישית", "מס מוטב", "מס אינפלציוני", "סך הכל"];
      const client = part[0];
      table.body = [];
      let sum = 0;
      let currentVal = 0;
      const row1 = [];
      const row2 = [];
      const row3 = [];
      row1.push("מס שבח לתשלום ללא פריסה");
      row2.push("חסכון בתשלום בשל הפריסה");
      row3.push("מס שבח לתשלום לאחר פריסה");
      currentVal = (this.props.finalLinear47 / numOfClients) * 0.47;
      sum += currentVal;
      row1.push(currentVal);
      row2.push(currentVal - client.finalLinear47);
      row3.push(client.finalLinear47);
      currentVal = (this.props.finalLinear20 / numOfClients) * 0.2;
      sum += currentVal;
      row1.push(currentVal);
      row2.push(currentVal - client.finalLinear20);
      row3.push(client.finalLinear20);
      currentVal = (this.props.finalLinear25 / numOfClients) * 0.25;
      sum += currentVal;
      row1.push(currentVal);
      row2.push(currentVal - client.finalLinear25);
      row3.push(client.finalLinear25);
      currentVal = (this.props.finalMutav25 / numOfClients) * 0.25;
      sum += currentVal;
      row1.push(currentVal);
      row2.push(currentVal - client.finalMutav25);
      row3.push(client.finalMutav25);
      currentVal = (this.props.finalInflationLinear10 / numOfClients) * 0.1;
      sum += currentVal;
      row1.push(currentVal);
      row2.push(currentVal - client.finalInflationLinear10);
      row3.push(client.finalInflationLinear10);
      row1.push(sum);
      const sumAfterPrisa =
        client.finalLinear47 + client.finalLinear20 + client.finalLinear25 + client.finalMutav25 + client.finalInflationLinear10;
      row2.push(sum - sumAfterPrisa);
      row3.push(sumAfterPrisa);
      table.body.push(row1);
      table.body.push(row2);
      table.body.push(row3);
      return table;
    });
    return tables;
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.loggedinReducer.role
  };
};

export default withRouter(connect(mapStateToProps)(JustPrisaCalc));
