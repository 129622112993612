import React, { Fragment, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Fab } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import AccessibleIcon from "@material-ui/icons/Accessible";

import Login from "../components/sessions/login";
import PendingRegister from "../components/sessions/pendingRegister";
import ContactUs from "../components/dialogs/contactUs";
import TokenConf from "../components/sessions/conf-token";
import ForgetPassword from "../components/sessions/forgotPass";
import MessagePage from "../components/sessions/messagePage";
import SetNewPass from "../components/sessions/setNewPass";

import "../assets/css/sessions.css";
import AccessibilityDialog from "../components/dialogs/AccessibilityDialog";

function SessionsCon() {
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [isAccessibilityDialogOpen, setIsAccessibilityDialogOpen] = useState(false);

  const handleClickOpen = (dialog) => {
    if (dialog === "isContactUsOpen") setIsContactUsOpen(true);
    else if (dialog === "isAccessibilityDialogOpen") setIsAccessibilityDialogOpen(true);
  };

  const handleClose = (dialog) => {
    if (dialog === "isContactUsOpen") setIsContactUsOpen(false);
    else if (dialog === "isAccessibilityDialogOpen") setIsAccessibilityDialogOpen(false);
  };

  return (
    <Fragment>
      <Switch>
        <Route path="/sessions/login" component={Login} />
        <Route path="/sessions/register" component={PendingRegister} />
        <Route path="/sessions/conf-token/:token/:clientEmail" component={TokenConf} />
        <Route path="/sessions/forgot-password" component={ForgetPassword} />
        <Route path="/sessions/message/:messageType" component={MessagePage} />
        <Route path="/sessions/set-new-pass/:emailConfToken/:email" component={SetNewPass} />
      </Switch>
      <Fab
        color="secondary"
        style={{
          top: "auto",
          right: "auto",
          bottom: 120,
          left: 30,
          position: "fixed"
        }}
        onClick={() => {
          handleClickOpen("isContactUsOpen");
        }}
      >
        <MailIcon />
      </Fab>
      <Fab
        color="secondary"
        style={{
          top: "auto",
          right: "auto",
          bottom: 30,
          left: 30,
          position: "fixed"
        }}
        onClick={() => {
          handleClickOpen("isAccessibilityDialogOpen");
        }}
      >
         <AccessibleIcon />
      </Fab>
      {isContactUsOpen ? <ContactUs open onClose={() => handleClose("isContactUsOpen")} /> : null}
      {isAccessibilityDialogOpen ? <AccessibilityDialog open onClose={() => handleClose("isAccessibilityDialogOpen")} /> : null}
    </Fragment>
  );
}

export default SessionsCon;
