import React, { Component } from "react";
import Swal from "sweetalert2";
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import CustomDialog from "../layout/CustomDialog";
import { Settings } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import dealsService from "../../services/dealsService";
import { numberWithCommas } from "../../services/utils/utils";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomBasicTable from "../layout/CustomBasicTable";
import filesService from "../../services/docsService/filesService";

const BOTH_APAS_PTOR_MSG = "יש זכאות לשתי הדירות להמכר בפטור מלא בשל שמחיר המכירה של שתי הדירות הינו נמוך מתקרת הפטור המינימלי";
const USED_49A_TITLE = "נעשה שימוש בפטור 49(ה) בצורה הבאה";
const DIDNT_USE_49A = "אין צורך בשימוש בפטור 49(ה).";

class Dialog49a extends Component {
  state = {
    hasMaxTwoApas: "",
    isAllowedSingleApaPtor: "",
    dealOne: null,
    dealTwo: null,
    isMoreInfoShown: false,
    calculationRes: "",
    selectedDealName: "",
    selectedDealName2: "",
    secondaryOpts: []
  };

  render() {
    const isAllowed49a = this.isAllowed49a();

    const calculationRes = this.state.calculationRes;

    const bestTaxOptTable = this.createTaxTypesTable(calculationRes?.bestSampleForDocsCreation);

    return (
      <CustomDialog label="מכירת שתי דירות בפטור - 49(ה)" open={this.props.open} onClose={this.cleanAndClosePopup}>
        <div className="gray-color">* במידה ועדיין לא הזנת את שתי העסקאות לבדיקה, עליך להזין אותן קודם ואז לחזור לחלון הזה</div>
        <hr className="mb-20 mt-20" />
        <FormControl component="fieldset">
          <FormLabel component="legend">כמה דירות יש ללקוח?</FormLabel>
          <RadioGroup row onChange={(e) => this.handleOnChange("hasMaxTwoApas", e.target.value)}>
            <FormControlLabel value="exactlyTwoApas" control={<Radio color="primary" />} label="שתי דירות" />
            <FormControlLabel value="moreThanTwoApas" control={<Radio color="primary" />} label="יותר משתי דירות" />
          </RadioGroup>
        </FormControl>

        <hr className="mb-20" />
        <FormControl component="fieldset">
          <FormLabel component="legend">אם היה ללקוח דירה אחת בלבד, האם היה זכאי לפטור דירה יחידה?</FormLabel>
          <RadioGroup row onChange={(e) => this.handleOnChange("isAllowedSingleApaPtor", e.target.value)}>
            <FormControlLabel value="allowedSingleApaPtor" control={<Radio color="primary" />} label="כן" />
            <FormControlLabel value="notAllowedSingleApaPtor" control={<Radio color="primary" />} label="לא" />
          </RadioGroup>
        </FormControl>

        {isAllowed49a === "allowed" ? (
          <div>
            <h3>יש לבחור שתי עסקאות להמלצה</h3>

            <div className="inline-block width40">
              <Autocomplete
                fullWidth
                value={this.state.dealOne}
                blurOnSelect
                options={this.props.dealsPrettyIdAndDesc}
                getOptionLabel={(option) => `${option.prettyId} - ${option.dealDescription || ""}`}
                openOnFocus
                renderInput={(params) => <TextField {...params} label="עסקה ראשונה" />}
                onChange={(e, val) => this.handleOnChange("dealOne", val)}
                className="center-div"
              />
            </div>
            <div className="inline-block width40 mr-20 mb-20">
              <Autocomplete
                fullWidth
                value={this.state.dealTwo}
                blurOnSelect
                options={this.props.dealsPrettyIdAndDesc}
                getOptionLabel={(option) => `${option.prettyId} - ${option.dealDescription || ""}`}
                openOnFocus
                renderInput={(params) => <TextField {...params} label="עסקה שניה" />}
                onChange={(e, val) => {
                  this.handleOnChange("dealTwo", val);
                }}
                className="center-div"
              />
            </div>

            <hr />
            <div className="mt-20">
              <Button
                style={{ minWidth: "130px" }}
                endIcon={<Settings style={{ marginRight: "8px" }} />}
                variant="contained"
                color="secondary"
                onClick={this.execute49a}
              >
                לחץ לניתוח
              </Button>
            </div>
          </div>
        ) : isAllowed49a === "notAllowed" ? (
          <h2 className="yellow-color">הלקוח לא זכאי לפטור 49(ה)</h2>
        ) : null}

        {calculationRes ? (
          <div>
            <h2 className="yellow-color">תוצאות חישוב</h2>
            <div>
              <div className="inline-block fs-20">
                <div>
                  <b>מס סופי לתשלום: </b>
                  {calculationRes.finalCheapestSummedTax} ש"ח
                </div>
              </div>
              <div className="inline-block fs-40 ml-20 mr-20"> | </div>

              <div className="inline-block">{calculationRes.instructionsForClientTitle}</div>
            </div>

            <div className="mt-10">
              <div className="fs-20 yellow-color">
                <b>הוראות</b>
              </div>
              {calculationRes.instructionsForClientBody}
            </div>

            <div className="mt-20">
              <div className="mb-5">
                <b className="fs-20 yellow-color">פירוט המיסוי</b>
              </div>
              <CustomBasicTable headers={["", "לינארי ישן", "מס מוטב"]} rows={bestTaxOptTable} />
            </div>
            <hr className="mb-20 mt-20" />

            <div className="ml-10 inline-block">
              <Button
                style={{ width: "130px" }}
                endIcon={
                  this.state.isMoreInfoShown ? (
                    <ArrowDownwardIcon style={{ marginRight: "8px" }} />
                  ) : (
                    <ArrowBackIcon style={{ marginRight: "8px" }} />
                  )
                }
                variant="contained"
                color="secondary"
                name="isPreCalcResultsExpanded"
                onClick={() => this.setState({ isMoreInfoShown: !this.state.isMoreInfoShown })}
              >
                פירוט נוסף
              </Button>
            </div>

            {this.is2990NotNeeded() ? (
              <b>אין צורך ביצירת קבצי 2990 - הדירות בפטור מלא ללא חבות מס</b>
            ) : (
              <Button
                style={{ minWidth: "130px" }}
                endIcon={<Settings style={{ marginRight: "8px" }} />}
                variant="contained"
                color="primary"
                onClick={() => this.create49aDocs2990(this.state.calculationRes.bestSampleForDocsCreation)}
              >
                לחץ ליצירת מסמכי 2990
              </Button>
            )}

            {this.state.isMoreInfoShown ? (
              <div>
                <div className="mt-20">
                  <hr />
                  <div className="mt-20">
                    {calculationRes.method === "1" ? (
                      <h3 className="yellow-color">אין הבדל בסדר המכירה של הדירות בשל הסיבה ששתי הדירות יקבלו פטור מלא ממס</h3>
                    ) : (
                      <>
                        <h3 className="yellow-color">אופציות מכירה נוספות שניתנות לעשות (עלול להיות מיסוי גבוה יותר):</h3>

                        <div>
                          {this.state.secondaryOpts.map((secondaryOpt, index) => {
                            return (
                              <div key={index}>
                                {this.state.secondaryOpts.length > 1 ? (
                                  <div className="fs-20 bold mt-20">אופציה מספר: {index + 1}</div>
                                ) : null}
                                {secondaryOpt.explanation}
                                <div className="mb-5">
                                  <CustomBasicTable
                                    headers={["", "לינארי ישן", "מס מוטב"]}
                                    rows={this.createTaxTypesTable(secondaryOpt.taxObj)}
                                  />
                                </div>

                                <Button
                                  style={{ minWidth: "130px" }}
                                  endIcon={<Settings style={{ marginRight: "8px" }} />}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => this.create49aDocs2990(secondaryOpt.taxObj)}
                                >
                                  יצר מסמך 2990
                                </Button>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </CustomDialog>
    );
  }

  createTaxTypesTable = (sampleDealCase) => {
    const tableExtraDataRows = [];
    if (sampleDealCase) {
      tableExtraDataRows.push([
        sampleDealCase.firstPrettyName,
        sampleDealCase.resMainCalc1.data.mainCalc.calculatedData.dataPerPart[0].actualFinalLinear26,
        // (sampleDealCase.resMainCalc1.data.mainCalc.calculatedData.dataPerPart[0].afterKizuzimInflation10 || 0),
        sampleDealCase.resMainCalc1.data.mainCalc.calculatedData.dataPerPart[0].actualFinalMutav26
      ]);
      if (sampleDealCase.secondPrettyName) {
        tableExtraDataRows.push([
          sampleDealCase.secondPrettyName,
          sampleDealCase.resMainCalc2.data.mainCalc.calculatedData.dataPerPart[0].actualFinalLinear26,
          // (sampleDealCase.resMainCalc2.data.mainCalc.calculatedData.dataPerPart[0].afterKizuzimInflation10 || 0),
          sampleDealCase.resMainCalc2.data.mainCalc.calculatedData.dataPerPart[0].actualFinalMutav26
        ]);
      }
    }
    return tableExtraDataRows;
  };

  create49aDocs2990 = (sampleForDocsCreation) => {
    Swal.fire({
      title: "לייצר מסמכי 2990 לעסקאות?",
      text: "",
      confirmButtonText: "כן",
      cancelButtonText: "לא",
      icon: "info",
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        filesService
          .create49aDocs2990(sampleForDocsCreation, this.state.dealOne._id, this.state.dealTwo._id)
          .then(() => {
            Swal.fire("מסמכים ירדו בהצלחה", "", "success");
          })
          .catch(() => {
            Swal.fire("היתה בעיה בהורדת הקובץ", "", "warning");
          });
      }
    });
  };

  handleOnChange = (key, val) => {
    this.setState({ [key]: val, calculationRes: null });
  };

  isAllowed49a = () => {
    const { hasMaxTwoApas, isAllowedSingleApaPtor } = this.state;
    // still both of the radios are not inserted.. we dont know if has ptor 49a
    if (!hasMaxTwoApas && !isAllowedSingleApaPtor) return null;

    if (hasMaxTwoApas) {
      if (hasMaxTwoApas === "moreThanTwoApas") return "notAllowed";
      if (!isAllowedSingleApaPtor) return null;
      if (isAllowedSingleApaPtor === "notAllowedSingleApaPtor") return "notAllowed";
      return "allowed";
    } else {
      if (isAllowedSingleApaPtor === "notAllowedSingleApaPtor") return "notAllowed";
      return null;
    }
  };

  getTextForOnePartMutavAndOnePtor = (dealName1, dealName2, isBestOpt) => (
    <span>
      יש צורך למכור במכירה רגילה (במס מוטב) את עסקה <b>{dealName1}</b> ולאחר מיכן תיהיה זכאות לפטור דירה יחידה על העסקה השניה -{" "}
      <b>{dealName2}</b>
      {isBestOpt ? " על מנת לקבל את המיסוי הנמוך ביותר " : ""}
    </span>
  );

  execute49a = () => {
    if (!this.state.dealOne || !this.state.dealTwo) {
      return Swal.fire("חובה לבחור שתי עסקאות", "", "info");
    }

    if (this.state.dealOne._id === this.state.dealTwo._id) {
      return Swal.fire("לא ניתן לבחור את אותה העסקה פעמיים", "", "info");
    }
    dealsService.execute49a(this.state.dealOne, this.state.dealTwo).then((res) => {
      if (res.success) {
        const secondaryOpts = [];

        const selectedDealName = `"${this.state.dealOne.prettyId} - ${this.state.dealOne.dealDescription || ""}"`;
        const selectedDealName2 = `"${this.state.dealTwo.prettyId} - ${this.state.dealTwo.dealDescription || ""}"`;

        const calculationRes = res.calculationRes;
        if (calculationRes.method === "1") {
          calculationRes.finalCheapestSummedTax = this.getFinalDealTax(calculationRes.calcTwoRegDealsWithPtor);
          calculationRes.finalCheapestSummedTax = numberWithCommas(parseInt(calculationRes.finalCheapestSummedTax));
          calculationRes.bestSampleForDocsCreation = calculationRes.calcTwoRegDealsWithPtor;
          delete calculationRes.calcTwoRegDealsWithPtor;
          calculationRes.instructionsForClientTitle = USED_49A_TITLE;
          calculationRes.instructionsForClientBody = BOTH_APAS_PTOR_MSG;
        } else {
          // Creating options / method 3
          let bestTaxOptionVal = this.getFinalDealTax(calculationRes.calcRegSample1);
          let bestTaxOptObjFromMethod3 = calculationRes.calcRegSample1;
          let bestTaxOptTextFromMethod3 = this.getTextForOnePartMutavAndOnePtor(selectedDealName2, selectedDealName);

          let secondOpt = calculationRes.calcRegSample2;
          let secondOptText = this.getTextForOnePartMutavAndOnePtor(selectedDealName, selectedDealName2);

          let selectedActualDealData = calculationRes.calcRegSample1;
          let instructionsForClientTitle = DIDNT_USE_49A;
          let instructionsForClientBody = this.getTextForOnePartMutavAndOnePtor(selectedDealName2, selectedDealName, true);

          const secondSample = this.getFinalDealTax(calculationRes.calcRegSample2);

          // If from the REGULAR tax options its better to sell in a different order.. then switch
          if (bestTaxOptionVal > secondSample) {
            bestTaxOptionVal = secondSample;
            bestTaxOptTextFromMethod3 = this.getTextForOnePartMutavAndOnePtor(selectedDealName, selectedDealName2);
            bestTaxOptObjFromMethod3 = calculationRes.calcRegSample2;

            secondOpt = calculationRes.calcRegSample1;
            secondOptText = this.getTextForOnePartMutavAndOnePtor(selectedDealName2, selectedDealName);

            selectedActualDealData = calculationRes.calcRegSample2;
            instructionsForClientBody = this.getTextForOnePartMutavAndOnePtor(selectedDealName, selectedDealName2, true);
          }

          // Pushing from the method 3 (one mutav one reg tax) the less good option to the other options list.
          secondaryOpts.push({ explanation: secondOptText, taxObj: secondOpt });

          if (calculationRes.method === "2") {
            const thirdSample = this.getFinalDealTax(calculationRes.calcOneApaPtorSample1);

            let isBestTaxFromMethod3 = false;
            if (bestTaxOptionVal > thirdSample) {
              // If we found that partial usage of 49a is still better than BOTH alternatives of JUST regular tax(no 49a at all)
              secondaryOpts.push({ explanation: bestTaxOptTextFromMethod3, taxObj: bestTaxOptObjFromMethod3 });

              bestTaxOptionVal = thirdSample;
              selectedActualDealData = calculationRes.calcOneApaPtorSample1;
              instructionsForClientTitle = USED_49A_TITLE;
              instructionsForClientBody = (
                <span>
                  עסקה <b>{selectedDealName}</b> נמכרת בפטור מלא ועסקה <b>{selectedDealName2}</b> נמכרת במס לינארי ישן בצורה יחסית
                </span>
              );
            } else {
              const text49A = (
                <span>
                  עסקה <b>{selectedDealName}</b> נמכרת בפטור מלא ועסקה <b>{selectedDealName2}</b> נמכרת במס לינארי ישן בצורה יחסית
                </span>
              );
              isBestTaxFromMethod3 = true;
              secondaryOpts.push({ explanation: text49A, taxObj: calculationRes.calcOneApaPtorSample1 });
            }

            const forthSample = this.getFinalDealTax(calculationRes.calcOneApaPtorSample2);

            if (bestTaxOptionVal > forthSample) {
              if (isBestTaxFromMethod3) {
                secondaryOpts.push({ explanation: bestTaxOptTextFromMethod3, taxObj: bestTaxOptObjFromMethod3 });
              } else {
                const text49A = (
                  <span>
                    עסקה <b>{selectedDealName}</b> נמכרת בפטור מלא ועסקה <b>{selectedDealName2}</b> נמכרת במס לינארי ישן בצורה יחסית
                  </span>
                );
                secondaryOpts.push({ explanation: text49A, taxObj: calculationRes.calcOneApaPtorSample1 });
              }

              bestTaxOptionVal = forthSample;
              selectedActualDealData = calculationRes.calcOneApaPtorSample2;
              instructionsForClientTitle = USED_49A_TITLE;
              instructionsForClientBody = (
                <span>
                  עסקה <b>{selectedDealName2}</b> נמכרת בפטור מלא ועסקה <b>{selectedDealName}</b> נמכרת במס לינארי ישן בצורה יחסית{" "}
                </span>
              );
            } else {
              const text49A = (
                <span>
                  עסקה <b>{selectedDealName2}</b> נמכרת בפטור מלא ועסקה <b>{selectedDealName}</b> נמכרת במס לינארי ישן בצורה יחסית
                </span>
              );
              secondaryOpts.push({ explanation: text49A, taxObj: calculationRes.calcOneApaPtorSample2 });
            }
          }

          calculationRes.finalCheapestSummedTax = numberWithCommas(parseInt(bestTaxOptionVal));
          calculationRes.bestSampleForDocsCreation = selectedActualDealData;
          calculationRes.instructionsForClientTitle = instructionsForClientTitle;
          calculationRes.instructionsForClientBody = instructionsForClientBody;
        }

        this.setState({ calculationRes, secondaryOpts, selectedDealName, selectedDealName2 });
      } else if (res.msgTitle) {
        Swal.fire(res.msgTitle, res.msgBody, "info");
      }
    });
  };

  getFinalDealTax = (deal) => {
    return (
      (deal.resMainCalc1?.data.mainCalc.calculatedData.dataPerPart[0].actualFinalLinear26 || 0) +
      (deal.resMainCalc1?.data.mainCalc.calculatedData.dataPerPart[0].actualFinalMutav26 || 0) +
      // (deal.resMainCalc1?.data.mainCalc.calculatedData.dataPerPart[0].afterKizuzimInflation10 || 0) +
      (deal.resMainCalc2?.data.mainCalc.calculatedData.dataPerPart[0].actualFinalLinear26 || 0) +
      (deal.resMainCalc2?.data.mainCalc.calculatedData.dataPerPart[0].actualFinalMutav26 || 0)
      // (deal.resMainCalc2?.data.mainCalc.calculatedData.dataPerPart[0].afterKizuzimInflation10 || 0)
    );
  };

  cleanAndClosePopup = () => {
    this.setState({ hasMaxTwoApas: "", isAllowedSingleApaPtor: "", dealOne: null, dealTwo: null, calculationRes: null });
    this.props.onClose();
  };

  is2990NotNeeded = () => {
    const { calculationRes } = this.state;
    const firstDealHasPtor =
      calculationRes?.bestSampleForDocsCreation?.resMainCalc1?.data?.preCalc?.preCalcPartsData[0].isSinglePropertyPtorAllowed;
    const secondDealHasPtor =
      calculationRes?.bestSampleForDocsCreation?.resMainCalc2?.data?.preCalc?.preCalcPartsData[0].isSinglePropertyPtorAllowed;

    return calculationRes.finalCheapestSummedTax === 0 && calculationRes.method === "1" && firstDealHasPtor && secondDealHasPtor;
  };
}

export default Dialog49a;
