import { SET_LOADER_ON, SET_LOADER_OFF, NEW_ACCESSIBLE_STATE } from "./actionTypes";

export const setLoaderOn = () => {
  return (dispatch, getstate) => {
    dispatch({ type: SET_LOADER_ON });
  };
};

export const setLoaderOff = () => {
  return (dispatch, getstate) => {
    dispatch({ type: SET_LOADER_OFF });
  };
};

export const setNewAccessibleState = (newAccessibleState) => {
  return (dispatch, getstate) => {
    dispatch({ type: NEW_ACCESSIBLE_STATE, payload: newAccessibleState });
  };
};
