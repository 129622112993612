import React, { Component } from "react";
import { Grid, Box } from "@material-ui/core";
import ReactJson from "react-json-view";

import CustomDialog from "../layout/CustomDialog";

class TestJsonDisplayerDialog extends Component {
  render() {
    return (
      <CustomDialog label="פרטי הבדיקה" open={this.props.open} onClose={this.props.onClose} maxWidth="md">
        <Grid container direction="column" spacing={3} alignItems="flex-end">
          <Grid item>
            <ReactJson src={this.props.test} enableClipboard={false} style={{ direction: "ltr", textAlign: "left" }} />
          </Grid>
        </Grid>
        <Box width="100%" height="8px" />
      </CustomDialog>
    );
  }
}

export default TestJsonDisplayerDialog;
