import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Swal from "sweetalert2";
import { Button, TextField } from "@material-ui/core";
import adminService from "../../services/adminService";

export default function ManageEmailSubRenewal() {
  const [testEmail, setTestEmail] = useState("");
  const [emailHtmlTitleRep, setEmailHtmlTitleRep] = useState("");
  const [emailHtmlBodyRep, setEmailHtmlBodyRep] = useState("");

  useEffect(() => {
    adminService.getRenewalTemplate().then((res) => {
      if (res.success && res.emailData) {
        setEmailHtmlTitleRep(res.emailData.emailTitle);
        setEmailHtmlBodyRep(res.emailData.emailBody);
      }
    });
  }, []);

  return (
    <div>
      <h2 className="yellow-color">מייל אוטומטי שייצא ללקוחות בסיום המנוי</h2>
      <div className="right fs-20 renewal-email-title-con">
        מייל חלק עליון
        <div>
          <TextField value={emailHtmlTitleRep} onChange={(e) => setEmailHtmlTitleRep(e.target.value)} fullWidth />
        </div>
      </div>
      <div className="mt-10 relative">
        <Editor
          apiKey="mdyv1tvpdrpgk5qhtw8mkul15ant6kbb99qzxb83dxal9jeh"
          value={emailHtmlBodyRep}
          init={{
            height: 500,
            menubar: false,
            directionality: "rtl",

            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount"
            ],
            toolbar:
              "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons"
          }}
          onEditorChange={(emailHtmlBodyRep) => setEmailHtmlBodyRep(emailHtmlBodyRep)}
        />

        <div className="position-test-renewal-email-btn">
          <TextField onChange={(e) => setTestEmail(e.target.value)} variant="outlined" size="small" />
          <div className="inline-block mr-10 ">
            <Button onClick={sendRenewalTest} variant="contained">
              שלח מייל לדוגמה
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-20">
        <Button color="primary" onClick={updateRenewalEmailRep} variant="contained">
          עדכון מייל
        </Button>
      </div>
    </div>
  );

  function sendRenewalTest() {
    if (!emailHtmlTitleRep || !emailHtmlBodyRep) {
      Swal.fire("יש להזין כותרת וגוף מייל", "", "info");
      return;
    }

    if (!testEmail) {
      Swal.fire("יש להזין מייל לבדיקה", "", "info");
      return;
    }

    adminService.sendRenewalTest(testEmail, emailHtmlTitleRep, emailHtmlBodyRep).then((res) => {
      if (res.success) {
        Swal.fire("נשלח מייל לבדיקה בהצלחה", "", "success");
      }
    });
  }

  function updateRenewalEmailRep() {
    if (!emailHtmlTitleRep || !emailHtmlBodyRep) {
      Swal.fire("יש להזין כותרת וגוף מייל", "", "info");
      return;
    }

    Swal.fire({
      title: "לשנות פורמט מייל? האם בדקת קודם איך נראה המייל לפני השינוי?",
      confirmButtonText: "המשך",
      cancelButtonText: "ביטול",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        adminService.updateRenewalEmail(emailHtmlTitleRep, emailHtmlBodyRep).then((res) => {
          if (res.success) {
            Swal.fire("שונה בהצלחה", "", "success");
          }
        });
      }
    });
  }
}
