import axios from "axios";
import Swal from "sweetalert2";

var generalErrMsg = "There appears to be a problem right now. Please try again later.";

export default {
  setupInterceptors: (history, toggleLoader) => {
    axios.interceptors.request.use((config) => {
      if (!config.preventLoading) toggleLoader(true);
      const token = localStorage.getItem("token");
      if (token) config.headers.Authorization = `Bearer ${token}`;

      return config;
    });

    axios.interceptors.response.use(
      (response) => {
        toggleLoader(false);

        if (response && response.data) {
          if (response.data.needsLogin) {
            const stationName = localStorage.getItem("stationName");
            localStorage.clear();
            localStorage.setItem("stationName", stationName);
            document.location.href = "/sessions/login";
            return;
          } else if (response.data.err) {
            Swal.fire("", generalErrMsg, "warning");
          }
        }

        return response;
      },
      (error) => {
        toggleLoader(false);

        if (error.request && error.request.status === 429) {
          Swal.fire("", "Too many requests made", "warning").then(() => {
            window.location.href = "/";
          });
        } else {
          Swal.fire("", generalErrMsg, "warning");
        }
        return error;

        // localStorage.clear();
        // document.location.href = "/guest/sign-in";
        // if (error.response.status === 404) {
        // history.push("/not-found");
        // }
      }
    );
  }
};
