//action types
import { SET_LOADER_ON, SET_LOADER_OFF, NEW_ACCESSIBLE_STATE } from "../actions/actionTypes";

const INITIAL_STATE = {
  loaderOn: false,
  screenZoomLevel: "",
  fontSizeLevel: "",
  lineHeightLevel: "",
  letterSpaceLevel: "",
  linkHighlightLevel: "",
  mouseCursorLevel: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADER_ON:
      return { ...state, loaderOn: true };
    case SET_LOADER_OFF:
      return { ...state, loaderOn: false };
    case NEW_ACCESSIBLE_STATE:
      return { ...state, [action.payload.key]: action.payload.newValue };

    default:
      return { ...state };
  }
};
