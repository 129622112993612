import React from "react";
import { Grid, Box, Divider, Typography } from "@material-ui/core";
import * as customColors from "../../theme/customColors";

// Extended optional props:
// title
// amount
// isFinalMsg

const SumNumDisplayer = (props) => (
  <Grid container direction="row" justify="flex-start" alignItems="center">
    <Grid item xs={props.isFinalMsg ? 3 : 6}>
      <Divider style={{ height: "2px" }} />
    </Grid>
    <Grid item xs={6}>
      <Box
        width={1}
        height="45px"
        bgcolor={customColors.orange}
        style={{
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
          borderTopLeftRadius: props.isFinalMsg ? 20 : 0,
          borderBottomLeftRadius: props.isFinalMsg ? 20 : 0,
          boxShadow: "4px 5px 3px 0 rgb(16 36 94 / 20%)"
        }}
      >
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={8}>
            <Typography align="center" variant="h6" style={{ color: customColors.darkBlue, fontWeight: "bold" }}>
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box
              width={0.98}
              height="36px"
              alignItems="center"
              bgcolor={customColors.white}
              style={{
                marginTop: "3px",
                paddingTop: "3px",
                borderTopLeftRadius: props.isFinalMsg ? 18 : 0,
                borderBottomLeftRadius: props.isFinalMsg ? 18 : 0
              }}
            >
              <Typography align="center" variant="h6" style={{ fontWeight: "bold" }}>
                {props.amount ? props.amount.toLocaleString(undefined, { maximumFractionDigits: 0 }) : 0}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
    {props.isFinalMsg ? (
      <Grid item xs={3}>
        <Divider style={{ height: "2px" }} />
      </Grid>
    ) : null}
  </Grid>
);

// const SumNumDisplayer = (props) => (
//   <div className="sum-con">
//     <div className={`sum-inner-con${props.isFinalMsg ? " final-msg" : ""}`}>
//       <div className="yellow-sum-background">
//         <h2>{props.title}</h2>
//       </div>
//       <div className="sum-total-number">
//         <h2>{props.amount}</h2>
//       </div>
//     </div>
//     <div className="sum-just-line" />
//   </div>
// );

export default SumNumDisplayer;
