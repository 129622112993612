import React, { Component } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import TextFieldWithIcon from "../layout/textFieldWithIcon";

import Swal from "sweetalert2";
import userService from "../../services/usersService";
import logo from "../../assets/images/logo.png";

import { Link } from "react-router-dom";

export default class SetNewPass extends Component {
  state = {
    password: "",
    repeatPass: "",
    emailConfToken: "",
    email: ""
  };

  componentDidMount() {
    var emailConfToken = this.props.match.params.emailConfToken;
    var email = this.props.match.params.email;
    this.setState({ emailConfToken, email });
  }

  render() {
    return (
      <div className="session-con">
        <div className="center">
          <img src={logo} alt="logo" />
        </div>

        <div className="mt-20">
          <div className="login-top-blue">
            <Typography>הגדרת סיסמה</Typography>
          </div>
          <div className="login-wrapper">
            <Typography variant="h6">
              <b>הגדרת סיסמה חדשה</b>
            </Typography>

            <div className="mt-10">
              <TextFieldWithIcon
                value={this.state.password}
                onChange={this.handleChange}
                name="password"
                iconName="lock"
                placeholder="סיסמה חדשה"
                iconColor="#eee"
                type="password"
              />
            </div>

            <div className="mt-10">
              <TextFieldWithIcon
                value={this.state.repeatPass}
                onChange={this.handleChange}
                name="repeatPass"
                iconName="lock"
                placeholder="חזור על הסיסמה"
                iconColor="#eee"
                type="password"
              />
            </div>

            <div className="right mt-20">
              <Button onClick={this.handleSubmit} variant="contained">
                שמור
              </Button>
            </div>
            <div className="right mt-10">
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="subtitle2">במידה ואינך מעוניין להמשיך בשינוי הסיסמה</Typography>
                </Grid>
                <Grid item>
                  <Link
                    to={{
                      pathname: "/sessions/login"
                    }}
                  >
                    <Typography variant="subtitle2">לחץ לכניסה</Typography>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = () => {
    if (this.state.password.length < 6 || this.state.repeatPass.length < 6) {
      Swal.fire("", "הסיסמה חייבת להיות בעלת 6 תוים לפחות", "warning");
      return;
    }
    if (this.state.password !== this.state.repeatPass) {
      Swal.fire("", "הסיסמה ואישור הסיסמה שהוזנו אינן תואמות", "warning");
      return;
    } else if (!this.state.emailConfToken || !this.state.email) {
      Swal.fire("", "missing data", "warning");
      return;
    }
    var takeVars = this.state;
    userService.changePass(takeVars.email, takeVars.password, takeVars.emailConfToken).then((res) => {
      if (res["badToken"]) {
        Swal.fire("", "הבקשה אינה בתוקף", "warning");
      } else if (res["success"]) {
        Swal.fire("", "הסיסמה שונתה בהצלחה", "success").then(() => {
          this.props.history.push("/sessions/login");
        });
      }
    });
  };
}
