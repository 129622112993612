import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import Swal from "sweetalert2";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

import CustomDialog from "../../../layout/CustomDialog";
import LabeledTextField from "../../../layout/LabeledTextField";
import remindersService from "../../../../services/remindersService";
import { showDateInTextFieldType } from "../../../../services/utils/utils";

class ReminderDetailsDialog extends Component {
  state = {
    mission: "",
    destinationDate: "",
    status: "open",
    notificationDate: ""
  };

  componentDidMount() {
    if (this.props.selectedRow && Array.isArray(this.props.selectedRow)) {
      const mission = this.props.selectedRow[2] ? this.props.selectedRow[2] : "";
      const destinationDate = this.props.selectedRow[4]
        ? moment(showDateInTextFieldType(this.props.selectedRow[4])).format("YYYY-MM-DD")
        : "";
      const status = this.props.selectedRow[5]
        ? this.props.statusOpts.filter((status) => status.label === this.props.selectedRow[5])[0].value
        : "open";
      const notificationDate = this.props.selectedRow[6]
        ? moment(showDateInTextFieldType(this.props.selectedRow[6])).format("YYYY-MM-DD")
        : "";
      this.setState({ mission, destinationDate, status, notificationDate });
    }
  }

  render() {
    return (
      <CustomDialog label="פרטים" open={this.props.open} onClose={this.props.onClose} maxWidth="xs">
        <Grid container direction="column" spacing={1} style={{ marginTop: "8px", marginBottom: "8px" }}>
          <Grid item>
            <LabeledTextField
              type="text"
              value={this.state.mission}
              name="mission"
              label="משימה"
              labelWidth={110}
              onChange={(e) => this.handleOnChange(e)}
            />
          </Grid>
          <Grid item>
            <LabeledTextField
              type="date"
              value={this.state.destinationDate}
              name="destinationDate"
              label="תאריך יעד"
              labelWidth={110}
              onChange={(e) => this.handleOnChange(e)}
            />
          </Grid>
          <Grid item>
            <LabeledTextField
              type="select"
              value={this.state.status}
              name="status"
              label="סטטוס"
              options={this.props.statusOpts}
              labelWidth={110}
              onChange={(e) => this.handleOnChange(e)}
            />
          </Grid>
          <Grid item>
            <LabeledTextField
              type="date"
              value={this.state.notificationDate}
              name="notificationDate"
              label="תאריך תזכורת"
              labelWidth={110}
              onChange={(e) => this.handleOnChange(e)}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2} justify="center" alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ minWidth: "100px" }}
                  endIcon={<SaveIcon style={{ marginRight: "8px" }} />}
                  onClick={() => (!this.props.selectedRow ? this.createReminder() : this.updateReminder())}
                >
                  שמור
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ minWidth: "100px" }}
                  endIcon={<CloseIcon style={{ marginRight: "8px" }} />}
                  onClick={() => this.props.onClose()}
                >
                  ביטול
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomDialog>
    );
  }

  handleOnChange = (e) => {
    let val = e.target.value;
    const newState = { ...this.state, [e.target.name]: val };
    this.setState(newState);
  };

  reminderValidations() {
    if (!this.state.mission) {
      return "משימה הינו שדה חובה";
    } else if (!this.state.destinationDate) {
      return "תאריך יעד הינו שדה חובה";
    } else if (!this.state.notificationDate) {
      return "תאריך תזכורת הינו שדה חובה";
    }
    return null;
  }

  createReminder() {
    const validationErr = this.reminderValidations();
    if (validationErr) {
      return Swal.fire("", validationErr, "error");
    } else {
      remindersService
        .createReminder(
          this.props.dealId,
          this.props.dealDescription,
          this.props.dealPrettyId,
          this.state.mission,
          this.state.destinationDate,
          this.state.status,
          this.state.notificationDate
        )
        .then((res) => {
          this.props.onClose(null, true);
        })
        .catch((err) => {
          return Swal.fire("", err, "error");
        });
    }
  }

  updateReminder() {
    const validationErr = this.reminderValidations();
    if (validationErr) {
      return Swal.fire("", validationErr, "error");
    } else {
      remindersService
        .updateReminder(
          this.props.selectedRow[0],
          this.props.dealId,
          this.state.mission,
          this.state.destinationDate,
          this.state.status,
          this.state.notificationDate
        )
        .then((res) => {
          this.props.onClose(null, true);
        })
        .catch((err) => {
          return Swal.fire("", err, "error");
        });
    }
  }
}

export default ReminderDetailsDialog;
