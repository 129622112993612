import React from "react";
import { Redirect, Route, Switch } from "react-router";
import ManageEmailSubRenewal from "../components/managerComponents/manageEmailSubRenewal";
import UpdateIndexesContainer from "../components/managerComponents/updateIndexesContainer";
import "../assets/css/admin.css";

function AdminContainer() {
  return (
    <Switch>
      <Route path="/admin/update-indexes" component={UpdateIndexesContainer} />
      <Route path="/admin/manage-email-sub-renewal" component={ManageEmailSubRenewal} />

      <Route path="/">
        <Redirect to="/my-deals" />
      </Route>
    </Switch>
  );
}

export default AdminContainer;
