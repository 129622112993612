import config from "../config";
import axios from "axios";

export default {
  getAllDealsManager: function (cardNumOfOffice, softwareServiceType, dealPrettyId, dealName, isTotalCount, skipAmount) {
    return new Promise((resolve) => {
      let endpoint = `${config.getAllDealsSalesEndpoint}?cardNumOfOffice=${cardNumOfOffice}&softwareServiceType=${softwareServiceType}`;
      if (dealPrettyId) endpoint += `&dealPrettyId=${dealPrettyId}`;
      if (dealName) endpoint += `&dealName=${dealName}`;
      if (isTotalCount) endpoint += `&isTotalCount=${isTotalCount}`;
      if (skipAmount) endpoint += `&skipAmount=${skipAmount}`;

      axios.get(endpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  resetTestUserPassword: function (email, password) {
    return new Promise((resolve) => {
      axios.post(config.resetTestUserPasswordSalesEndpoint, { email, password }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getEmailConfToken: function (email) {
    return new Promise((resolve) => {
      axios.get(config.getEmailConfTokenEndpoint + "?email=" + email).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  }
};
