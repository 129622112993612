import React, { Component } from "react";
import userServices from "../../services/usersService";
import { Card, CardContent, Typography } from "@material-ui/core";

import Swal from "sweetalert2";

export default class TokenConf extends Component {
  componentDidMount() {
    var token = this.props.match.params.token;
    var clientEmail = this.props.match.params.clientEmail;
    if (!token) {
      return;
    }

    // if its a user that tries to confirm his/her email
    userServices.tryConfEmailToken(token, clientEmail).then((res) => {
      if (res["success"]) {
        Swal.fire("אושר", "אימייל אושר בהצלחה", "success").then(() => {
          this.props.history.push("/sessions/login");
        });
      } else {
        Swal.fire("", "אישור מייל לא תקין", "warning");
      }
    });
  }

  render() {
    return (
      <div className="container">
        <Card>
          <CardContent className="login-card">
            {/* <h4 className="primary-color mb-40"> */}
            <Typography>אישור אימייל</Typography>
            {/* </h4> */}
          </CardContent>
        </Card>
      </div>
    );
  }
}
